export interface CartPromoBoxProps {
  onApplyCode: (promoCode: string, setPromoCode: (code: string) => void) => void;
}

export class CartPromoBoxModel {
  promoCode: string = "";
  disableInteraction: boolean = false;
  constructor({ promoCode, disableInteraction }: { promoCode: string; disableInteraction: boolean }) {
    this.promoCode = promoCode;
    this.disableInteraction = disableInteraction;
  }
}
