import { useElementContext } from "../../../contexts";

const PickupFulfillment = ({ fulfillment, onChangeDate, onChangeLocation, pickupLocations }) => {
  const {
    CommonModule: { PickupLocationPicker },
  } = useElementContext();
  return (
    <div className="shippingFulfillment">
      <div>
        <PickupLocationPicker
          fulfillment={fulfillment}
          onChangeDate={onChangeDate}
          onChangeLocation={onChangeLocation}
          pickupLocations={pickupLocations}
        />
      </div>
    </div>
  );
};
export { PickupFulfillment };
