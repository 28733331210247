import { useContext } from "react";
import { FormContext } from "../DynamicForm";
import { formModel_formQuestion } from "../dynamicFormModel.class";
import { useElementContext } from "../../../contexts";

const FormInputLayout = () => {
  const {
    CommonModule: { FormCol, FormRow },
  } = useElementContext();
  const { layoutInput } = useContext(FormContext);

  return (
    <>
      {layoutInput?.map((rowInputs: any, i: number) => (
        <FormRow key={i}>
          {rowInputs?.map((q: any, idx: number) => {
            return (
              <FormCol key={idx}>
                <FormEL {...q} />
              </FormCol>
            );
          })}
        </FormRow>
      ))}
    </>
  );
};

export default FormInputLayout;

const FormEL = (props: formModel_formQuestion) => {
  const {
    CommonModule: {
      FormCheckboxField,
      FormCheckboxGroupField,
      FormChronoField,
      FormFileField,
      FormMultiSelectField,
      FormRadioField,
      FormSelectField,
      FormTextField,
      FormYesNoField,
    },
  } = useElementContext();
  const { attributes, ...rest } = props;
  switch (attributes.inputType) {
    case "text":
    case "email":
    case "password":
      return <FormTextField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "textArea":
      return <FormTextField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "radioGroup":
      return <FormRadioField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "checkboxGroup":
      return <FormCheckboxGroupField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "checkbox":
      return <FormCheckboxField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "yesNo":
      return <FormYesNoField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "select":
      return <FormSelectField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "typeSelect":
      return <FormSelectField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "multiselect":
      return <FormMultiSelectField key={attributes.attributeCode} {...attributes} {...rest} />;
    case "date":
      return <FormChronoField key={attributes.attributeCode} {...attributes} {...rest} chronoType="date" />;
    case "time":
      return <FormChronoField key={attributes.attributeCode} {...attributes} {...rest} chronoType="time" />;
    case "dateTime":
      return <FormChronoField key={attributes.attributeCode} {...attributes} {...rest} chronoType="datetime-local" />;
    case "file":
      return <FormFileField key={attributes.attributeCode} {...attributes} {...rest} />;
    default:
      return <h2>Not found - {attributes.inputType}</h2>;
  }
};
