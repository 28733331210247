import { ProductBundle as IProductBundle, ProductBundleBuildOnAccount } from "../../../../interface/Product";

export interface ProductBundleProps {
  productBundle: IProductBundle;
  productBundleBuildOnAccount?: ProductBundleBuildOnAccount;
  productID: string;
}

export class ProductBundleModel {
  bundlesOnAccount: { [key: string]: any };
  bundleBuildsOnAccount?: ProductBundleBuildOnAccount;
  productBundle: IProductBundle;
  productID: string = "";
  constructor({
    props,
    bundlesOnAccount,
    bundleBuildsOnAccount,
  }: {
    props: ProductBundleProps;
    bundlesOnAccount: { [key: string]: any };
    bundleBuildsOnAccount?: ProductBundleBuildOnAccount;
  }) {
    this.productBundle = props.productBundle;
    this.productID = props.productID;
    this.bundlesOnAccount = bundlesOnAccount;
    this.bundleBuildsOnAccount = bundleBuildsOnAccount;
  }
}
