import { toast } from "react-toastify";
import { axios, getSdkURL } from "../services";
import { receiveCart } from "./";
import { receiveUser, clearUser, evictAllPages } from "./";

export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const RECEIVE_LOGIN = "RECEIVE_LOGIN";
export const ERROR_LOGIN = "ERROR_LOGIN";
export const LOGOUT = "LOGOUT";

export const requestLogin = () => {
  return {
    type: REQUEST_LOGIN,
  };
};

export const receiveLogin = (isAuthenticanted) => {
  return {
    type: RECEIVE_LOGIN,
    isAuthenticanted,
  };
};

export const errorLogin = () => {
  return {
    type: ERROR_LOGIN,
  };
};

export const requestLogOut = () => {
  return {
    type: LOGOUT,
  };
};
export const logout = (success = "", failure = "") => {
  return async (dispatch) => {
    dispatch(evictAllPages());
    return await axios({
      method: "POST",
      withCredentials: true,
      url: `${getSdkURL()}api/scope/logout`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { returnJSONObjects: "cart,account" },
    }).then((response) => {
      dispatch(softLogout());
      if (response?.status === 200) {
        dispatch(receiveUser(response.data.account));
        dispatch(receiveCart(response.data.cart));
        dispatch({ type: "GET_ALL_CART_OR_QUOTE", cartAndQuote: [] });
        if (success !== "") toast.success(success);
      } else {
        if (failure !== "") toast.error(failure);
      }
      return response;
    });
  };
};

export const softLogout = () => {
  return async (dispatch) => {
    dispatch(requestLogOut());
    dispatch(clearUser());
    return new Promise((resolve, reject) => resolve({}));
  };
};

export const createAccount = (user) => {
  return async (dispatch) => {};
};
