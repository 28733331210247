import { CSSProperties } from "react";

export interface PoweredByProps {
  theme: any;
  styleList: CSSProperties;
}

const PoweredBy = ({ theme, styleList }: PoweredByProps) => {
  if (!theme.footerPoweredBy || theme.footerPoweredBy === "undefined") return null;
  return (
    <div className="d-flex align-items-center mx-2">
      Powered By:&nbsp;
      <img src={`${theme.footerPoweredBy.url}`} alt="" className="img-fluid" style={styleList} />
    </div>
  );
};

export { PoweredBy };
