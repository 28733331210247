import { useComponentData } from "./ProductDetailGallery.componentService";
import { ProductImageGalleryProps } from "./ProductDetailGallery.componentModel";
import { ProductDetailGalleryView } from "./ProductDetailGallery.componentView";

const ProductDetailGallery = (props: ProductImageGalleryProps) => {
  const { componentData } = useComponentData(props);

  return <ProductDetailGalleryView componentData={componentData} />;
};
export { ProductDetailGallery, type ProductImageGalleryProps };
