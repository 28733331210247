import image from "../assets/bearer.webp";
import DESIGNPINELOGO from "../assets/design-pine-logo.webp";
import { useEffect } from "react";
import $ from "jquery";
import "../assets/pure.min.js";
const Bearer = () => {
  useEffect(() => {
    $("#roofbeamSubmit").on("click", function () {
      roofBeamOnSubmit();
    });

    function roofBeamOnSubmit() {
      var x = document.getElementById("myDIV");
      var list5 = $("#list9");
      var list6 = $("#list10");
      var list7 = $("#list11");
      var list8 = $("#list12");

      var errorMessage = document.getElementById("errorMessage");

      if (list5.val() === "") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Span Type";
        $("#list9").focus();
        return false;
      } else if (list6.val() === "") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Live Load";

        $("#list10").focus();
        return false;
      } else if (list7.val() === "") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Laminate";

        $("#list11").focus();
        return false;
      } else if (list8.val() === "") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Supported Floor Load";

        $("#list12").focus();
        return false;
      } else {
        errorMessage.style.display = "none";
        x.style.display = "block";
        var spantype = list5.val();
        var windzone = list6.val();
        var laminate = list7.val();
        var roofloadsupport = list8.val();
        var desiredspan = document.getElementById("desiredSpan").value;

        requestRoofBeam(spantype, windzone, laminate, roofloadsupport, desiredspan);
        return true;
      }
    }

    function requestRoofBeam(opt1, opt2, opt3, opt4, opt5) {
      var spantype = opt1;
      var windzone = opt2;
      var laminate = opt3;
      var roofload = opt4;
      var desiredspan = opt5;
      // eslint-disable-next-line
      var setLink =
        "https://spantablesviewer.azurewebsites.net/bearers?brandname=DesignPine" +
        "&spantype=" +
        spantype +
        "&liveload=" +
        windzone +
        "&laminate=" +
        laminate +
        "&roofloadsupport=" +
        roofload +
        "&desiredspan=" +
        desiredspan;

      $.ajax({
        url: setLink,
        type: "GET",
        dataType: "text",
        success: function (response) {
          $("#Loading").hide();
          if (response.length === 0) {
            document.getElementById("resultError2").style.display = "block";
            document.getElementById("reportButton4").style.display = "none";
            document.getElementById("tableShell").style.display = "none";

            document.getElementById("showResult2").innerHTML = "Product is currently not available";
          } else {
            $("#list-rafter").empty();
            var clearHTML =
              '<tr>          <td data-label="Size"><span id="BeamSize"></span></td>          <td data-label="Grade"><span id="strength" data-strength=""></span></td>          <td data-label="Span (mm)"><span id="span" data-span=""></span></td>          <td data-label="Select">            <label className="toggle-check">              <input id="reportCheckbox" type="checkbox" data-id="" data-name="" data-application="" data-summary="" data-strength="" data-span="" data-spantype="" data-laminate="" data-roofload="" data-supportedroofload="" />            </label>          </td>        </tr>';
            var jsonData = JSON.parse(response);
            // checkGetData(jsonData);
            var dJSON = { products: jsonData },
              directive = {
                tr: {
                  "products<-products": {
                    "#BeamSize": "products.BeamSize",
                    "#reportCheckbox@data-id": "products.BearerID",
                    "#reportCheckbox@data-name": "products.brandName",
                    "#reportCheckbox@data-application": "products.Application",
                    "#reportCheckbox@data-summary": "products.BeamSize",
                    "#strength": "products.Grade",
                    "#reportCheckbox@data-strength": "products.Grade",
                    "#span": "products.Span",
                    "#reportCheckbox@data-span": "products.Span",
                    "#reportCheckbox@data-spantype": "products.SpanType",
                    "#reportCheckbox@data-laminate": "products.Laminate",
                    "#reportCheckbox@data-roofload": "products.LiveLoad",
                    "#reportCheckbox@data-supportedroofload": "products.FloorLoadSupport",
                  },
                },
              };
            $("#list-rafter").append(clearHTML);
            if (typeof dJSON.products !== "undefined" && dJSON.products.length > 0) {
              document.getElementById("resultError2").style.display = "none";
              document.getElementById("reportButton4").style.display = "block";
              document.getElementById("tableShell").style.display = "inline-table";

              $("#list-rafter").render(dJSON, directive); //render the result
            } else {
              document.getElementById("resultError2").style.display = "block";
              document.getElementById("reportButton4").style.display = "none";
              document.getElementById("tableShell").style.display = "none";

              document.getElementById("showResult2").innerHTML = "Product is currently not available";
            }
          }

          relevantEventListeners2();
        },
        error: function (response) {
          document.getElementById("resultError2").style.display = "block";
          document.getElementById("reportButton4").style.display = "none";
          document.getElementById("tableShell").style.display = "none";
          document.getElementById("showResult2").innerHTML = "Product is currently not available";
        },
      });

      $("#searchContainer").ajaxStart(function () {
        $("#Loading").show();
      });
    }

    function relevantEventListeners2() {
      $("#reportCheckbox").myCart({
        currencySymbol: "$",
        classCartIcon: "my-cart-icon",
        classCartBadge: "my-cart-badge",
        classProductQuantity: "my-product-quantity",
        classProductRemove: "my-product-remove",
        classCheckoutCart: "my-cart-checkout",
        affixCartIcon: true,
        showCheckoutModal: true,
        numberOfDecimals: 2,
        cartItems: [],
      });
    }
    $("#viewReportlist").on("click", function () {
      var val = [];
      $("#list-rafter input[type=checkbox]:checked").each(function (i) {
        this.setAttribute("checked", ""); // For IE
        this.checked = true;
        val[i] = $(this).val();
      });
      if (val.length === 0) {
        $("#resultError2").show();
        document.getElementById("showResult2").innerHTML = "Please include a report to view report";
        // alert("Please include a report to view report");
      } else {
        $("#resultError2").hide();
        $("#hideonReport2").hide();
        $("#myDIV").hide();
        $("#ViewReport").show();

        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        var newdate = "Date created: " + day + "/" + month + "/" + year;

        document.getElementById("getDate").innerHTML = newdate;
      }
    });

    // report button JS
    $("#printReport").on("click touchstart", function () {
      $("#ViewReport").printThis({
        pageTitle: "jQuery printThis Demo",
        loadCSS: "",
      });
    });
    $("#continueEdit").on("click touchstart", function () {
      $("#ViewReport").hide();
      $("#hideonReport2").show();
      $("#myDIV").show();
    });
    (function ($) {
      // eslint-disable-next-line
      "use strict";

      var OptionManager = (function () {
        var objToReturn = {};

        var _options = null;
        var DEFAULT_OPTIONS = {
          currencySymbol: "$",
          classCartIcon: "my-cart-icon",
          classCartBadge: "my-cart-badge",
          classProductQuantity: "my-product-quantity",
          classProductRemove: "my-product-remove",
          classCheckoutCart: "my-cart-checkout",
          affixCartIcon: true,
          showCheckoutModal: true,
          numberOfDecimals: 2,
          cartItems: null,
          clickOnAddToCart: function ($addTocart) {},
          afterAddOnCart: function (products) {},
          clickOnCartIcon: function ($cartIcon, products) {},
          checkoutCart: function (products) {
            return false;
          },
        };

        var loadOptions = function (customOptions) {
          _options = $.extend({}, DEFAULT_OPTIONS);
          if (typeof customOptions === "object") {
            $.extend(_options, customOptions);
          }
        };
        var getOptions = function () {
          return _options;
        };

        objToReturn.loadOptions = loadOptions;
        objToReturn.getOptions = getOptions;
        return objToReturn;
      })();
      // eslint-disable-next-line
      var MathHelper = (function () {
        var objToReturn = {};
        var getRoundedNumber = function (number) {
          if (isNaN(number)) {
            throw new Error("Parameter is not a Number");
          }
          number = number * 1;
          var options = OptionManager.getOptions();
          return number.toFixed(options.numberOfDecimals);
        };
        objToReturn.getRoundedNumber = getRoundedNumber;
        return objToReturn;
      })();

      var ProductManager = (function () {
        var objToReturn = {};

        /*
        PRIVATE
        */
        localStorage.products = localStorage.products ? localStorage.products : "";
        var getIndexOfProduct = function (id) {
          var productIndex = -1;
          var products = getAllProducts();
          $.each(products, function (index, value) {
            if (value.id === id) {
              productIndex = index;
              return;
            }
          });
          return productIndex;
        };
        var setAllProducts = function (products) {
          localStorage.products = JSON.stringify(products);
        };
        var addProduct = function (
          id,
          name,
          summary,
          application,
          strength,
          span,
          spantype,
          laminate,
          roofload,
          supportedroofload,
        ) {
          var products = getAllProducts();
          products.push({
            id: id,
            name: name,
            summary: summary,
            application: application,
            strength: strength,
            span: span,
            spantype: spantype,
            laminate: laminate,
            roofload: roofload,
            supportedroofload: supportedroofload,
          });
          setAllProducts(products);
        };

        /*
        PUBLIC
        */
        var getAllProducts = function () {
          try {
            var products = JSON.parse(localStorage.products);
            return products;
          } catch (e) {
            return [];
          }
        };
        var updatePoduct = function (id, quantity) {
          var productIndex = getIndexOfProduct(id);
          if (productIndex < 0) {
            return false;
          }
          // eslint-disable-next-line
          var products = getAllProducts();

          return true;
        };
        var setProduct = function (
          id,
          name,
          summary,
          application,
          strength,
          span,
          spantype,
          laminate,
          roofload,
          supportedroofload,
        ) {
          if (typeof id === "undefined") {
            console.error("id required");
            return false;
          }
          if (typeof name === "undefined") {
            console.error("name required");
            return false;
          }

          summary = typeof summary === "undefined" ? "" : summary;
          application = typeof application === "undefined" ? "" : application;
          strength = typeof strength === "undefined" ? "" : strength;
          span = typeof span === "undefined" ? "" : span;
          spantype = typeof spantype === "undefined" ? "" : spantype;
          laminate = typeof laminate === "undefined" ? "" : laminate;
          roofload = typeof roofload === "undefined" ? "" : roofload;
          supportedroofload = typeof supportedroofload === "undefined" ? "" : supportedroofload;

          if (!updatePoduct(id)) {
            addProduct(id, name, summary, application, strength, span, spantype, laminate, roofload, supportedroofload);
          }
        };
        var clearProduct = function () {
          setAllProducts([]);
        };
        var removeProduct = function (id) {
          var products = getAllProducts();
          products = $.grep(products, function (value, index) {
            return value.id !== id;
          });
          setAllProducts(products);
        };

        objToReturn.getAllProducts = getAllProducts;
        objToReturn.updatePoduct = updatePoduct;
        objToReturn.setProduct = setProduct;
        objToReturn.clearProduct = clearProduct;
        objToReturn.removeProduct = removeProduct;
        return objToReturn;
      })();

      var loadMyCartEvent = function (targetSelector) {
        var options = OptionManager.getOptions();
        var $cartIcon = $("." + options.classCartIcon);
        // eslint-disable-next-line
        var $cartBadge = $("." + options.classCartBadge);
        // eslint-disable-next-line
        var classProductQuantity = options.classProductQuantity;
        var classProductRemove = options.classProductRemove;
        // eslint-disable-next-line
        var classCheckoutCart = options.classCheckoutCart;
        // eslint-disable-next-line
        var idCartModal = "my-cart-modal";
        var idCartTable = "my-cart-table";
        // eslint-disable-next-line
        var idGrandTotal = "my-cart-grand-total";
        // eslint-disable-next-line
        var idEmptyCartMessage = "my-cart-empty-message";
        // eslint-disable-next-line
        var idDiscountPrice = "my-cart-discount-price";
        // eslint-disable-next-line
        var classProductTotal = "my-product-total";
        var classAffixMyCartIcon = "my-cart-icon-affix";

        if (options.cartItems && options.cartItems.constructor === Array) {
          ProductManager.clearProduct();
          $.each(options.cartItems, function () {
            ProductManager.setProduct(
              this.id,
              this.name,
              this.summary,
              this.application,
              this.strength,
              this.span,
              this.spantype,
              this.laminate,
              this.roofload,
              this.supportedroofload,
            );
          });
        }

        var drawTable = function () {
          var $cartTable = $("#" + idCartTable);
          $cartTable.empty();

          var products = ProductManager.getAllProducts();
          $.each(products, function () {
            // var total = this.quantity * this.price;
            // eslint-disable-next-line
            $cartTable.append(
              '<tr title="' +
                this.summary +
                '" data-id="' +
                this.id +
                '">' +
                '<td data-label="Product">' +
                this.name +
                "</td>" +
                '<td data-label="Application">' +
                this.application +
                "</td>" +
                '<td data-label="Beam Size">' +
                this.summary +
                "</td>" +
                '<td data-label="Strength">' +
                this.strength +
                "</td>" +
                '<td data-label="Span Type">' +
                this.spantype +
                "</td>" +
                '<td width="20%" data-label="Parameter">' +
                this.laminate +
                "," +
                this.roofload +
                "," +
                this.supportedroofload +
                "</td>" +
                '<td data-label="Result (mm)">' +
                this.span +
                "</td>" +
                '<td data-label=" " title="Remove from Cart" className="text-center" style="width: 30px;"><a href="javascript:void(0);" className="btn btn-xs btn-danger removeProd ' +
                classProductRemove +
                '">X</a></td>' +
                "</tr>",
            );
          });
        };
        var showModal = function () {
          drawTable();
        };

        /*
        EVENT
        */
        if (options.affixCartIcon) {
          var cartIconBottom = $cartIcon.offset().top * 1 + $cartIcon.css("height").match(/\d+/) * 1;
          // eslint-disable-next-line
          var cartIconPosition = $cartIcon.css("position");
          $(window).scroll(function () {
            $(window).scrollTop() >= cartIconBottom
              ? $cartIcon.addClass(classAffixMyCartIcon)
              : $cartIcon.removeClass(classAffixMyCartIcon);
          });
        }

        $cartIcon.click(function () {
          options.showCheckoutModal ? showModal() : options.clickOnCartIcon($cartIcon, ProductManager.getAllProducts());
        });

        $(document).on("click", "." + classProductRemove, function () {
          var $tr = $(this).closest("tr");
          var id = $tr.data("id");
          $tr.hide(500, function () {
            ProductManager.removeProduct(id);

            $("#list-rafter input[type=checkbox]:checked").each(function (i) {
              var $target = $(this);

              var checkboxDataID = $target.data("id");

              if (checkboxDataID === id) {
                this.removeAttribute("checked"); // For other browsers
                this.checked = false;
              } else {
                this.setAttribute("checked", ""); // For IE
                this.checked = true;
              }
            });
            drawTable();
          });
        });

        $("#list-rafter input[type=checkbox]").on("click", targetSelector, function () {
          if (this.checked) {
            //Do stuff
            var $target = $(this);
            options.clickOnAddToCart($target);
            var id = $target.data("id");
            var name = $target.data("name");
            var summary = $target.data("summary");
            var application = $target.data("application");
            var strength = $target.data("strength");
            var span = $target.data("span");
            var spantype = $target.data("spantype");
            var laminate = $target.data("laminate");
            var roofload = $target.data("roofload");
            var supportedroofload = $target.data("supportedroofload");

            ProductManager.setProduct(
              id,
              name,
              summary,
              application,
              strength,
              span,
              spantype,
              laminate,
              roofload,
              supportedroofload,
            );
            options.afterAddOnCart(ProductManager.getAllProducts());
            this.setAttribute("checked", ""); // For IE
            // this.removeAttribute("checked"); // For other browsers
            this.checked = true;
          } else {
            ProductManager.clearProduct();
            this.removeAttribute("checked"); // For other browsers

            // $('#reportCheckbox').attr('checked', false); // Unchecks it
            this.checked = false;
          }
        });
        $("#resetReport").on("click touchstart", function () {
          $("#my-cart-table").empty();
          $("#list-rafter input[type=checkbox]:checked").each(function (i) {
            this.removeAttribute("checked"); // For other browsers
            this.checked = false;
          });
          // $('#list-rafter input[type=checkbox]').attr('checked', false); // Unchecks it
          // $('.viewReportlist').hide();
          $("#reportCheckbox").myCart({
            cartItems: [],
          });
        });
      };

      $.fn.myCart = function (userOptions) {
        OptionManager.loadOptions(userOptions);
        loadMyCartEvent(this.selector);
        return this;
      };
    })($);

    document.getElementById("showAppReport").innerHTML =
      '<div className="col-md-6"> <h2><b>Bearers</b> </h2> <p>Floor dead load 0.4 kPa<br> Point Load 1.8kN</p> <p>Deflection limits <br> Dead Loads span/300 or 12 mm<br> Live Loads span/360 or 9 mm<br> 2 mm for 1kN point load</p> <p><b>Notes</b> </p> <p>1. Bearers designed to provisions of AS 1720.1:2010, Timber Structures. <br> 2. Bearers do not support roof loads. <br> 3. Maximum balustrade weight 25kg/m.<br> 4. Allows for up to 300mm joist cantilever. <br> 5.Timber strength details above.</p> </div> <div className="col-md-6"> <div className="imgtype"> <img src=' +
      image +
      ' alt="bearer"/> </div> </div> ';
  }, []);
  return (
    <div className="col-md-9 col-sm-9">
      <div className="row">
        <div id="hideonReport2" style={{ display: "flex", background: "white" }}>
          <div className="col-md-6">
            <h2>Bearer</h2>
            <div className="dropdown clearfix position-static" id="spanfilter">
              <p id="errorMessage"></p>
              <label htmlFor="SpanType">Span Type</label>
              <select required="" id="list9">
                <option value="" hidden="">
                  {" "}
                  -- Select Option --{" "}
                </option>
                <option value="single">Single</option>
                <option value="Continuous">Continuous</option>
              </select>

              <label htmlFor="WindZone">Live Load</label>
              <select required="" id="list10">
                <option value="" hidden="">
                  {" "}
                  -- Select Option --{" "}
                </option>
                <option value="1.5kPa">1.5kPa (1.8kN)</option>
                <option value="2kPa">Residential 2.0kPa (1.8kN)</option>
                <option value="3kPa">Heavy Residential 3.0kPa (1.8kN)</option>
                <option value="4kPa">Commercial 4.0kPa (1.8kN)</option>
              </select>

              <label htmlFor="Laminate">Laminate</label>
              <select required="" id="list11">
                <option value="" hidden="">
                  {" "}
                  -- Select Option --{" "}
                </option>
                <option value="single">Single</option>
                <option value="double">Double</option>
              </select>

              <label htmlFor="Supported Floor Load">Supported Floor Load (m)</label>
              <select required="" id="list12">
                <option value="" hidden="">
                  {" "}
                  -- Select Option --{" "}
                </option>
                <option value="1.2">1.2</option>
                <option value="1.8">1.8</option>
                <option value="2.4">2.4</option>
                <option value="3">3.0</option>
                <option value="3.6">3.6</option>
                <option value="4.2">4.2</option>
                <option value="4.8">4.8</option>
                <option value="5.4">5.4</option>
                <option value="6">6.0</option>
              </select>

              <label htmlFor="Desired Span (mm)">Desired Span (mm)</label>
              <input type="text" className="form-control inputSpan" id="desiredSpan" placeholder="Desired Span (mm)" />
              <button className="dropdown-toggle search1" type="submit" id="roofbeamSubmit">
                Search
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="imgtype">
              <img alt="" src={image} width="100%" />
            </div>
          </div>
        </div>
        <div className="col-md-12" id="myDIV">
          <div id="searchContainer">
            <div id="Loading">
              <i className="fa fa-spinner fa-pulse fa-5x fa-fw"></i>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          <table id="tableShell">
            <thead>
              <tr>
                <th scope="col">Size</th>
                <th scope="col">Grade</th>
                <th scope="col">Span (mm)</th>
                <th scope="col">Select</th>
              </tr>
            </thead>
            <tbody id="list-rafter">
              <tr>
                <td data-label="Size">
                  <span id="BeamSize"></span>
                </td>
                <td data-label="Grade">
                  <span id="strength" data-strength=""></span>
                </td>
                <td data-label="Span (mm)">
                  <span id="span" data-span=""></span>
                </td>
                <td data-label="Select">
                  <label className="toggle-check">
                    <input
                      id="reportCheckbox"
                      type="checkbox"
                      data-id=""
                      data-name=""
                      data-application=""
                      data-summary=""
                      data-strength=""
                      data-span=""
                      data-spantype=""
                      data-laminate=""
                      data-roofload=""
                      data-supportedroofload=""
                    />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="col-md-6 m-auto">
            <div className="viewReportlist mt-4" id="reportButton4">
              <span className="my-cart-icon" id="viewReportlist">
                <p>
                  View Report <span className="badge badge-notify my-cart-badge"></span>
                </p>
              </span>
            </div>

            <div className="yellowAlert" id="resultError2">
              <h4>
                <b id="showResult2"></b>{" "}
              </h4>
            </div>
          </div>
        </div>

        <div id="ViewReport">
          <div className="col-md-12 reportBorder">
            <div className="spanTablesTitle" style={{ backgroundColor: "#222" }}>
              <h1>
                <b>Span Tables</b>{" "}
              </h1>
            </div>
            <div className="LogoActions">
              <div className="col-md-4">
                <div className="reportLogo">
                  <img className="header-logo-image" src={DESIGNPINELOGO} alt="Design Pine" />
                </div>
              </div>
              <div className="col-md-8">
                <div className="alightItem">
                  <button id="printReport" className="new-button me-3" type="button" name="button">
                    Print Report
                  </button>
                  <button id="continueEdit" className="new-button me-3" type="button" name="button">
                    Continue Editing Report
                  </button>
                  <button id="resetReport" className="new-button me-3" type="button" name="button">
                    Reset Report
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="outputTitle">
                <p>
                  <b className="designPineBlue">DesignPine</b> Span Table List
                </p>
                <p id="getDate"></p>
                <hr />
                <h4>
                  <b>Results</b>{" "}
                </h4>
                <hr />
              </div>

              <div id="output">
                <div className="reportTable">
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Product</th>
                        <th scope="col">Application</th>
                        <th scope="col">Size</th>
                        <th scope="col">Strength</th>
                        <th scope="col">Span Type</th>
                        <th scope="col">Parameter</th>
                        <th scope="col">Result (mm)</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody id="my-cart-table"></tbody>
                  </table>
                </div>
              </div>

              <div id="showAppReport">
                <div className="col-md-6">
                  <h2>
                    <b>Bearers</b>{" "}
                  </h2>
                  <p>
                    Floor dead load 0.4 kPa
                    <br /> Point Load 1.8kN
                  </p>
                  <p>
                    Deflection limits <br /> Dead Loads span/300 or 12 mm
                    <br /> Live Loads span/360 or 9 mm
                    <br /> 2 mm for 1kN point load
                  </p>
                  <p>
                    <b>Notes</b>{" "}
                  </p>
                  <p>
                    1. Bearers designed to provisions of AS 1720.1:2010, Timber Structures.
                    <br /> 2. Bearers do not support roof loads. <br /> 3. Maximum balustrade weight 25kg/m.
                    <br /> 4. Allows for up to 300mm joist cantilever. <br />
                    5.Timber strength details above.
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="imgtype">
                    {" "}
                    <img src={image} alt="bearer" />{" "}
                  </div>
                </div>
              </div>

              <hr />
              <h2>
                <b>Certification</b>{" "}
              </h2>
              <p>
                The above span output is certified by Dave King Eng - Tasman Consulting, reviewed by: Robin Frengley
                Consulting Engineer
              </p>
              <p>
                <b>Disclaimer</b>{" "}
              </p>
              <p>
                ITI takes no responsibility for the interpretation of the above application or the information input
                into the generation of this report. The Above span are applicable for the products described above only.
              </p>
              <p>www.designpine.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bearer;
