import { Option, OptionGroup } from "../../../interface/Product";

export interface OptionButtonsProps {
  productOptions: OptionGroup[];
  updateFilters: (optionGroup: OptionGroup, option: Option) => void;
}

const OptionButtons = ({ productOptions, updateFilters }: OptionButtonsProps) => {
  const isMultiOptionSet = productOptions.length - 1;

  return (
    <div className="optionGroup d-flex flex-column">
      {productOptions?.map((optionGroup) => {
        const { optionGroupName, options, optionGroupID } = optionGroup;
        //const selectedOptionCode = selection[optionGroupCode] || 'select'

        return (
          <div className="form-group pe-4 my-4" key={optionGroupID}>
            <div className="d-flex justify-content-between align-items-center pb-1">
              <label className="optionGroupName h6" htmlFor={optionGroupID}>
                {optionGroupName}
              </label>
            </div>
            <div className="d-flex options-div flex-wrap" id={optionGroupID}>
              {/* Only for available variants. providing checked for input for preselecting the value */}
              {options?.map((option) => {
                return (
                  <button
                    key={option.optionCode}
                    onClick={() => {
                      updateFilters(optionGroup, option);
                    }}
                    className={` mx-2 p-1 my-2 btn skuOption 
                      ${option.active ? "active" : "nonactive"} 
                      ${option.productCount || !isMultiOptionSet ? "available" : "nonavailable"}`}
                  >
                    {option.optionName}
                  </button>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { OptionButtons };
