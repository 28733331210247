import { useCallback, useContext, useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { FormContext } from "../DynamicForm";

const useFormRecaptcha = () => {
  const { setRecaptchaVerified, requireReCAPTCHA } = useContext(FormContext);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) return;

    const recaptchaToken = await executeRecaptcha("yourAction");

    if (recaptchaToken) setRecaptchaVerified(true);
  }, [executeRecaptcha, setRecaptchaVerified]);

  useEffect(() => {
    if (requireReCAPTCHA) handleReCaptchaVerify();
  }, [handleReCaptchaVerify, requireReCAPTCHA]);
};

export default useFormRecaptcha;
