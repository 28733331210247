export const DisplayField = ({ fieldValue, iconClass }: { fieldValue: string; iconClass: string }) => {
  return (
    <>
      {fieldValue && (
        <div className="me-2 d-flex align-items-center">
          <i className={`bi bi-${iconClass} ms-sm-3 me-2 fs-5`}></i>
          <span>{fieldValue}</span>
        </div>
      )}
    </>
  );
};
