import dayjs from "dayjs";
import { Event } from "../../pages/Event/interface";

const EventDateTime = ({
  cssClass = "",
  startDateTime,
  endDateTime,
  format = "D MMM YYYY",
}: Pick<Event, "startDateTime" | "endDateTime"> & { cssClass?: string; format?: string }) => {
  if (!startDateTime && !endDateTime) return null;

  const formateDate = (date: string) => dayjs(date).format(format);

  const renderDate = () => {
    if (startDateTime && endDateTime)
      return (
        <>
          {formateDate(startDateTime)} - {formateDate(endDateTime)}
        </>
      );
    if (startDateTime) return <>FROM {formateDate(startDateTime)}</>;
    if (endDateTime) return <>UNTIL {formateDate(endDateTime)}</>;
    return null;
  };

  return (
    <div className={cssClass}>
      <i className="bi bi-calendar3 pr-2"></i> {renderDate()}
    </div>
  );
};

export default EventDateTime;
