import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { axios, SlatwalApiService } from "../../../services";

const useQuotesList = ({ statusTypeCode }) => {
  const [quotes, setQuotes] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const accountID = useSelector((state) => state.userReducer.accountID);

  useEffect(() => {
    setQuotes(null);
  }, [statusTypeCode]);

  const source = useRef();

  const fetchQuoteList = useCallback(() => {
    if (!accountID) return;

    if (source.current) {
      source.current.cancel();
    }
    source.current = axios.CancelToken.source();
    setLoading(true);
    let payload = { ...(statusTypeCode ? { statusTypeCode } : {}), currentPage };
    SlatwalApiService.quotes.list(payload, {}, source.current).then((response) => {
      if (response.isSuccess()) {
        const { quotesOnAccount } = response.success();
        setQuotes(quotesOnAccount);
        setLoading(false);
        const totalPage = Math.ceil(quotesOnAccount?.recordsCount / quotesOnAccount?.pageRecordsShow);
        if (totalPage < currentPage) {
          setCurrentPage(totalPage);
        }
      }
    });
  }, [accountID, currentPage, statusTypeCode]);

  useEffect(() => {
    fetchQuoteList();
  }, [fetchQuoteList]);

  return {
    quotes: quotes?.ordersOnAccount || [],
    isLoading,
    currentPage,
    setCurrentPage,
    recordsCount: quotes?.recordsCount || 0,
    totalPage: Math.ceil(quotes?.recordsCount / quotes?.pageRecordsShow),
    fetchQuoteList,
  };
};
export { useQuotesList };
