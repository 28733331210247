import { FilterSpec } from "../../Controls/FilterBanner";
import { Location } from "../../../../interface/Location";

export interface WhereToBuyLocationProps {
  relatedCollectionDataList: Location[];
  wtbFilters?: string;
  wtbMapConfiguration?: string;
  wtbLayoutType: "full" | "slimRow";
  showSearch: boolean;
  contentElementName: string;
  customStyleClasses?: string;
}

export class WhereToBuyLocationModel {
  customStyleClasses?: string;
  wtbLayoutType!: WhereToBuyLocationProps["wtbLayoutType"];
  displayedRetailLocations: Location[] = [];
  filterStack: FilterSpec[] = [];
  searchValue: string = "";
  showSearch: boolean = true;
  title: string = "";
  mapCenter?: {
    AddressLatitude: string;
    AddressLongitude: string;
  };
  defaultZoom?: number;
  constructor({
    props,
    searchValue,
    filterStack,
    displayedRetailLocations,
    mapCenter,
    defaultZoom,
  }: {
    props: WhereToBuyLocationProps;
    searchValue: string;
    filterStack: FilterSpec[];
    displayedRetailLocations: Location[];
    mapCenter?: {
      AddressLatitude: string;
      AddressLongitude: string;
    };
    defaultZoom?: number;
  }) {
    this.title = props.contentElementName;
    this.wtbLayoutType = props.wtbLayoutType || "full";
    this.showSearch = props.showSearch ?? true;
    this.searchValue = searchValue;
    this.displayedRetailLocations = displayedRetailLocations;
    this.filterStack = filterStack;
    this.mapCenter = mapCenter;
    this.defaultZoom = defaultZoom;
    this.customStyleClasses = props.customStyleClasses || "";
  }
}
