import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useElementContext } from "../../../contexts";

const ShippingFulfillment = ({ fulfillment, onShipmentSelect, onShipmentSave, onSelectShippingMethod }) => {
  const { orderFulfillmentID } = fulfillment;
  const custDetails = useSelector((state) => state.userReducer);
  const { t } = useTranslation();
  const {
    CommonModule: { FulfilmentAddressSelector, ShippingMethodRates },
  } = useElementContext();

  return (
    <div className="shippingFulfillment">
      <div>
        <div className="mb-4">
          <h5 className="h5 pt-1 pb-2 mb-3 border-bottom confirm-details-title">{t("frontend.confirmdetails")}</h5>
          <div className="row">
            <div className="me-2 d-flex col-5 flex-wrap">
              <b>{t("frontend.contact.firstName")}: &nbsp;</b>
              <p>{custDetails?.firstName}</p>
            </div>
            <div className="d-flex col-5 flex-wrap">
              <b>{t("frontend.contact.lastName")}: &nbsp;</b>
              <p>{custDetails?.lastName}</p>
            </div>
          </div>
          <div className="row">
            <div className="me-2 d-flex col-5 flex-wrap">
              <b>{t("frontend.account.company")}: &nbsp;</b>
              <p>{custDetails?.company}</p>
            </div>
            <div className="d-flex col-5 flex-wrap">
              <b>{t("frontend.contact.emailAddress")}: &nbsp;</b>
              <p>{custDetails?.primaryEmailAddress.emailAddress}</p>
            </div>
          </div>
          <div className="row">
            <div className="d-flex flex-wrap">
              <b>{t("frontend.contact.phoneNumber")}: &nbsp;</b>
              <p>{custDetails?.primaryPhoneNumber.phoneNumber}</p>
            </div>
          </div>
        </div>
        {/* <OrderFulfillmentItems fulfillment={fulfillment} /> */}
        <FulfilmentAddressSelector
          fulfillment={fulfillment}
          onSelect={(value) => onShipmentSelect(value, orderFulfillmentID)}
          onSave={(values) => onShipmentSave(values, orderFulfillmentID)}
        />

        <ShippingMethodRates
          fulfillment={fulfillment}
          onSelection={(values) => onSelectShippingMethod(values, orderFulfillmentID)}
        />
      </div>
    </div>
  );
};
export { ShippingFulfillment };
