import { useElementContext } from "../../contexts";

const PageHeader = ({ title, subHeading, children, ...props }) => {
  const {
    CommonModule: { BreadCrumb },
  } = useElementContext();
  return (
    <div className="page-header bg-light p-4 text-center">
      <div className="text-center">
        <h1 className="display-6">{title}</h1>
        <p className="lead mb-0">{subHeading}</p>
      </div>

      <div className="px-5">
        <BreadCrumb {...props} />
      </div>
    </div>
  );
};

export { PageHeader };
