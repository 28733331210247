import { axios } from "../../services";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { useServiceContext, useElementContext } from "../../contexts";

const DynamicProductListing = (props) => {
  const { viewMode = "celSlider", contentElementName, searchString, searchQuery, customStyleClasses } = props;
  const cardDisplayConfigurations = {
    skuCardConfiguration: JSON.parse(props?.skuCardConfiguration || "{}"),
    productCardConfiguration: JSON.parse(props?.productCardConfiguration || "{}"),
  };

  let source = axios.CancelToken.source();
  const [products, setProducts] = useState([]);
  const {
    ProductCardModule,
    CommonModule: { ProductSlider, ContentCard },
  } = useElementContext();
  const { ProductService } = useServiceContext();
  const productService = useMemo(() => new ProductService(), [ProductService]);

  const params = queryString.parse(searchQuery || "");

  useEffect(() => {
    productService.search({ pageSize: 12, searchTerm: searchString, ...params }, "product", source).then((data) => {
      setProducts(
        data.products.map(
          ({ skus, defaultSku_skuID }) => skus.find((sku) => sku.skuID === defaultSku_skuID) || skus[0],
        ),
      );
    });
    //eslint-disable-next-line
  }, []);

  if (viewMode === "2c938084833c662e01833d163c01004c" || viewMode === "celSlider") {
    return (
      <ProductSlider
        customStyleClasses={customStyleClasses}
        products={products}
        title={contentElementName}
        cardConfiguration={cardDisplayConfigurations.skuCardConfiguration}
      />
    );
  } else if (viewMode === "celStack") {
    if (!products?.length) {
      return null;
    }

    return (
      <div className={`w-100 ${customStyleClasses}`}>
        <h3 className="fw-bold">{contentElementName}</h3>
        <ContentCard
          contentCardLayout="card-overlay-left"
          contentBody={products[0].skuDescription}
          contentHeading={products[0].skuName}
          contentCardImage={products[0].imageFile}
        />
        <div className="d-flex mt-4 flex-wrap justify-content-between">
          {products.map((product, idx) => {
            if (idx === 0) {
              return null;
            }

            if (idx < 4) {
              return (
                <ProductCardModule.CoreControl.SkuCard
                  key={idx}
                  {...product}
                  cardType="Third width Description Card"
                  cardConfiguration={cardDisplayConfigurations.skuCardConfiguration}
                />
              );
            }

            return null;
          })}
        </div>
        <div className="w-100 text-center p-3">
          <Link to="/shop">
            <b>Show More</b>
          </Link>
        </div>
      </div>
    );
  } else if (viewMode === "celTiles") {
    if (!products?.length) {
      return null;
    }
    return (
      <div className={`w-100 ${customStyleClasses}`}>
        <h3 className="fw-bold">{contentElementName}</h3>
        <div className="row">
          {products.map((product, idx) => {
            if (idx < 6) {
              return (
                <ProductCardModule.CoreControl.SkuCard
                  key={idx}
                  {...product}
                  cardType="Third width Description Card"
                  cardConfiguration={cardDisplayConfigurations.skuCardConfiguration}
                />
              );
            }

            return null;
          })}
        </div>
        <div className="w-100 text-center p-3">
          <Link to="/shop">
            <b>Show More</b>
          </Link>
        </div>
      </div>
    );
  }
  return null;
};
export { DynamicProductListing };
