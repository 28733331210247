import { Sku } from "../../../../interface/Product";

export interface SkuSelectorProps {
  sku?: Sku;
  skus: Sku[];
}

export class SkuSelectorModel {
  sku?: Sku;
  skus: Sku[] = [];
  constructor({ props }: { props: SkuSelectorProps }) {
    this.sku = props.sku;
    this.skus = props.skus;
  }
}
