import { axios, getSdkURL } from "../../services";
import { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const QuoteCheckOut = ({ quoteReadyItems, orderID }) => {
  const [quoteName, setQuoteName] = useState("");
  const [quoteID, setQuoteID] = useState("");
  const navigate = useNavigate();
  const cartAndQuotes = useSelector((state) => state?.allCartQuote?.cartAndQuote) || [];

  const splitCartToQuoteOrder = () => {
    const orderItemIDList = quoteReadyItems.map((item) => item.orderItemID).join(",");

    let params = {
      orderID,
      orderItemIDList,
    };

    if (!quoteID && !quoteName) {
      toast.error("Quote is required");
      return;
    }

    if (!orderItemIDList) {
      toast.error("No items available to move into quote");
      return;
    }

    if (quoteID) params["quoteID"] = quoteID;
    else params["quoteName"] = quoteName;

    axios({
      method: "POST",
      withCredentials: true,
      url: `${getSdkURL()}api/scope/splitCartToQuoteOrder`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    }).then((response) => {
      if (response?.status === 200 && response?.data?.failureActions?.length === 0) {
        toast.success("Items moved to quote successfully");
        setTimeout(navigate("/shopping-cart"), 1000);
      } else if (response?.data?.error !== "") toast.error(response?.data?.error);
    });
  };

  return (
    <>
      <div className="send-quote-form">
        <h3>Send to Quote</h3>
        <select
          className="form-select my-2"
          name="quoteId"
          id="quoteId"
          onChange={(e) => setQuoteID(e.target.value)}
          defaultValue={quoteID}
        >
          <option value="" selected>
            Select an Item
          </option>
          {cartAndQuotes.length > 0 &&
            cartAndQuotes
              .filter((item) => item.quoteName)
              .map((item) => {
                return (
                  <option key={item.orderID} value={item.orderID}>
                    {item.quoteName}
                  </option>
                );
              })}
        </select>

        {!quoteID && (
          <input
            name="quoteName"
            className="form-control my-2"
            value={quoteName}
            onChange={(e) => setQuoteName(e.target.value)}
            placeholder="Quote Name"
          />
        )}

        <div className="col-12 text-center">
          <button className="btn btn-success rounded" onClick={() => splitCartToQuoteOrder()}>
            Send to Quote
          </button>
        </div>
      </div>
    </>
  );
};

export { QuoteCheckOut };
