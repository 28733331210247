import { dynamicAreaLayoutEngine } from "../factory/Factory_Content_LayoutEngine.class";

export class storefrontPageModel {
  //Base page hooha
  urlTitle;
  urlTitlePath;
  contentPublicationDate;
  formattedSEO;
  contentID;
  contentPageType_systemCode;
  productListingPageFlag;
  settings;
  linkUrl;
  isPageFlag;
  pageType;
  title;
  linkLabel;
  sortOrder;
  permissions;
  description;
  pageLogo;
  styleOverrides;

  //Content lements
  contentElements = [];

  constructor({
    urlTitle = "home",
    urlTitlePath = "home",
    contentPublicationDate = "",
    formattedSEO = {},
    contentID = 1,
    contentPageType_systemCode = "Page",
    productListingPageFlag = false,
    settings = {},
    linkUrl = "",
    isPageFlag = true,
    pageType = "Standard",
    title = "Home",
    linkLabel = "",
    sortOrder = 0,
    permissions = {},
    contentElements = [],
    pageLogo = "",
    description = "",
    styleOverrides = {},
    templateData = {},
  } = {}) {
    this.urlTitle = urlTitle;
    this.urlTitlePath = urlTitlePath;
    this.contentPublicationDate = contentPublicationDate;
    this.formattedSEO = formattedSEO;
    this.contentID = contentID;
    this.contentPageType_systemCode = contentPageType_systemCode;
    this.productListingPageFlag = productListingPageFlag;
    this.settings = settings;
    this.linkUrl = linkUrl;
    this.isPageFlag = isPageFlag;
    this.pageType = pageType;
    this.title = title;
    this.linkLabel = linkLabel;
    this.sortOrder = sortOrder;
    this.permissions = permissions;
    this.description = description;
    this.pageLogo = pageLogo;
    this.styleOverrides = styleOverrides;
    this.templateData = templateData;

    //map element and add them to the class property
    const pageLayoutEngine = new dynamicAreaLayoutEngine();
    this.contentElements = pageLayoutEngine.processPageComponents(contentElements);
  }
}
