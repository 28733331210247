import { Link } from "react-router-dom";
import { useElementContext } from "../../contexts";
import { useMemo } from "react";
import { FooterContentProps } from "./FooterContent";

export const FooterStyleOne = ({ logo, theme, ribbonMenu, contentStack, formElement }: FooterContentProps) => {
  const {
    CommonModule: { PoweredBy, ContentStackMenu, SocialLinks },
  } = useElementContext();

  const filteredContentStack = useMemo(
    () => contentStack?.filter((child) => !["Footer Shop Menu"].includes(child.props.el.contentElementName)),
    [contentStack],
  );

  return (
    <>
      <div className="container d-flex justify-s-b align-start footer-container flex-wrap">
        <div className="row col-12 py-4">
          <div className="col-lg-4 col-md-4 col-sm-12 footer-brandImage flex-sm-column flex-md-row">
            {theme?.footerLogoTarget !== "None" && (
              <Link className="col-md-6 col-sm-12 d-block" to="/">
                <span className="navbar-brand d-block">
                  <img
                    src={`${logo?.trim()}`}
                    alt=""
                    className="img-fluid navbar-brandLogo"
                    style={{
                      maxHeight: "60px",
                      minWidth: "150px",
                      marginBottom: "1em",
                    }}
                  />
                </span>
              </Link>
            )}
            <div className="col-md-6 col-sm-12 pt-4" dangerouslySetInnerHTML={{ __html: theme.FooterAddress }} />
          </div>
          <div className="d-flex flex-column col-sm-12 col-md-8 col-lg-8 align-items-lg-end align-items-md-start">
            <div className="row w-100 justify-content-around flex-wrap flex-lg-nowrap">
              <ContentStackMenu contentStack={filteredContentStack} />
            </div>
            <div className="text-center align-self-center w-100">{formElement ? formElement : null}</div>
          </div>
        </div>
      </div>
      <div className="footer-ribbon flex-wrap">
        {theme.copyRight && <p className="m-0 me-2 align-self-center">{theme.copyRight}</p>}
        <SocialLinks />
        {ribbonMenu}
        <PoweredBy styleList={{ height: "25px", width: "fit-content" }} theme={theme} />
      </div>
    </>
  );
};
