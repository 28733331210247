import { useTranslation } from "react-i18next";
import { useElementContext } from "../../../contexts";

const FulfillmentPicker = ({
  orderItems,
  eligibleFulfillmentMethods,
  onSelect,
  onChangeOrderFullfillment,
  selectedFulfillmentMethod,
}) => {
  const {
    CommonModule: { SwRadioSelect },
  } = useElementContext();
  const { t } = useTranslation();
  const orderItemIDList = orderItems
    .map((orderItem) => {
      return orderItem.orderItemID;
    })
    .join();
  return (
    <div className="row">
      <div className="col-sm-12 px-0">
        {eligibleFulfillmentMethods && eligibleFulfillmentMethods.length > 0 && (
          <SwRadioSelect
            label={t("frontend.checkout.receive_option")}
            options={eligibleFulfillmentMethods}
            onChange={(fulfillmentMethodID) => {
              onChangeOrderFullfillment(fulfillmentMethodID, orderItemIDList);
              onSelect();
            }}
            selectedValue={selectedFulfillmentMethod.fulfillmentMethodID}
          />
        )}
      </div>
    </div>
  );
};

export { FulfillmentPicker };
