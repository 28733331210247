import { useSelector } from "react-redux";
import { CookieBanner } from "@palmabit/react-cookie-law";
import { useTranslation } from "react-i18next";
import { useUtilities } from "../../hooks/useUtilities";

const AnalyticsManager = ({ cookieSettings }) => {
  const showCookieBanner = useSelector((state) => state.configuration.analytics.showCookieBanner);
  const { eventHandlerForWSIWYG } = useUtilities();

  const { t } = useTranslation();
  const settings = {
    message: t("frontend.coookie.banner"),
    wholeDomain: true,
    onAccept: () => {},
    onAcceptPreferences: () => {},
    onAcceptStatistics: () => {
      window["Statistics-Allowed"] = true;
    },
    onAcceptMarketing: () => {
      window["Statistics-Allowed"] = true;
    },
    preferencesDefaultChecked: true,
    statisticsDefaultChecked: true,
    marketingDefaultChecked: true,
    managePreferencesButtonText: t("frontend.cookie.buttonNext"),
    policyLink: "/governance-and-policies",
    privacyPolicyLinkText: t("frontend.cookie_policy"),
    ...cookieSettings,
  };
  return (
    <div onClick={eventHandlerForWSIWYG}>
      {showCookieBanner && (
        <CookieBanner
          {...settings}
          styles={{
            button: {
              backgroundColor: "#eee",
              color: "#333",
              border: "1px solid #000",
              padding: "5px 10px",
              minWidth: "9rem",
              margin: "5px",
            },
            policy: {
              marginLeft: "10px",
              color: "#2478cc",
              backgroundColor: "none",
              fontSize: "12px",
            },
            container: {
              margin: "auto",
            },
            message: {
              padding: "10px 10px 20px 10px",
              color: "#000",
            },
          }}
        />
      )}
    </div>
  );
};

export { AnalyticsManager };
