import { SearchBarProps } from "./SearchBar.componentModel";
import { useComponentData } from "./SearchBar.componentService";
import { SearchBarView } from "./SearchBar.componentView";

const SearchBar = (props: SearchBarProps) => {
  const { componentData, componentService } = useComponentData(props);
  return <SearchBarView componentData={componentData} {...componentService} />;
};

export { SearchBar };
