//COMPONENTS
import { WhereToBuyLocation } from "./Components/WhereToBuyLocation/WhereToBuyLocation.component";

//CONTROLS
import { LocationCard } from "./Controls/LocationCard";
import { LocationHeader } from "./Controls/LocationHeader";
import { LocationAddress } from "./Controls/LocationAddress";
import { LocationDetail } from "./Controls/LocationDetail";
import { LocationMap } from "./Controls/LocationMap";
import { LocationFeatures } from "./Controls/LocationFeatures";
import { FilterCard } from "./Controls/FilterCard";
import { FilterBanner } from "./Controls/FilterBanner";
import { FilterLabeledInput } from "./Controls/FilterLabeledInput";
import { WhereToBuyLocationCard } from "./Controls/WhereToBuyLocationCard";

//CUSTOM

export namespace LocationModule {
  export const CoreComponents = {
    WhereToBuyLocation,
  };

  export const CoreControl = {
    FilterBanner,
    FilterCard,
    FilterLabeledInput,
    WhereToBuyLocationCard,
    LocationCard,
    LocationHeader,
    LocationDetail,
    LocationAddress,
    LocationMap,
    LocationFeatures,
  };

  export const Custom = {};
}
