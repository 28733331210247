import { useTranslation } from "react-i18next";
import { useElementContext } from "../../../contexts";

const AutoFulfillment = ({ fulfillment }) => {
  const {
    CommonModule: { OrderFulfillmentItems },
  } = useElementContext();
  const { t } = useTranslation();
  return (
    <div>
      <h2>{t("frontend.checkout.fulfillments.auto")}</h2>
      <div>
        <OrderFulfillmentItems fulfillment={fulfillment} />
      </div>
    </div>
  );
};
export { AutoFulfillment };
