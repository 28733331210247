import { CartLineItemView } from "./CartLineItem.componentView";
import { useComponentData } from "./CartLineItem.componentService";
import { CartLineItemProps } from "./CartLineItem.componentModel";

const CartLineItem = (props: CartLineItemProps) => {
  const { componentData, componentService } = useComponentData(props);

  return (
    <CartLineItemView
      componentData={componentData}
      onRemoveItem={componentService.onRemoveItem}
      setItemCount={componentService.setItemCount}
      onUpdateQty={componentService.onUpdateQty}
    />
  );
};
export { CartLineItem, type CartLineItemProps };
