import { useEffect } from "react";
import { SkuOptionProps } from "./SkuOptions.componentModel";
import { useComponentModel } from "./SkuOptions.componentService";
import { SkuOptionsComponentView } from "./SkuOptions.componentView";
import { OptionGroup, Option } from "../../../../interface/Product";

function SkuOptions(props: SkuOptionProps) {
  const { componentData, componetService } = useComponentModel(props);

  const clearFilters = () => {
    componetService.resetFilters();
  };

  const updateFilters = (optionGroup: OptionGroup, option: Option) => {
    //ADD CUSTOM FILTER PROCESSING BUSINESS LOGIC HERE
    if (componentData.selection[optionGroup.optionGroupCode] === option.optionCode) {
      componetService.removeFilter(optionGroup);
    } else {
      componetService.updateFilter(optionGroup, option, componentData.filteredProducts);
    }
  };

  useEffect(() => {
    // Sets the options to the default sku of only one sku is avaialble
    if (componentData.availableProducts.length === 1) componetService.setSelectionFromUrlParams();
  }, [componentData.availableProducts.length, componetService]);

  if (componentData.availableProducts.length <= 1) return null;

  return (
    <SkuOptionsComponentView componentData={componentData} resetFilters={clearFilters} updateFilters={updateFilters} />
  );
}

export { SkuOptions, type SkuOptionProps };
