import { createContext, PropsWithChildren, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useReview, useGetProductImageGallery } from "../hooks";
import { AttributeSet, Product, ProductBundle, ProductBundleBuildOnAccount } from "../interface/Product";
import { ReviewData } from "../interface/ProductReview";
import { ProductImage } from "../interface/ProductImageGallery";
import { useGetProductByUrlTitle } from "../hooks/components/ProductDetail/useGetProductByUrlTitle";
import { useElementContext } from "../contexts";

export interface IProductContext {
  isFetching: boolean;
  product: Product;
  attributeSets: AttributeSet[];
  productBundle: ProductBundle;
  productBundleBuildOnAccount?: ProductBundleBuildOnAccount;
  ratingData: ReviewData;
  imageGallery?: ProductImage[];
}

export const ProductContext = createContext<IProductContext>({
  isFetching: false,
  product: {} as Product,
  attributeSets: [],
  ratingData: {} as ReviewData,
  productBundle: [] as ProductBundle,
  productBundleBuildOnAccount: {} as ProductBundleBuildOnAccount,
});

export const ProductContextProvider = ({
  productUrlTitle,
  children,
}: PropsWithChildren<{ productUrlTitle?: string }>) => {
  const {
    CommonModule: { RedirectWithReplace },
  } = useElementContext();
  const location = useLocation();
  const urlTitle = productUrlTitle || location.pathname.split("/").pop();

  const productWithSettings = useGetProductByUrlTitle(urlTitle);

  const { product, attributeSets, productBundle, productBundleBuildOnAccount, isFetching, error } = productWithSettings;

  const ratingData: ReviewData = useReview(urlTitle);
  const { imageGallery } = useGetProductImageGallery(urlTitle);

  if (isFetching) return <ProductDetailLoading />;
  if (error.isError || !product) return <RedirectWithReplace pathname="/404" />;

  const data = {
    isFetching: productWithSettings.isFetching,
    product,
    attributeSets,
    productBundle,
    productBundleBuildOnAccount,
    ratingData,
    imageGallery,
  };

  return <ProductContext.Provider value={data}>{children}</ProductContext.Provider>;
};

const ProductDetailLoading = () => {
  const {
    CommonModule: { Spinner },
  } = useElementContext();
  return (
    <div className="container mt-5">
      <Spinner />
    </div>
  );
};

export const useProductContext = () => {
  // get the context
  const context = useContext(ProductContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error("useProductContext was used outside of its Provider");
  }

  return context;
};
