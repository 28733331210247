import {
  AttributeSet,
  Product,
  ProductBundle,
  ProductBundleBuildOnAccount,
  ProductSettings,
} from "../interface/Product";
// import { parseProductDescription } from "./utils";

export class ProductFactory {
  public product: Product;
  public attributeSets: AttributeSet[] = [];
  public productBundle: ProductBundle = [];
  public productBundleBuildOnAccount?: ProductBundleBuildOnAccount;
  public processingSettings = { lowestPriceCheck: true };

  constructor(
    data: {
      product: Product;
      attributeSets: AttributeSet[];
      productBundle: ProductBundle;
      productBundleBuildOnAccount?: ProductBundleBuildOnAccount;
    } = {
      product: {} as Product,
      attributeSets: [],
      productBundle: [],
    },
    processingSettings = { lowestPriceCheck: true },
  ) {
    this.processingSettings = processingSettings;
    this.product = data.product;
    // this.product.productDescription = parseProductDescription(this.product.productDescription);

    this.productBundle = data.productBundle;
    this.productBundleBuildOnAccount = data.productBundleBuildOnAccount;

    this.addDefaultsToData();
    this.processOptionGroups();
    this.lowestPriceCheck();
  }

  private addDefaultsToData() {
    if (!this.product.optionGroups) this.product.optionGroups = [];
    if (!this.product.skus) this.product.skus = [];
    if (!this.product.settings) this.product.settings = {} as ProductSettings;
  }

  processOptionGroups() {
    this.product.skus = this.product?.skus.map((sku: any) => {
      if (Array.isArray(sku.options)) {
        sku.slug = sku.options.map((opt: any) => `${opt.optionGroupCode}=${opt.optionCode}`).join("&");
      }
      return sku;
    });
  }

  lowestPriceCheck() {
    if (this.processingSettings.lowestPriceCheck) {
      if (this.product?.skus?.length) {
        const defaultSku = [
          this.product.skus.reduce((prev: any, curr: any) => (prev.listPrice < curr.listPrice ? prev : curr)),
        ];
        this.lowestPriceCheck_processOptions(defaultSku);
      }
    } else {
      if (this.product?.defaultSku_skuID?.length && this.product?.skus?.length) {
        const defaultSku = this.product.skus.filter((sku: any) => sku.skuID === this.product?.defaultSku_skuID);
        this.lowestPriceCheck_processOptions(defaultSku);
      }
    }
  }

  private lowestPriceCheck_processOptions(defaultSku: any) {
    if (defaultSku.length) {
      return (this.product["defaultSku_slug"] = defaultSku
        ?.at(0)
        .options?.map((opt: any) => `${opt.optionGroupCode}=${opt.optionCode}`)
        .join("&"));
    }
  }
}
