import { useUtilities } from "../../hooks/useUtilities";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { modalSizes } from "./Modal";
import { useComponentModel } from "../../pages/ProductDetailsPage/ProductDetailsPage.pageService";
import { ProductContextProvider } from "../../contexts/ProductContext";
import { useElementContext } from "../../contexts";

const ProductModalHeading = ({ title, skuCode = "", setShow }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="modal-header">
        <h3 className="modal-title">
          {t("frontend.bulkorder.listview.modal.configuring")}: {title}
        </h3>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            setShow(false);
          }}
        ></button>
      </div>
      <div className="modal-header">
        <h5 className="modal-title">
          {t("frontend.bulkorder.listview.modal.sku")}: {skuCode}
        </h5>
      </div>
    </div>
  );
};

const ProductModalView = ({ setShow, addToCart }) => {
  const {
    ProductModule,
    CommonModule: { Button },
  } = useElementContext();
  const { componentData } = useComponentModel();
  const {
    product,
    selectedSKu,
    productDescription,
    productDetailHeading,
    productDetailGallery,
    productPrice,
    skuOptions,
    productForm,
    productAttributes,
  } = componentData;
  const { t } = useTranslation();
  const { eventHandlerForWSIWYG } = useUtilities();
  const [itemCount, setItemCount] = useState(1);

  return (
    <div className="modal-content">
      <ProductModalHeading title={product.product_productName} skuCode={selectedSKu?.skuCode} setShow={setShow} />
      <div className="modal-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              {productDetailGallery && <ProductModule.CoreComponents.ProductDetailGallery {...productDetailGallery} />}
            </div>
            <div className="col-md-5">
              {productDetailHeading && <ProductModule.CoreComponents.ProductDetailHeading {...productDetailHeading} />}
              <div
                className="pt-4"
                onClick={eventHandlerForWSIWYG}
                dangerouslySetInnerHTML={{
                  __html: productDescription,
                }}
              />
            </div>
            <div className="col-md-4">
              <div className="my-4">
                {productPrice && <ProductModule.CoreComponents.ProductPrice {...productPrice} />}
              </div>
              {skuOptions && <ProductModule.CoreComponents.SkuOptions disableQs {...skuOptions} />}

              {productForm && (
                <>
                  <ProductModule.CoreControl.ProductQuantityInput
                    sku={selectedSKu}
                    quantity={itemCount}
                    setQuantity={setItemCount}
                    showLabel={productForm.showInputLabel}
                    quantityInputType={productForm.quantityInputMode}
                    showInventory={productForm.showInventory}
                  />

                  {/* Add to cart button */}
                  <Button
                    classList="btn btn-primary rounded"
                    onClick={(e) => {
                      e.preventDefault();
                      addToCart(selectedSKu.skuID, itemCount);
                    }}
                  >
                    {t("frontend.product.add_to_cart")}
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className="row">
            {productAttributes && <ProductModule.CoreComponents.ProductAttributes {...productAttributes} />}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductModal = ({
  show = true,
  setShow,
  product = {},
  size = "xLarge",
  footer = false,
  addToCart = (skuID, itemCount) => {},
}) => {
  return (
    <div
      className="modal"
      tabIndex="-1"
      aria-modal="true"
      role="dialog"
      style={{ display: show ? "inline" : "none" }}
      onClick={() => setShow(false)}
    >
      <div className={"modal-dialog modal-dialog-centered " + modalSizes[size]} onClick={(e) => e.stopPropagation()}>
        <ProductContextProvider productUrlTitle={product.urlTitle}>
          <ProductModalView setShow={setShow} addToCart={addToCart} />
        </ProductContextProvider>
      </div>
    </div>
  );
};

export { ProductModal };
