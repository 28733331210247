import { PropsWithChildren } from "react";

const MegaMenu = ({ customStyleClasses, children }: PropsWithChildren<{ customStyleClasses?: string }>) => {
  return (
    <nav className={`navbar navbar-expand-lg navbar-dark bg-dark shadow-sm py-2 ${customStyleClasses}`} id="mega-menu">
      <div className="container">
        <ul className="navbar-nav nav-categories w-100 align-items-center no-print">{children}</ul>
      </div>
    </nav>
  );
};

export { MegaMenu };
