import { FooterView } from "./Footer.componentView";
import { FooterProps } from "./Footer.componentModel";
import { useComponentData } from "./Footer.componentService";

const Footer = (props: FooterProps) => {
  const { componentData } = useComponentData(props);
  return <FooterView componentData={componentData} />;
};

export { Footer };
