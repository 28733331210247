import { useSEO } from "../../../hooks";
import { useElementContext } from "../../../contexts";
import { HeaderModel } from "./Header.componentModel";

export const HeaderView = ({
  componentData: { theme, siteSetting, ribbons, megaMenus, menus, filteredMenus },
}: {
  componentData: HeaderModel;
}) => {
  const {
    CommonModule: { HeaderContent },
  } = useElementContext();

  const { SEO } = useSEO();

  return (
    <header style={{ backgroundColor: theme.headerBarColor?.trim() }} className="col-12">
      <SEO />
      <HeaderContent
        theme={theme}
        ribbons={ribbons}
        megaMenus={megaMenus}
        filteredMenus={filteredMenus}
        siteSetting={siteSetting}
      />
    </header>
  );
};
