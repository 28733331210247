import { useSelector } from "react-redux";
import { getProductTypeRoute } from "../../selectors/configurationSelectors";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useUtilities } from "../useUtilities";
import { getSdkURL } from "../../services";
import queryString from "query-string";

const useProductType = () => {
  const { id } = useParams();
  let { pathname } = useLocation();
  const { augmentProductType } = useUtilities();
  const [productTypeData, setProductTypeData] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const productTypeRoute = useSelector(getProductTypeRoute);
  const productTypeBase = useSelector((state) => state.configuration.filtering.productTypeBase);

  const crumbCalculator = useCallback(() => {
    if (
      process.env.REACT_APP_DELTA_STORE_URL &&
      process.env.REACT_APP_DELTA_ENTITY_TYPES.split(",").includes("product-type")
    ) {
      const crumbs = productTypeData?.ancestors?.slice(1).map((ancestor, idx) => ({
        title: ancestor.productTypeName,
        urlTitle: `/${productTypeRoute}/${productTypeData?.ancestors
          .slice(1, idx + 2)
          .map((c) => c.urlTitle)
          .join("/")}`,
      }));

      return crumbs;
    } else {
      return productTypeData?.ancestors
        ?.map((crumb) => {
          return { title: crumb.productTypeName, urlTitle: crumb.urlTitle };
        })
        .filter((crumb) => crumb.urlTitle !== productTypeBase)
        .filter((crumb) => crumb.urlTitle !== id)
        .map((crumb) => {
          return { ...crumb, urlTitle: `/${productTypeRoute}/${crumb.urlTitle}` };
        });
    }
  }, [productTypeData?.ancestors, productTypeRoute, productTypeBase, id]);

  const fetchProductType = useCallback(
    async (pathname) => {
      const urlTitles = pathname?.split("/").filter((n) => !!n) || [];
      urlTitles.shift();
      const urlTitle = urlTitles.join("/");
      if (
        process.env.REACT_APP_DELTA_STORE_URL &&
        process.env.REACT_APP_DELTA_ENTITY_TYPES?.split(",").includes("product-type")
      ) {
        const productTypeData = await fetch(
          `${process.env.REACT_APP_DELTA_STORE_URL}/public/ultracommerce/product-type/transform/byUrlTitle/merchandise/${urlTitle}`,
        )
          .then((resp) => resp.json())
          .then(async (data) => {
            const childProductTypes = await Promise.all(
              data.children.map(async (child) => {
                const childProductType = await fetch(
                  `${process.env.REACT_APP_DELTA_STORE_URL}/public/ultracommerce/product-type/transform/byUrlTitle/${child.productTypeUrlTitlePath}`,
                )
                  .then((resp) => resp.json())
                  .catch(() => ({ children: [] }));
                return {
                  ...child,
                  urlTitle: child.productTypeUrlTitlePath.replace("merchandise/", ""),
                  childProductTypes: childProductType.children?.map((child) => ({
                    ...child,
                    urlTitle: child.productTypeUrlTitlePath.replace("merchandise/", ""),
                  })),
                };
              }),
            );
            return { ...data, childProductTypes };
          });

        return productTypeData;
      }

      const {
        data: { productType },
      } = await fetch(`${getSdkURL()}/api/public/getEntity?entityName=productType&urlTitle=${urlTitle}`).then((res) =>
        res.json(),
      );

      const {
        data: { pageRecords },
      } = await fetch(
        `${getSdkURL()}/api/public/getEntity?${queryString.stringify({
          entityName: "productType",
          "f:productTypeIDPath:like": `%${productType.productTypeID}%`,
          "p:show": 250,
          includeSettingsInList: true,
        })}`,
      ).then((res) => res.json());

      const productTypeData = { ...productType, ...augmentProductType(urlTitle, pageRecords) };

      return productTypeData;
    },
    [augmentProductType],
  );

  useEffect(() => {
    let isUnmounted = false;
    const fetchData = async () => {
      setFetching(true);
      setError(false);
      setProductTypeData({});
      setErrorMessage("");
      try {
        const data = await fetchProductType(pathname);
        if (!isUnmounted) {
          setProductTypeData(data);
          setError(false);
          setFetching(false);
        }
      } catch {
        if (!isUnmounted) {
          setError(true);
          setFetching(false);
          setErrorMessage("Error fetching product type");
        }
      }
    };

    fetchData();

    return () => {
      isUnmounted = true;
    };
  }, [fetchProductType, pathname]);

  return {
    isFetching,
    isError,
    productTypeData,
    crumbCalculator,
    productTypeRoute,
    errorMessage,
  };
};

const formatProductTypeCachedResponse = (data) => ({
  ...data,
  childProductTypes: data.children.map((child) => ({
    ...child,
    urlTitle: child.productTypeUrlTitlePath.replace("merchandise/", ""),
  })),
});

export { useProductType, formatProductTypeCachedResponse };
