export interface LocationFeaturesProps {
  features: string[];
}

export const LocationFeatures = ({ features }: LocationFeaturesProps) => {
  return (
    <div className="d-flex gap-2">
      {features.map((feature, index) => (
        <label key={index} className={`p-1 px-2 m-0 rounded text-break ${"bg-primary text-light"}`}>
          {feature}
        </label>
      ))}
    </div>
  );
};
