import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getProductRoute } from "../../../selectors";
import { useElementContext } from "../../../contexts";

export interface BundleItemProps {
  bundleItem: any;
  minValue: number;
  maxValue: number;
  bundlesOnAccount: { [key: string]: any };
  addBundleBuild: (skuID: string, quantity: number) => void;
}

export const BundleItem = ({ bundleItem, minValue, maxValue, bundlesOnAccount, addBundleBuild }: BundleItemProps) => {
  const productRouting = useSelector(getProductRoute);
  const [quantity, setQuantity] = useState(0);
  const { t } = useTranslation();
  const {
    ProductModule,
    CommonModule: { SimpleImage },
  } = useElementContext();

  useEffect(() => {
    setQuantity(bundlesOnAccount[bundleItem["skuID"]]?.quantity || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundlesOnAccount]);

  return (
    <div className="row border-bottom py-3">
      <div className="col-sm-2 col-3">
        {bundleItem.images && bundleItem.images?.length > 0 && (
          <SimpleImage
            className="img-fluid  m-auto image_container productImage border border-light"
            src={bundleItem.images?.at(0)}
            alt={bundleItem.product_productName}
            type="product"
          />
        )}
      </div>
      <div className="col-sm-4 col-9">
        <h5>
          <Link
            to={{
              pathname: `/${productRouting}/${bundleItem?.product_urlTitle}`,
            }}
            className="link"
          >
            {bundleItem.product_productName}
          </Link>
        </h5>
        <div className="font-size-sm">
          <span className="text-muted mr-2"> {bundleItem?.product_brand_brandName}</span>
        </div>
        <div className="font-size-sm">
          <span className="text-muted mr-2">{bundleItem.skuCode}</span>
        </div>
      </div>
      <div className="col-sm-3">
        <ProductModule.CoreComponents.ProductPrice type="cart" salePrice={bundleItem.price} />
      </div>
      <div className="col-sm-3">
        <div className="row m-2">
          <div className="col-sm-6">
            <small>{t("frontend.cart.quantity")}</small>
            <input
              type="number"
              className="form-control"
              value={quantity}
              onChange={(e) => {
                e.preventDefault();
                let value = parseInt(e.target.value, 10);
                if (value < 0) return;
                if (value > maxValue) return;
                if (value < minValue) value = minValue;
                setQuantity(value);
              }}
            />
          </div>
        </div>
        <div className="row justify-content-center m-2">
          <button
            className="w-100 btn btn-primary d-block col-3"
            onClick={(e) => {
              e.preventDefault();
              addBundleBuild(bundleItem.skuID, quantity);
            }}
          >
            {t("frontend.product.productBundle.add_to_bundle")}
          </button>
        </div>
      </div>
    </div>
  );
};
