import { ProductDetailHeadingView } from "./ProductDetailHeading.componentView";
import { ProductDetailHeadingProps } from "./ProductDetailHeading.componentModel";
import { useComponentData } from "./ProductDetailHeading.componentService";

const ProductDetailHeading = (props: ProductDetailHeadingProps) => {
  const { componentData } = useComponentData(props);

  return <ProductDetailHeadingView componentData={componentData} />;
};

export { ProductDetailHeading, type ProductDetailHeadingProps };
