import { useFormik } from "formik";
import { toast } from "react-toastify";
import queryString from "query-string";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { getUser, getWishLists } from "../../../actions";
import { SlatwalApiService } from "../../../services";
import { getErrorMessage } from "../../../utils";

const useCreateAccount = () => {
  const dispatch = useDispatch();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  let navigate = useNavigate();
  const { search } = useLocation();
  // eslint-disable-next-line no-unused-vars
  const signupSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    phoneNumber: Yup.string().required("Required").matches(phoneRegExp, "Phone number is not valid"),
    password: Yup.string().required("Required"),
    passwordConfirm: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
    emailAddress: Yup.string().email("Invalid email").required("Required"),
    emailAddressConfirm: Yup.string()
      .email("Invalid email")
      .oneOf([Yup.ref("emailAddress"), null], "Emails must match"),
    company: Yup.string(),
    australianBusinessNumber: Yup.string(),
    builderLicenseNumber: Yup.string(),
    addressLineOne: Yup.string().required("Required"),
    addressLineTwo: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    userState: Yup.string().required("Required"),
    userCountry: Yup.string().required("Required"),
    userPostCode: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      returnTokenFlag: "1",
      createAuthenticationFlag: "1",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      emailAddress: "",
      emailAddressConfirm: "",
      password: "",
      passwordConfirm: "",
      company: "",
      australianBusinessNumber: "",
      builderLicenseNumber: "",
      addressLineOne: "",
      addressLineTwo: "",
      city: "",
      userState: "",
      userCountry: "Australia",
      userPostCode: "",
    },
    validateOnChange: false,
    validationSchema: signupSchema,
    onSubmit: (values) => {
      SlatwalApiService.account
        .create(values)
        .then((response) => {
          if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) {
            toast.error(getErrorMessage(response.success().errors));
            return Promise.reject(getErrorMessage(response.success().errors));
          }
          if (response.isSuccess()) {
            if (!response.success().failureActions.length) {
              toast.success("Success");
              dispatch(getUser()).then(() => {
                dispatch(getWishLists());
              });
            }
          } else {
            toast.error("Error");
            return Promise.reject("Error");
          }
        })
        .then(() => {
          if (search.includes("redirect")) {
            const params = queryString.parse(search);
            navigate(params.redirect);
          } else {
            navigate({
              pathname: "/my-account/overview",
            });
          }
        });
    },
  });

  return { formik };
};

export { useCreateAccount };
