import { useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FilterSpec } from "../../Controls/FilterBanner";

import { Location } from "../../../../interface/Location";
import { WhereToBuyLocationModel, WhereToBuyLocationProps } from "./WhereToBuyLocation.componentModel";

const defaultFilterStack = JSON.stringify({
  filterStack: [
    {
      selection: "",
      title: "CATEGORIES",
      filteredObjectProperty: "StockistOrReseller",
      inputType: "radio",
      comparator: "eq",
    },
    { selection: "", title: "STATE", filteredObjectProperty: "PhysicalState", inputType: "radio", comparator: "eq" },
  ],
});

const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    //Error
    //JSON is not okay
    return false;
  }

  return true;
};

export const useComponentData = (props: WhereToBuyLocationProps) => {
  const theme = useSelector((state: any) => state.configuration.site.theme);

  const {
    relatedCollectionDataList,
    wtbFilters = defaultFilterStack,
    wtbMapConfiguration = JSON.stringify(theme.mapsBaseConfig ? theme.mapsBaseConfig : ""),
  } = props;
  const [searchValue, setSearchValue] = useState("");
  const [filterStack, setFilterStack] = useState<FilterSpec[]>(
    isJson(wtbFilters) ? JSON.parse(wtbFilters)?.filterStack : [],
  );
  const [displayedRetailLocations, setDisplayedRetailLocations] = useState<Location[]>([]);

  useLayoutEffect(() => {
    setDisplayedRetailLocations(
      relatedCollectionDataList
        ?.sort((a, b) => a.CustomerName.localeCompare(b.CustomerName))
        .map((rtl) => {
          rtl.searchHash = Object.values(rtl)
            .filter((e) => `${e}`?.trim()?.length)
            .join("|")
            .toLocaleLowerCase();
          rtl.active = true;
          return rtl;
        }),
    );
  }, [relatedCollectionDataList]);

  // change cetner of map when a RetailLocation is clicked //JSON.parse(wtbMapConfiguration)?.mapCenter
  const [mapCenter, setMapCenter] = useState(JSON.parse(wtbMapConfiguration)?.mapCenter);
  const [defaultZoom] = useState(JSON.parse(wtbMapConfiguration)?.defaultZoom);

  const updateFiltersAndLocations = (key: string, selection: string, searchText: string = searchValue) => {
    setSearchValue(searchText);
    setFilterStack((filters) => {
      return filters.map((filter) => {
        if (filter.title === key) {
          filter.selection = selection;
        }
        return filter;
      });
    });

    const requiredFilterStack = filterStack
      .map((filter) => {
        if (filter.title === key) {
          filter.selection = selection;
        }
        return filter;
      })
      .filter((el) => el.selection.length > 0);

    setDisplayedRetailLocations((locs) =>
      locs.map((retailLoc) => {
        retailLoc.active =
          requiredFilterStack.filter((filterEl) => {
            return (
              (retailLoc[filterEl.filteredObjectProperty as keyof Location] as string)?.trim?.() === filterEl.selection
            );
          }).length === requiredFilterStack.length &&
          (searchText.length > 0 ? retailLoc.searchHash?.includes(searchText?.toLowerCase()) : true);
        return retailLoc;
      }),
    );
  };

  const onLocationClicked = (addressLatitude: string, addresslongitude: string) => {
    setMapCenter({
      AddressLatitude: parseFloat(addressLatitude),
      AddressLongitude: parseFloat(addresslongitude),
    });
    const requiredFilterStack = filterStack?.filter((el) => el.selection.length > 0);
    setDisplayedRetailLocations((locs) =>
      locs.map((retailLoc) => {
        retailLoc.active =
          requiredFilterStack?.filter((filterEl) => {
            return (
              (retailLoc[filterEl.filteredObjectProperty as keyof Location] as string)?.trim?.() === filterEl.selection
            );
          }).length === requiredFilterStack.length &&
          (searchValue.length > 0 ? retailLoc.searchHash?.includes(searchValue?.toLowerCase()) : true);
        return retailLoc;
      }),
    );
  };
  const componentData = useMemo(
    () =>
      new WhereToBuyLocationModel({
        props,
        searchValue,
        filterStack,
        mapCenter,
        defaultZoom,
        displayedRetailLocations,
      }),
    [defaultZoom, filterStack, mapCenter, props, searchValue, displayedRetailLocations],
  );
  return { componentData, componentService: { onLocationClicked, updateFiltersAndLocations, setSearchValue } };
};
