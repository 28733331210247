import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useFormatCurrency } from "../../../hooks";
import { getProductRoute } from "../../../selectors";
import { useElementContext } from "../../../contexts";

export interface BundleConfigProps {
  bundlesOnAccount: { [key: string]: any };
  addToCart: () => void;
  removeProductBundleBuild: () => void;
  removeProductBundleItem: (productBundleBuildItemID: string) => void;
}

const BundleConfig = ({
  bundlesOnAccount,
  addToCart,
  removeProductBundleBuild,
  removeProductBundleItem,
}: BundleConfigProps) => {
  const { t } = useTranslation();
  const {
    CommonModule: { SimpleImage },
  } = useElementContext();

  const [formatCurrency] = useFormatCurrency({});
  const productRoute = useSelector(getProductRoute);

  if (!Object.entries(bundlesOnAccount).length) return null;
  return (
    <div className="accordion mt-2 mb-2" id={`accordion1`}>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse1`}
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            {t("frontend.product.productBundle.bundle_config")}
          </button>
        </h2>
        <div
          id={`collapse1`}
          className="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div className="d-flex justify-content-end">
              <button className="btn btn-light" onClick={removeProductBundleBuild}>
                <i className="bi bi-trash"></i>
              </button>
            </div>
            <div className="row w-100 mt-2">
              {bundlesOnAccount &&
                Object.entries(bundlesOnAccount).map(([skuID, item], index) => {
                  return (
                    <div className="col-3 d-flex mt-2" key={skuID}>
                      {index !== 0 ? (
                        <i className="bi bi-plus-circle col-2 align-self-center"></i>
                      ) : (
                        <div className="col-2"></div>
                      )}
                      <div className="card col-10">
                        <i
                          className="bi bi-trash3 position-absolute text-primary"
                          style={{ right: "-5px", top: "-5px" }}
                          onClick={() => removeProductBundleItem(item.productBundleBuildItemID)}
                        ></i>
                        <div className="card-body text-center">
                          <Link to={`/${productRoute}/${item.product_urlTitle}`}>
                            <SimpleImage
                              className="img-fluid  m-auto image_container productImage"
                              src={item?.images?.at(0)}
                              alt={item?.product_productName}
                              type="product"
                            />
                          </Link>
                        </div>
                        <div className="card-footer">
                          <p className="text-dark">
                            {" "}
                            {`${formatCurrency(item.salePrice || item.price)} x ${item.quantity}`}
                          </p>
                          <Link to={`/${productRoute}/${item.product_urlTitle}`}>{item?.product_productName}</Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="row justify-content-center mt-2">
              <button className="btn btn-dark d-block col-3" onClick={addToCart}>
                {t("frontend.product.productBundle.add_to_cart")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { BundleConfig };
