import { useState } from "react";

const FormMultiSelectField = ({
  attributeName,
  attributeCode,
  requiredFlag,
  options,
  setValue,
  value,
  invalid,
  validationMessage,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  let label;
  if (value?.length)
    label = options
      .filter((o) => value?.includes(o.value))
      .map((o) => o.name)
      .join(", ");
  else label = "Select";

  return (
    <fieldset>
      <div className={`form-label ${requiredFlag ? "required" : ""}`}>{attributeName}</div>
      <button
        className="btn btn-primary"
        type="button"
        onClick={(e) => {
          setShowDropdown((s) => !s);
        }}
      >
        {label}
      </button>
      <div className={showDropdown ? "show" : "collapse"}>
        {options.map((option, idx) => {
          return (
            <div className={"form-check"} key={idx}>
              <input
                onChange={() => {
                  setValue((response) => {
                    let val;
                    if (response[attributeCode]?.includes(option.value))
                      val = response[attributeCode].filter((i) => i !== option.value);
                    else {
                      const pushRes = response[attributeCode]?.push(option.value);
                      if (!pushRes) val = [option.value];
                      else val = response[attributeCode];
                    }
                    return {
                      ...response,
                      [attributeCode]: val,
                    };
                  });
                }}
                value={attributeCode}
                className={"form-check-input" + (invalid ? " is-invalid" : "")}
                type="checkbox"
                name={attributeCode + option.name}
                checked={value?.includes(option.value)}
                id={attributeCode + option.name}
              />
              <label className="form-check-label" htmlFor={attributeCode + option.name}>
                {option.name}
              </label>
            </div>
          );
        })}
      </div>
      {invalid && <div className="invalid-feedback">{validationMessage ?? ""}</div>}
    </fieldset>
  );
};

export default FormMultiSelectField;
