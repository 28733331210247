import { useTranslation } from "react-i18next";
import { ProductBundleItemsModel } from "./ProductBundleItems.componentModel";
import { useElementContext } from "../../../../contexts";

const ProductBundleItemsView = ({
  componentData: { totalPages, productItems, productBundleItem, isOpen, currentPage, bundlesOnAccount },
  addBundleBuild,
  getProductBundles,
  setIsOpen,
  setCurrentPage,
}: {
  componentData: ProductBundleItemsModel;
  addBundleBuild: (skuID: string, quantity: number) => void;
  getProductBundles: (productBundleGroupID: string, page?: number) => void;
  setIsOpen: (isOpen: boolean) => void;
  setCurrentPage: (page: number) => void;
}) => {
  const { t } = useTranslation();
  const {
    ProductModule,
    CommonModule: { ListingPagination },
  } = useElementContext();

  return (
    <div
      className="accordion mt-2 mb-2"
      id={`accordion_${productBundleItem.productBundleGroupID}`}
      key={productBundleItem.productBundleGroupID}
    >
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse_${productBundleItem.productBundleGroupID}`}
            aria-expanded="false"
            aria-controls="collapseOne"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {productBundleItem?.bundleType} :{" "}
            {productBundleItem?.minimumQuantity === productBundleItem?.maximumQuantity
              ? t("frontend.product.productBundle.select_count_products", {
                  count: productBundleItem?.minimumQuantity,
                })
              : t("frontend.product.productBundle.select_min_max_products", {
                  min: `${productBundleItem?.minimumQuantity}`,
                  max: `${productBundleItem?.maximumQuantity}`,
                })}
          </button>
        </h2>
        <div
          id={`collapse_${productBundleItem.productBundleGroupID}`}
          className="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent={`#accordion_${productBundleItem.productBundleGroupID}`}
        >
          <div className="accordion-body">
            {productItems?.skuList?.map((item: any) => {
              return (
                <ProductModule.CoreControl.BundleItem
                  key={item.skuID}
                  bundleItem={item}
                  minValue={productItems.minimumQuantity}
                  maxValue={productItems.maximumQuantity}
                  bundlesOnAccount={bundlesOnAccount}
                  addBundleBuild={addBundleBuild}
                />
              );
            })}

            <ListingPagination
              recordsCount={productItems?.totalSkuCount}
              currentPage={currentPage}
              totalPages={totalPages}
              setPage={(page: number) => {
                setCurrentPage(page);
                getProductBundles(productBundleItem.productBundleGroupID, page);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProductBundleItemsView };
