import { ReactElement, ReactNode } from "react";
import { useElementContext } from "../../contexts";

export interface FooterContentProps {
  ribbonMenu: ReactNode;
  logo: string;
  theme: any;
  contentStack: ReactElement[];
  formElement?: ReactElement | null;
}

const FooterContent = (props: FooterContentProps) => {
  const {
    CommonModule: { FooterStyleOne, FooterStyleTwo, FooterStyleThree },
  } = useElementContext();

  switch (props.theme.footerStyleOption) {
    case "two":
      return <FooterStyleTwo {...props} />;
    case "three":
      return <FooterStyleThree {...props} />;
    default:
      return <FooterStyleOne {...props} />;
  }
};

export { FooterContent };
