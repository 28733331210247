import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useElementContext } from "../../../contexts";

export const HeaderContentFour = ({
  theme,
  siteSetting,
  filteredMenus = [],
}: {
  theme: any;
  siteSetting: any;
  filteredMenus: ReactNode[];
}) => {
  const { t } = useTranslation();
  const {
    CommonModule: { MobileHeader, MiniCart, SearchBar },
  } = useElementContext();

  return (
    <div className="row col-12 align-items-center justify-content-between position-relative">
      <div className="d-flex filtered-block-div col-5 col-lg-3">
        <Link className="d-block" to="/">
          <span className="navbar-brand d-block">
            {theme && theme.logo ? (
              <img
                src={`${theme.logo?.trim()}`}
                className="img-fluid navbar-brandLogo"
                alt={t("frontend.logo")}
                style={{ maxHeight: "60px", minWidth: "150px" }}
              />
            ) : null}
          </span>
        </Link>
        {theme.headerTagLine && <div className="d-none d-lg-block">{theme.headerTagLine}</div>}
      </div>

      <div className="d-none d-lg-flex flex-column justify-content-center col-4">
        <div className="d-lg-flex m-auto">
          <SearchBar searchBoxTypeaheadFlag redirectToSearchPage searchBoxPlaceholder={theme?.searchPlaceholder} />
        </div>
      </div>

      <MobileHeader siteSetting={siteSetting} />

      <div className="ms-2 d-none d-lg-flex justify-content-end text-end col-4">
        {filteredMenus.length > 0 && <div>{filteredMenus}</div>}
        {siteSetting.sitePurpose !== "Non-Transactional" && <MiniCart />}
      </div>
    </div>
  );
};
