import { HeartButtonModel } from "./HeartButton.componentModel";

const HeartButtonView = ({
  componentData: { className = "link btn-wishlist", liked, showHeartButton, primaryColor },
  onHeartButtonClicked,
}: {
  componentData: HeartButtonModel;
  onHeartButtonClicked: () => void;
}) => {
  if (!showHeartButton) {
    return null;
  }

  return (
    <button
      className={className}
      onClick={(e) => {
        e.preventDefault();
        onHeartButtonClicked();
      }}
      type="button"
      data-toggle="tooltip"
      data-placement="left"
      title=""
      data-original-title={liked ? "Remove from wishlist" : "Add to wishlist"}
    >
      {liked ? (
        <i className="bi bi-heart-fill link" style={{ color: `#${primaryColor}` }}></i>
      ) : (
        <i className="bi bi-heart"></i>
      )}
    </button>
  );
};
export { HeartButtonView };
