import { useContentContext } from "../../contexts/ContentContext";

import { useElementContext } from "../../contexts";

const Shop = () => {
  const pageData = useContentContext();
  const {
    ProductListingModule,
    PageModule: { DynamicPage },
    CommonModule: { PageContentRow },
  } = useElementContext();

  const { searchListing } = pageData.templateData || {};

  return (
    <DynamicPage>
      {({ renderDynamicContent }) =>
        searchListing ? (
          <>
            <PageContentRow>
              <ProductListingModule.CoreComponents.SearchListing {...searchListing} />
            </PageContentRow>
            {renderDynamicContent()}
          </>
        ) : null
      }
    </DynamicPage>
  );
};

export default Shop;
