import { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FormContext } from "../DynamicForm";
import useFormRecaptcha from "../hooks/useFormRecaptcha";

export function SubmitButton() {
  const { t } = useTranslation();

  const { submitDisabled, formAction, actionButtonText } = useContext(FormContext);

  useFormRecaptcha();

  return (
    <>
      <div className="d-flex flex-column">
        {formAction === "Sign Up" ? (
          <>
            <Link to="my-account/login">{t("frontend.account.back_to_login")}</Link>
            <div className="d-flex w-100  justify-content-end">
              <button className="btn btn-primary btn-block mt-2" disabled={submitDisabled}>
                <span className="d-sm-inline">{actionButtonText}</span>
              </button>
            </div>
          </>
        ) : (
          <div className="d-flex w-100 justify-content-start">
            <button className="btn btn-primary btn-block mt-2" disabled={submitDisabled}>
              <span className="d-sm-inline">{actionButtonText}</span>
              <i className="bi bi-arrow-right-circle float-end mt-1 ms-2"></i>
            </button>
          </div>
        )}
      </div>
    </>
  );
}
