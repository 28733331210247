import { storefrontPageModel } from "./models/storefrontPageModel.class";
import { storefront500model } from "./models/storefront404.class";
import queryString from "query-string";

export class StorefrontContentService {
  #serviceDomain = null;
  siteElements = null;

  constructor() {
    this.#serviceDomain =
      process.env.REACT_APP_LOCAL_CMS === "true" ? "http://localhost" : window.UC?.integrations?.cms.moduleApiUrl;
  }

  set serviceDomain(serviceDomain) {
    this.#serviceDomain = serviceDomain;
  }

  get serviceDomain() {
    return this.#serviceDomain;
  }

  getPage(params) {
    return this.fetchPage(params);
  }

  fetchPage(params) {
    const servicePath = "/api/get-content?pageId=";
    return fetch(this.serviceDomain + servicePath + params.urlTitlePath)
      .then((response) => response.json())
      .then((response) => (response.content ? response.content : false))
      .then((response) => {
        //hydrate the content elements in the response
        if (!response.contentID) {
          return this.fetch404Page();
        }
        if (response.contentElements) return new storefrontPageModel(response);
        return response;
      })
      .then((hydrated) => {
        return { isSuccess: true, hydrated };
      })
      .catch((error) => {
        console.error("Get Page API error: ", error);
        return new storefront500model();
      });
  }

  fetch404Page() {
    const servicePath = "/api/get-content?pageId=404";
    return fetch(this.serviceDomain + servicePath)
      .then((response) => response.json())
      .then((response) => (response.content ? response.content : false))
      .then((response) => {
        return new storefrontPageModel(response);
      })
      .catch((error) => {
        console.error("Critical failure lodaing 404 page: ", error);
      });
  }

  #fetchSiteElements() {
    //This gets site common this like the header & footer
    const servicePath = "/api/get-site-elements";
    return fetch(this.serviceDomain + servicePath).then((response) =>
      response.json().then((siteElementData) => (this.siteElements = siteElementData)),
    );
  }

  #fetchSiteTheme() {
    const servicePath = "/api/get-theme-settings";
    return fetch(this.serviceDomain + servicePath).then((response) => response.json());
  }

  #fetchSiteSettings() {
    const servicePath = "/api/get-site-settings";
    return fetch(this.serviceDomain + servicePath).then((response) => response.json());
  }

  fetchNewConfig(oldSiteConfig) {
    let configPromise = Promise.all([
      this.#fetchSiteSettings(),
      this.#fetchSiteTheme(),
      this.#fetchSiteElements(),
    ]).then((response) => {
      const settings = response[0];
      const themeConfig = response[1];
      const header = response[2].header ? response[2].header : null;
      const footer = response[2].footer ? response[2].footer : null;

      const newSiteConfig = {
        site: {
          ...oldSiteConfig.site,
          header: header,
          footer: footer,
          settings: {
            ...oldSiteConfig.site.settings,
            ...settings,
          },
          theme: {
            ...themeConfig,
            additionalLogo: themeConfig.additionalLogo?.url,
            logo: themeConfig.logo?.url,
            favicon: themeConfig.favicon?.url,
            footerPoweredBy: themeConfig.footerPoweredBy,
          },
        },
      };

      return newSiteConfig;
    });
    return configPromise;
  }

  fetchCollectionList(collectionName, params = {}) {
    return fetch(`${this.serviceDomain}/api/${collectionName}?${queryString.stringify(params)}`).then((response) =>
      response.json(),
    );
  }
}
