import { useFormatDate, useUtilities } from "../../hooks";

const BlogPostBody = ({ blogPostData }) => {
  const { eventHandlerForWSIWYG } = useUtilities();
  const [formateDate] = useFormatDate();
  return (
    <article className="blogPost shadow col-md-8 col-sm-12 py-4 h-100 m-auto">
      {blogPostData.postImage && (
        <img src={blogPostData.postImage.url} className="mw-100" alt={blogPostData.title} type="none" />
      )}
      <div className="article_articleBody">
        {blogPostData?.contentPublicationDate && (
          <div className="entry-meta p-4">
            <ul className="list-unstyled d-flex m-0">
              <li className="d-flex align-items-center mr-3">
                <i className="bi bi-clock me-2 small line-height-0"></i>
                {formateDate(blogPostData.contentPublicationDate)}
              </li>
            </ul>
          </div>
        )}
      </div>

      <div
        className="entry-content py-2 px-4"
        onClick={eventHandlerForWSIWYG}
        dangerouslySetInnerHTML={{ __html: blogPostData.contentSummary }}
      />
      {blogPostData.Media?.map((media, index) => (
        <img key={index} src={media.url} className="mw-100" alt={blogPostData.title} type="none" />
      ))}
    </article>
  );
};

export { BlogPostBody };
