import ReactGA from "react-ga4";
import { EventProductItem, GaEvent } from "../interface/GaEvent";
import { Product, Sku } from "../interface/Product";
import { Cart, OrderItem } from "../interface/Cart";

const formatProductItem = (product: Product): EventProductItem => ({
  item_id: product.productID,
  item_name: product.productName,
  item_brand: product.brand_brandName,
  price: product.salePrice,
});

const formatSkuItem = (sku: Sku, quantity: number): EventProductItem => ({
  item_id: sku.skuID || sku.sku_skuID,
  item_name: sku.skuName,
  price: sku.salePrice,
  item_brand: sku.product_brand_brandName,
  quantity,
});

const formatOrderItem = (orderItem: OrderItem): EventProductItem => ({
  item_id: orderItem.sku.skuID,
  item_name: orderItem.sku.skuName,
  price: orderItem.extendedUnitPriceAfterDiscount,
  item_brand: orderItem.sku.product?.brand?.brandName,
  quantity: orderItem.quantity,
});

class LogService {
  allowedEvents?: string[];
  excludedEvents?: string[];
  constructor({ excludedEvents, allowedEvent }: { allowedEvent?: string[]; excludedEvents?: string[] } = {}) {
    this.excludedEvents = excludedEvents;
    this.allowedEvents = allowedEvent;
  }

  initialize() {
    if (!process.env.REACT_APP_MEASUREMENT_ID) return;
    ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID, { gtagOptions: { debug_mode: true } });
  }

  sendEvent<Event extends keyof GaEvent>(event: Event, payload: GaEvent[Event]) {
    if (!process.env.REACT_APP_MEASUREMENT_ID) return;
    if (this.allowedEvents && !this.allowedEvents.includes(event)) return;
    if (this.excludedEvents && this.excludedEvents.includes(event)) return;
    ReactGA.gtag("event", event, payload);
  }

  viewItem(product: Product[]) {
    this.sendEvent("view_item", {
      currency: product[0]?.skus[0]?.settings.skuCurrency,
      items: product.map(formatProductItem),
    });
  }

  addToCart(skus: Sku[], quantities: number[]) {
    this.sendEvent("add_to_cart", {
      currency: skus[0]?.settings.skuCurrency,
      items: skus.map((sku, index) => formatSkuItem(sku, quantities[index])),
    });
  }

  removeFromCart(orderItems: OrderItem[]) {
    this.sendEvent("remove_from_cart", {
      currency: orderItems[0]?.currencyCode,
      items: orderItems.map(formatOrderItem),
    });
  }

  beginCheckout(orderItems: OrderItem[]) {
    this.sendEvent("begin_checkout", { currency: orderItems[0].currencyCode, items: orderItems.map(formatOrderItem) });
  }

  purchase(cart: Cart) {
    this.sendEvent("purchase", {
      value: cart?.total,
      currency: cart.orderItems[0]?.currencyCode,
      items: cart.orderItems.map(formatOrderItem),
      transaction_id: cart.orderID,
    });
  }

  applyPromotion(cart: Cart, promotionCode: string) {
    this.sendEvent("select_promotion", {
      promotion_id: promotionCode,
      promotion_name:
        cart.promotionCodes.find((promotion) => promotion.promotionCode === promotionCode)?.promotion.promotionName ||
        "",
      items: cart.orderItems.map(formatOrderItem),
    });
  }
}

export { LogService };
