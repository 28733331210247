import { Fragment } from "react";
import { useElementContext } from "../../contexts";

const DynamicContent = ({ contentElements = [] }) => {
  const {
    CommonModule: { DynamicComponent, PageContentRow },
  } = useElementContext();
  return (
    <Fragment>
      {contentElements.map((pageEl, idx) => {
        if (pageEl?.systemCode === "cetRow") {
          return (
            <PageContentRow key={idx}>
              {pageEl?.innerElements?.map((rowEl, rowIdx) => {
                return <DynamicComponent el={rowEl} key={rowIdx} />;
              })}
            </PageContentRow>
          );
        }
        return <DynamicComponent el={pageEl} key={idx} />; //Catch all for non row based components
      })}
    </Fragment>
  );
};
export { DynamicContent };
