import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useContentContext } from "../../contexts/ContentContext";
import { useEffect, useState } from "react";

const Layout = ({ classNameList = "", children }) => {
  const siteName = useSelector((state) => state.configuration.site.siteName);
  const pageData = useContentContext();
  const [tags, setTags] = useState([]);
  useEffect(() => {
    setTags(document.querySelectorAll("h2"));
  }, []);

  const scrollToTag = (offset) => {
    if (offset) {
      window.scrollTo(0, offset);
    }
  };

  return (
    <>
      <Helmet title={siteName} />
      <div className="d-flex">
        <div
          className={`${classNameList} ${
            pageData.pageType === "Page with page contents sidebar" ? "col-9" : "col-12"
          } d-flex flex-column`}
          style={{ minHeight: "100vh" }}
        >
          {children}
        </div>
        {pageData.pageType === "Page with page contents sidebar" && (
          <div className="col-3 position-fixed end-0" style={{ height: "100%" }}>
            <h3 className="ms-3 mt-3">Go to</h3>
            {Array.from(tags).map((tag, i) => {
              return (
                <div key={i}>
                  {tag.className === "" && (
                    <div role="button" className="pe-auto" onClick={() => scrollToTag(tag?.offsetTop)}>
                      <p className="ms-3 my-2">
                        <u>{tag.innerText}</u>
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export { Layout };
