import { useDispatch, useSelector } from "react-redux";
import { addSkuToWishList, removeWishlistItem, createListAndAddItem } from "../../../../actions";
import { HeartButtonModel, HeartButtonProps } from "./HeartButton.componentModel";
import { useCallback, useMemo } from "react";
import { isAuthenticated } from "../../../../utils";
import { getDefaultWishlist, getItemsForDefaultWishList } from "../../../../selectors";

export const useComponentData = (props: HeartButtonProps) => {
  const dispatch = useDispatch();
  const primaryColor = useSelector((state: any) => state.configuration.theme.primaryColor);
  const defaultWishlist = useSelector(getDefaultWishlist) as any;
  const isListLoaded = useSelector((state: any) => state.userReducer.wishList.isListLoaded) as boolean;
  const items = useSelector(getItemsForDefaultWishList) as string[];

  const componentData = useMemo(
    () => new HeartButtonModel({ props, primaryColor, items, isAuthenticated: isAuthenticated() }),
    [items, primaryColor, props],
  );

  const { skuID } = props;

  const removeFromWishList = useCallback(() => {
    dispatch(removeWishlistItem(skuID, defaultWishlist?.value) as any);
  }, [defaultWishlist?.value, dispatch, skuID]);

  const addToWishList = useCallback(() => {
    if (isListLoaded && !defaultWishlist?.value) {
      dispatch(createListAndAddItem(skuID) as any);
    } else {
      dispatch(addSkuToWishList(skuID, defaultWishlist?.value) as any);
    }
  }, [defaultWishlist?.value, dispatch, isListLoaded, skuID]);

  return { componentData, componentService: { removeFromWishList, addToWishList } };
};
