const FeatureBlock = (props) => {
  const { contentHeading, subtext, theme, style, customStyleClasses } = props;

  return (
    <div className={`mb-3 mt-5 ${customStyleClasses}`}>
      {theme === "light" && (
        <div className="FeatureBlockpage p-0 d-flex">
          <div className={`basic container bg-white shadow-sm rounded-3 p-4 mb-5 ${style === "half width" && "col-6"}`}>
            <h1 className="h3 text-dark mb-0 font-accent">{contentHeading}</h1>
            <p dangerouslySetInnerHTML={{ __html: subtext }} />
          </div>
        </div>
      )}

      {theme === "dark" && (
        <div className="FeatureBlockpage bg-black d-flex">
          <div className={`basic container shadow-sm rounded-3 p-4 mb-5 ${style === "half width" && "col-6"}`}>
            <h1 className="h3 text-dark mb-0 font-accent">{contentHeading}</h1>
            <p dangerouslySetInnerHTML={{ __html: subtext }} />
          </div>
        </div>
      )}
    </div>
  );
};

export { FeatureBlock };
