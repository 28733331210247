import { useElementContext } from "../../../contexts";

const FormYesNoField = (props) => {
  const {
    CommonModule: { FormRadioField },
  } = useElementContext();
  return (
    <FormRadioField
      {...props}
      options={[
        { value: "Yes", name: "Yes" },
        { value: "No", name: "No" },
      ]}
    />
  );
};

export default FormYesNoField;
