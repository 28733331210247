import ContentLoader from "react-content-loader";
import { useElementContext } from "../../contexts";

const ListingGridLoader = (props) => (
  <ContentLoader viewBox="0 0 1200 500" className="listingGridLoader" {...props}>
    <rect x="100" y="20" rx="8" ry="8" width="300" height="300" />
    <rect x="100" y="350" rx="0" ry="0" width="300" height="32" />
    <rect x="100" y="400" rx="0" ry="0" width="180" height="36" />

    <rect x="500" y="20" rx="8" ry="8" width="300" height="300" />
    <rect x="500" y="350" rx="0" ry="0" width="300" height="36" />
    <rect x="500" y="400" rx="0" ry="0" width="180" height="30" />

    <rect x="900" y="20" rx="8" ry="8" width="300" height="300" />
    <rect x="900" y="350" rx="0" ry="0" width="300" height="32" />
    <rect x="900" y="400" rx="0" ry="0" width="180" height="36" />
  </ContentLoader>
);

const BulkOrderListingGridView = ({ Card, isFetching, pageRecords, bulkOrderList, onButtonClick }) => {
  const { ProductListingModule } = useElementContext();
  return (
    <div className="col">
      {isFetching && (
        <>
          <ListingGridLoader /> <ListingGridLoader /> <ListingGridLoader />
        </>
      )}
      <div className="product-grid">
        {!isFetching &&
          pageRecords?.map((product, index) => {
            let skuID = product.skuID;
            if (!skuID) {
              const defaultSku =
                product.skus?.find((sku) => sku.skuID === product.defaultSku_skuID) || product.skus?.[0];
              skuID = defaultSku?.skuID;
            }
            return (
              <div key={`${product.productName}${index}`} className="mb-4">
                <Card
                  productData={product}
                  onButtonClick={onButtonClick}
                  quantity={bulkOrderList[skuID]?.quantity || 0}
                />
              </div>
            );
          })}
      </div>
      {!isFetching && pageRecords.length === 0 && <ProductListingModule.CoreControl.NoProductFound />}
    </div>
  );
};
export { BulkOrderListingGridView };
