import { PropsWithChildren, ReactNode } from "react";
import { useSelector } from "react-redux";
import { HeaderModel } from "./Header.componentModel";

export const getContentByElementVal = (children: ReactNode, value: string) => {
  return (children as ReactNode[])?.filter((child: any) => child?.props?.el.contentElementTypeCode === value);
};

export const useComponentData = (props: PropsWithChildren) => {
  const theme = useSelector((state: any) => state.configuration.site.theme);
  const siteSetting = useSelector((state: any) => state.configuration.site.settings);

  const ribbons = getContentByElementVal(props.children, "cetRibbon");
  const menus = getContentByElementVal(props.children, "cetMenu");
  const megaMenus = getContentByElementVal(props.children, "cetMegaMenu");
  const filteredMenus = menus?.filter((menu: any) => menu.props.el.contentElementName !== "Filter Header Menu");

  return { componentData: new HeaderModel({ theme, siteSetting, ribbons, megaMenus, menus, filteredMenus }) };
};
