import { SkuOptionModel } from "./SkuOptions.componentModel";
import { OptionGroup, Option } from "../../../../interface/Product";
import { useElementContext } from "../../../../contexts";

export function SkuOptionsComponentView({
  componentData,
  updateFilters,
  resetFilters,
}: {
  componentData: SkuOptionModel;
  updateFilters: (optionGroup: OptionGroup, option: Option) => void;
  resetFilters: () => void;
}) {
  const { ProductModule } = useElementContext();
  console.log(componentData);
  if (componentData?.optionGroups[0]?.options.length >= 2) {
    if (componentData.optionStyle === "tile") {
      return (
        <ProductModule.CoreControl.OptionButtons
          productOptions={componentData.filteredOptions}
          updateFilters={updateFilters}
        />
      );
    }
    return (
      <ProductModule.CoreControl.OptionDropdown
        optionData={componentData.filteredOptions}
        updateFilters={updateFilters}
        resetFilters={resetFilters}
      />
    );
  } else {
    return <></>;
  }
}
