import React from "react";
import { useElementContext } from "../../../contexts";

const TabbedProductListing = (props) => {
  const {
    CommonModule: { SimpleTabs },
  } = useElementContext();
  return (
    <>
      {props.innerElements?.length > 0 && (
        <div className="my-5">
          <SimpleTabs displayChildren={true} {...props} />
        </div>
      )}
    </>
  );
};

export { TabbedProductListing };
