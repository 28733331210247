import { getSdkURL } from "../../../../services";
import { ProductAttributesModel, ProductAttributesProps } from "./ProductAttributes.componentModel";
import { useMemo } from "react";

const downloadFile = ({ fileID }: { fileID: string }) => {
  const adminUrl = getSdkURL();
  window.open(`${adminUrl}api/scope/downloadFile?fileID=${fileID}`);
};

export const useCopmonentData = (props: ProductAttributesProps) => {
  const componentData = useMemo(() => new ProductAttributesModel({ props }), [props]);

  return { componentData, componentService: { downloadFile } };
};
