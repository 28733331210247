import { MiniCartProps } from "./MiniCart.componentModel";
import { useComponentData } from "./MiniCart.componentService";
import { MiniCartView } from "./MiniCart.componentView";

const MiniCart = (props: MiniCartProps) => {
  const { componentData, componentService } = useComponentData(props);
  return <MiniCartView componentData={componentData} {...componentService} />;
};

export { MiniCart };
