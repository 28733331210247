class rowElement {
  columnCounter = 0;
  systemCode = "cetRow";
  contentElementTypeCode = "cetRow";
  innerElements = [];
}

export class dynamicAreaLayoutEngine {
  processedContent = [];
  currentRow = new rowElement();
  rowWrapperComponentExclusions = ["cetHeader", "cetFooter", "cetBody"];

  processPageComponents(pageComponents) {
    pageComponents.map((componentData) => {
      if (!componentData) return null;
      //If the component does not define a layout or is on the row wrapper exclusion list then just add it to the page layout as is
      if (
        this.rowWrapperComponentExclusions.includes(componentData?.contentElementTypeCode) ||
        componentData?.componentLayout === "Full Page"
      ) {
        this.#closeRowElement(); //close any open row first, this keeps the componets in order on the page
        this.processedContent.push(componentData);
        return null;
      } else {
        const componetLayoutRows = this.#processComponentLayoutToBootstrapCols(componentData); //get the Bootstrap rows for this component
        componentData.bootStrapLayoutClass = componetLayoutRows.bootstrapLayoutClass; //Add teh layout classes for UI use
        //If there is an open row element and adding this component will make it more than 12 cols
        this.#addComponetToRow(componentData);

        // if( (this.currentRow.columnCounter + componetLayoutRows.rowCount ) > 12){
        //   this.#closeRowElement() //close any open row first
        //   this.#addComponetToRow(componentData) //Create new row and add this component to it
        // } else { //If we are in a row then add the componet to a row
        //   this.#addComponetToRow(componentData)
        // }

        return null;
      }
    });

    this.#cleanup(); //claose any open fows and clean up the data model
    return this.processedContent;
  }

  #processComponentLayoutToBootstrapCols(component) {
    if (!component || !component.componentLayout) return { rowCount: 13, bootstrapLayoutClass: "col-12" };

    switch (component.componentLayout) {
      case "Half Width":
        return { rowCount: 6, bootstrapLayoutClass: "col-lg-6 col-12" };

      case "Quarter Width":
        return { rowCount: 3, bootstrapLayoutClass: "col-lg-3 col-12" };

      case "Third Width":
        return { rowCount: 4, bootstrapLayoutClass: "col-lg-4 col-12" };

      default:
        return { rowCount: 12, bootstrapLayoutClass: "col-lg-12 col-12" };
    }
  }

  #addComponetToRow(componentData) {
    const componetLayoutRows = this.#processComponentLayoutToBootstrapCols(componentData);
    if (!this.currentRow.innerElements) {
      //If now row exists the create one
      this.#createRowElement();
    }

    this.currentRow.innerElements.push(componentData); //add the element to the row
    this.currentRow.columnCounter += componetLayoutRows.rowCount; //Update teh row counter
  }

  #createRowElement() {
    this.currentRow = new rowElement();
  }

  #closeRowElement() {
    if (this.currentRow && this.currentRow.innerElements.length) this.processedContent.push(this.currentRow); //the check for element prevents empty rows being added
    this.currentRow = new rowElement();
  }

  #cleanup() {
    //push in the last row if there is one open
    this.#closeRowElement();
  }
}
