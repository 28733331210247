import { useElementContext } from "../../contexts";

export interface CallToActionBtnProps {
  urlTarget: string;
  linkUrl: string;
  linkLabel: string;
  ctaButtonType: string;
}

const CallToActionBtn = ({ urlTarget, linkLabel, linkUrl, ctaButtonType = "primary" }: CallToActionBtnProps) => {
  const {
    CommonModule: { ExternalLink },
  } = useElementContext();

  return (
    <>
      {linkUrl ? (
        <ExternalLink
          linkUrl={linkUrl}
          className={`btn btn-${ctaButtonType}`}
          target={urlTarget}
          {...(urlTarget === "_blank" ? { rel: "noreferrer noopener" } : {})}
        >
          {linkLabel}
        </ExternalLink>
      ) : (
        <></>
      )}
    </>
  );
};

export default CallToActionBtn;
