import { useTranslation } from "react-i18next";

import CartModel from "./Cart.componentModel";
import { useElementContext } from "../../contexts";

const View = ({
  componentData: { isEmptyCart, components, isRemovingItem, showClearCart },
  onClearCartData,
  onRemoveItem,
  onUpdateQty,
  onApplyCode,
}: {
  componentData: CartModel;
  onClearCartData: () => void;
  onRemoveItem: (id: string) => void;
  onUpdateQty: (id: string, qty: number) => void;
  onApplyCode: (promoCode: string, setPromoCode: (code: string) => void) => void;
}) => {
  const {
    CartModule,
    PageModule: { DynamicPage },
  } = useElementContext();
  const { t } = useTranslation();

  return (
    <DynamicPage ignoreLayout={true}>
      <div className="container my-5">
        {isEmptyCart && (
          <div className="text-center p-3">
            <h3>{t("frontend.cart.empty_cart")}</h3>
          </div>
        )}

        {isRemovingItem && <div className="alert alert-success">{t("frontend.cart.removeCartItem")}</div>}

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            {components.cartOrderItemList && (
              <CartModule.CoreControl.CartItemList
                {...components.cartOrderItemList}
                onUpdateQty={onUpdateQty}
                onRemoveItem={onRemoveItem}
              />
            )}
            {components.quoteOrderItemsList && (
              <CartModule.CoreControl.CartItemList
                {...components.quoteOrderItemsList}
                onUpdateQty={onUpdateQty}
                onRemoveItem={onRemoveItem}
              />
            )}
            {showClearCart && (
              <div className="white-background">
                <button className="btn btn-link link-btn" onClick={onClearCartData}>
                  {t("frontend.cart.clearCart")}
                </button>
              </div>
            )}
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            {components.cartCheckoutOptions && (
              <CartModule.CoreControl.CartCheckoutOptions {...components.cartCheckoutOptions} />
            )}
            {components.cartCheckOut && (
              <CartModule.CoreControl.CartCheckOut {...components.cartCheckOut} onApplyCode={onApplyCode} />
            )}
          </div>
        </div>
      </div>
    </DynamicPage>
  );
};

export default View;
