import { useTranslation } from "react-i18next";
import QuoteModel from "./Quote.componentModel";
import { useElementContext } from "../../contexts";

const QuoteView = ({
  componentData: { isEmptyCart, isRemovingItem, components },
  onRemoveItem,
  onUpdateQty,
}: {
  componentData: QuoteModel;
  onRemoveItem: (id: string) => void;
  onUpdateQty: (orderItemID: string, itemCount: number) => void;
}) => {
  const { t } = useTranslation();
  const {
    CartModule,
    PageModule: { DynamicPage },
    CommonModule: { QuoteCheckOut },
  } = useElementContext();

  return (
    <DynamicPage ignoreLayout={true}>
      <div className="container my-5">
        {isEmptyCart && (
          <div className="text-center p-3">
            <h3>{t("frontend.cart.empty_cart")}</h3>
          </div>
        )}

        {isRemovingItem && <div className="alert alert-success">{t("frontend.cart.removeCartItem")}</div>}
        <div className="row">
          <div className="col-lg-7 col-md-12">
            {components.quoteOrderItemList && (
              <CartModule.CoreControl.CartItemList
                {...components.quoteOrderItemList}
                onUpdateQty={onUpdateQty}
                onRemoveItem={onRemoveItem}
              />
            )}
          </div>
          {components.quoteCheckOut && (
            <div className="col-lg-4 offset-lg-1 col-md-12 pt-4">
              <QuoteCheckOut {...components.quoteCheckOut} />
            </div>
          )}
        </div>
      </div>
    </DynamicPage>
  );
};

export { QuoteView };
