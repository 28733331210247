/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Bearer from "./ApplicationType/Bearers";
import Joists from "./ApplicationType/Joists";
import RoofBeams from "./ApplicationType/RoofBeams";
import Rafters from "./ApplicationType/Rafters";
import RidgeBeams from "./ApplicationType/RidgeBeams";
import "./configurator.scss";
import Posts from "./ApplicationType/Posts";
import "./assets/jquery.mycart.js";
import "./assets/printThis.js";
import { receiveUser } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getErrorMessage, isAuthenticated, toBoolean } from "../../utils";
import { SlatwalApiService, getSdkURL } from "../../services";

const TermsAndConditionsStepUp = () => {
  const dispatch = useDispatch();
  const [accept, setAccept] = useState(true);
  const [initials, setInitials] = useState("");
  const accountID = useSelector((state) => state.userReducer.accountID);
  const setValue = () => {
    SlatwalApiService.account
      .update({ accountID, spanAcessFlag: accept, tocInitials: initials, returnJSONObjects: "account" })
      .then((response) => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length)
          toast.error(getErrorMessage(response.success().errors));
        if (response.isSuccess()) {
          dispatch(receiveUser(response.success().account));
        } else {
          toast.error("Error");
        }
      });
  };
  // https://admin.itiaustralia.com.au/custom/assets/Files/design-pine/span-tac.pdf
  return (
    <div className="container toc p-5 span-configurator-tandc">
      <h3>Span Configurator Registration</h3>
      <p>
        By registering and proceeding to use the Design Pine Span App, you are agreeing to abide by the terms &
        conditions of Design Pine and ITI Australia
      </p>
      <div className="row">
        <div className="col-md-6">
          <div className="col form-group">
            <div className="input-group" style={{ height: "100%" }}>
              <input
                onChange={() => {
                  setAccept(!accept);
                }}
                id="spanTOC"
                checked={accept}
                type="checkbox"
                className="mx-2"
              />
              <label
                htmlFor="spanTOC"
                style={{ marginBottom: "unset" }}
                className={"control-label px-2  d-flex align-items-center form-label required"}
              >
                I Agree to The{" "}
                <a target="_blank" rel="noreferrer" href={`${getSdkURL()}custom/assets/Files/design-pine/span-tac.pdf`}>
                  Terms and Conditions
                </a>
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-group">
            <span
              style={{ marginBottom: "unset", border: "unset", backgroundColor: "unset" }}
              className="input-group-text form-label control-label required"
            >
              Initials
            </span>
            <input
              value={initials}
              onChange={(e) => {
                e.preventDefault();
                setInitials(e.target.value);
              }}
              className="form-control"
              required={true}
              id="spanini"
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="text-right pt-4">
        <button disabled={initials.length < 1 || !accept} className="btn btn-primary" onClick={setValue} type="submit">
          Accept
        </button>
      </div>
    </div>
  );
};

function SpanConfigurator() {
  const spanAcessFlag = useSelector((state) => state.userReducer.spanAcessFlag) || false;
  const loc = useLocation();
  const [applicationType, setApplicationType] = useState("");
  const applicationsTypeItems = ["Rafters", "Roof Beams", "Ridge Beams", "Bearer", "Joists", "Posts"];
  const navigate = useNavigate();
  if (!isAuthenticated()) {
    navigate(`/my-account/login?redirect=${loc.pathname}`);
    return null;
  }

  console.log("Authenticated : ", isAuthenticated(), "Span App access : ", spanAcessFlag);

  if (isAuthenticated() && !toBoolean(spanAcessFlag)) return <TermsAndConditionsStepUp />;

  return (
    <section className="topSection cetSpanConfigurator">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-12 col-sm-12">
            <div id="desktop">
              <div className="span-sidebar">
                <h2>Application Type</h2>
                <form id="idForm">
                  {applicationsTypeItems.map((applicationsTypeItem) => (
                    <div className="form-check" key={applicationsTypeItem}>
                      <input
                        id={`radio` + applicationsTypeItem}
                        type="radio"
                        onChange={(e) => setApplicationType(e.target.value)}
                        name="radio"
                        value={applicationsTypeItem}
                        checked={applicationsTypeItem === applicationType ? "checked" : ""}
                      />
                      <label htmlFor={`radio` + applicationsTypeItem}>{applicationsTypeItem}</label>
                    </div>
                  ))}
                </form>
                <hr />
                <a href="https://e.issuu.com/embed.html?d=dp_spanweb_rego&hideIssuuLogo=true&u=revolutions9">
                  <div className="introGuide">SpanTable Introduction Guide</div>
                </a>
                <hr />

                <h4>Span Table Certification</h4>
                <ul className="dptheme ps-0 ms-0">
                  <a target="_blank" rel="noreferrer" href="/pdf/21180ITISpanTableCertification2022-03-30.pdf">
                    Structural Certification{" "}
                  </a>{" "}
                  <br />
                  <a target="_blank" rel="noreferrer" href="/pdf/21034-StructuralReview-ITIDesignPineSpanTables.pdf">
                    Structural Review Certificate{" "}
                  </a>
                  <br />
                </ul>
              </div>
            </div>
          </div>
          {!applicationType && (
            <div className="col-lg-9 col-md-12 col-sm-122">
              {/* <!-- intro span table --> */}
              <div id="slideDIV">
                <iframe
                  allowFullScreen=""
                  allow="fullscreen"
                  style={{ border: "none", width: "100%", height: "600px" }}
                  src="//e.issuu.com/embed.html?d=dp_spanweb_rego&amp;hideIssuuLogo=true&amp;u=revolutions9"
                  width="100%"
                  height="600"
                ></iframe>
              </div>
            </div>
          )}
          {applicationType === "Bearer" && <Bearer />}
          {applicationType === "Roof Beams" && <RoofBeams />}
          {applicationType === "Rafters" && <Rafters />}
          {applicationType === "Ridge Beams" && <RidgeBeams />}
          {applicationType === "Posts" && <Posts />}
          {applicationType === "Joists" && <Joists />}
        </div>
      </div>
    </section>
  );
}

export { SpanConfigurator };
