import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { axios, getSdkURL } from "../../../services";
import { addPaymentToOrder } from "../../../actions";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useCheckoutUtilities } from "../../../hooks";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../utils";
import { useElementContext } from "../../../contexts";

const TillPayments = ({ method, isQuote = false, orderID }) => {
  const {
    CommonModule: { Button, SwSelect },
  } = useElementContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [paymentMethodErrors, setPaymentMethodErrors] = useState({
    card_holder: { message: "" },
    number: { message: "" },
    cvv: { message: "" },
  });
  const { months, years } = useCheckoutUtilities();
  const [paymentJS, setPaymentJS] = useState();
  const [loading, setLoading] = useState(true);
  const paymentMethodID = method;

  const [paymentMethod, setPaymentMethod] = useState({
    accountPaymentMethodName: "",
    paymentMethodType: "tillpayments",
    nameOnCreditCard: "",
    expirationMonth: dayjs().add(1, "month").format("MM"),
    expirationYear: dayjs().add(1, "month").format("YYYY"),
    securityCode: "",
    accountAddressID: "",
    saveShippingAsBilling: false,
    savePaymentMethodToAccount: false,
    returnJSONObjects: "cart",
  });

  const addPayment = (params, cb = null) => {
    params = {
      ...params,
      transactionInitiator: "CHECKOUT_PAYMENT",
    };

    if (isQuote) params["orderID"] = orderID;

    return dispatch(
      addPaymentToOrder({
        params,
        isQuote,
      }),
    )
      .then((response) => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) {
          toast.error(getErrorMessage(response.success().errors));
        }
        if (!response.isSuccess()) {
          toast.error("An Error Occurred");
        }
        return response;
      })
      .then((response) => {
        if (typeof cb === "function") {
          cb(response);
        }
        return response;
      });
  };

  const mutateErrors = ({ name, message }) => {
    setPaymentMethodErrors((errors) => {
      return { ...errors, [name]: { message } };
    });
  };

  useEffect(() => {
    let source = axios.CancelToken.source();
    let script;

    const init = async () => {
      const config = await axios({
        method: "GET",
        withCredentials: true,
        url: `${getSdkURL()}api/scope/getTillPaymentsConfiguration`,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (response.status === 200) {
          return response.data.config;
        }
        return null;
      });

      const tillApiUrl = config.testMode ? "https://test-gateway.tillpayments.com" : "https://gateway.tillpayments.com";

      script = document.createElement("script");
      script.src = `${tillApiUrl}/js/integrated/payment.1.3.min.js`;
      script.async = true;
      script.setAttribute("data-main", "payment-js");

      var formControlCSS = {
        display: "block",
        width: "100%",
        padding: ".375rem .75rem",
        "font-size": "1rem",
        "font-weight": "400",
        "line-height": "1.5",
        color: "#212529",
        "background-color": "#fff",
        "background-clip": "padding-box",
        border: "1px solid #ced4da",
        "-webkit-appearance": "none",
        "-moz-appearance": "none",
        appearance: "none",
        "border-radius": ".375rem",
        transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
      };

      var callback = function () {
        const payment = new window.PaymentJs();
        payment.init(config.publicIntegrationKey, "number_div", "cvv_div", function (payment) {
          payment.setNumberStyle(formControlCSS);
          payment.setCvvStyle(formControlCSS);

          payment.numberOn("input", function () {
            mutateErrors({ name: "number" });
          });

          payment.cvvOn("input", function () {
            mutateErrors({ name: "cvv" });
          });

          setLoading(false);
        });
        setPaymentJS(payment);
      };

      script.onload = callback;
      document.body.appendChild(script);
    };

    init();
    return () => {
      source.cancel();
      document.body.removeChild(script);
    };
  }, [dispatch]);

  const formSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    var data = {
      card_holder: paymentMethod.nameOnCreditCard,
      month: paymentMethod.expirationMonth,
      year: paymentMethod.expirationYear,
      // email: "" // could get from user object
    };

    // Send CC details to Till Payments and retrieve a token
    paymentJS.tokenize(
      data,
      function (token, cardData) {
        // Success callback function
        const params = {
          newOrderPayment: {
            saveShippingAsBilling: 1,
            nameOnCreditCard: paymentMethod.nameOnCreditCard,
            providerToken: token,
            expirationMonth: paymentMethod.expirationMonth,
            expirationYear: paymentMethod.expirationYear,
            creditCardType: cardData.card_type,
            creditCardLastFour: cardData.last_four_digits,
            paymentMethod: {
              paymentMethodID,
            },
          },
          transactionInitiator: "CHECKOUT_PAYMENT",
        };

        addPayment(params, () => {
          setLoading(false);
        });
      },
      function (errors) {
        // Error callback function
        let newErrors = {};
        errors.forEach((err) => {
          newErrors[err.attribute] = { path: err.attribute, message: err.message };
        });

        setPaymentMethodErrors(newErrors);
        setLoading(false);
      },
    );
  };

  return (
    <>
      <hr />
      <div className="row mb-3">
        <div className="col-sm-6">
          <div className="form-group">
            <h5>{t("frontend.checkout.cardInfo")}</h5>
          </div>

          <div className="mb-3">
            <label htmlFor="number_div">{t("frontend.account.payment_method.name")}</label>
            <input
              className="form-control"
              type="text"
              id="nameOnCreditCard"
              value={paymentMethod.nameOnCreditCard}
              onChange={(e) => {
                const value = document.getElementById("nameOnCreditCard").value;
                if (value) {
                  mutateErrors({ name: "card_holder" });
                }
                setPaymentMethod({
                  ...paymentMethod,
                  nameOnCreditCard: value,
                });
              }}
            />
            {paymentMethodErrors?.card_holder?.message && (
              <span className="form-error-msg">{paymentMethodErrors.card_holder.message}</span>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="number_div">{t("frontend.account.payment_method.ccn")}</label>
            <div id="number_div" style={{ height: "38px", width: "100%" }}></div>
            {paymentMethodErrors?.number?.message && (
              <span className="form-error-msg">{paymentMethodErrors.number.message}</span>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="cvv_div">{t("frontend.account.payment_method.cvv")}</label>
            <div id="cvv_div" style={{ height: "38px", width: "100%" }}></div>
            {paymentMethodErrors?.cvv?.message && (
              <span className="form-error-msg">{paymentMethodErrors.cvv.message}</span>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="expirationMonth">{t("frontend.account.payment_method.expiration_month")}</label>
            <SwSelect
              id="expirationMonth"
              value={paymentMethod.expirationMonth}
              onChange={(e) => {
                setPaymentMethod({
                  ...paymentMethod,
                  expirationMonth: e.target.value,
                });
              }}
              options={months}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="expirationYear">{t("frontend.account.payment_method.expiration_year")}</label>
            <SwSelect
              id="expirationYear"
              value={paymentMethod.expirationYear}
              onChange={(e) => {
                setPaymentMethod({
                  ...paymentMethod,
                  expirationYear: e.target.value,
                });
              }}
              options={years}
            />
          </div>
          <div className="mb-3">
            <Button label="Submit" onClick={formSubmit} disabled={loading} isLoading={loading} />
          </div>
        </div>
      </div>
    </>
  );
};
export { TillPayments };
