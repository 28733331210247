import { PotentialFilter, PotentialFilterOption } from "../../../../interface/SearchProduct";
import { SearchConfig } from "../../productListing.moduleModels";

export interface FacetFilterProps {
  config: SearchConfig;
  filter: PotentialFilter;
  updateAttribute: (data: { name: string; filterName: string }) => void;
  facetKey?: string;
}

export class FacetFilterModel {
  config: SearchConfig;
  searchTerm: string = "";
  facetKey: string = "";
  updateCount: number;
  filter: PotentialFilter;
  searchResults: PotentialFilterOption[];
  appliedFilters: any;

  constructor({
    props,
    searchTerm,
    updateCount,
    searchResults,
    appliedFilters,
  }: {
    props: FacetFilterProps;
    searchTerm: string;
    updateCount: number;
    searchResults: PotentialFilterOption[];
    appliedFilters: any;
  }) {
    const { config, filter, facetKey = "" } = props;
    this.config = config;
    this.searchTerm = searchTerm;
    this.filter = filter;
    this.facetKey = facetKey;
    this.searchResults = searchResults.sort((a, b) => {
      const aSelected = appliedFilters.includes(a.slug);
      const bSelected = appliedFilters.includes(b.slug);
      return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
    });
    this.updateCount = updateCount;
    this.appliedFilters = appliedFilters;
  }
}
