import { SearchListingProps } from "./SearchListing.componentModel";
import { SearchListingView } from "./SearchListing.componentView";
import { useComponentData } from "./SearchListing.componentService";

const SearchListing = (props: SearchListingProps) => {
  const { componentData, componentService } = useComponentData(props);

  return <SearchListingView componentData={componentData} setViewMode={componentService.setViewMode} />;
};

export { SearchListing, type SearchListingProps };
