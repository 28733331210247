import { useAccountFavorites } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { useContentContext } from "../../../contexts/ContentContext";
import { useElementContext } from "../../../contexts";

const AccountFavorites = () => {
  const { products, isFetching, totalPages, setPage } = useAccountFavorites();
  const pageData = useContentContext();
  const {
    ProductListingModule,
    CommonModule: { AccountLayout, AccountContent, ListingPagination },
  } = useElementContext();
  const { t } = useTranslation();
  const templateData = pageData?.templateData?.searchListing;

  const cardDisplayConfigurations = {
    skuCardConfiguration: JSON.parse(templateData?.skuCardConfiguration || "{}"),
    productCardConfiguration: JSON.parse(templateData?.productCardConfiguration || "{}"),
  };

  return (
    <AccountLayout>
      <AccountContent />
      {!isFetching && (
        <>
          {pageData && products.length > 0 ? (
            <>
              {templateData.viewMode === "LISTING" && (
                <ProductListingModule.CoreControl.ListingListView
                  cardDisplayConfigurations={cardDisplayConfigurations}
                  config={templateData}
                  isFetching={isFetching}
                  pageRecords={products}
                />
              )}
              {templateData.viewMode === "GRID" && (
                <ProductListingModule.CoreControl.ListingGridView
                  cardDisplayConfigurations={cardDisplayConfigurations}
                  config={templateData}
                  isFetching={isFetching}
                  pageRecords={products}
                />
              )}
              <ListingPagination recordsCount={products.length} totalPages={totalPages} setPage={setPage} />
            </>
          ) : (
            <div className="alert alert-info" role="alert">
              {t("frontend.account.favorites.none")}
            </div>
          )}
        </>
      )}
    </AccountLayout>
  );
};

export { AccountFavorites };
