import { useElementContext } from "../../../contexts";
import { ReactNode } from "react";

export const HeaderTopRight = ({
  ribbons,
  theme,
  siteSetting,
}: {
  ribbons: ReactNode[];
  theme: any;
  siteSetting: any;
}) => {
  const {
    CommonModule: { MiniCart },
  } = useElementContext();
  return (
    <div className="col-md-6 col-sm-12 d-flex justify-content-end">
      <div className="d-flex align-items-center">{ribbons}</div>
      <div className="text-black d-flex align-items-center" style={{ color: theme.headerBarTextColour?.trim() }}>
        {theme.headerStyleOption !== "one" &&
          theme.headerStyleOption !== "four" &&
          siteSetting.sitePurpose !== "Non-Transactional" && (
            <div className="ms-3 d-none d-lg-block">
              <MiniCart color={theme.headerBarTextColour?.trim()} />
            </div>
          )}
      </div>
    </div>
  );
};
