const FormCheckboxGroupField = ({
  attributeName,
  attributeCode,
  requiredFlag,
  options,
  setValue,
  value,
  invalid,
  validationMessage,
}) => {
  return (
    <fieldset>
      <div className={`form-label ${requiredFlag ? "required" : ""}`}>{options.length > 1 ? attributeName : ""}</div>
      {options.map((option, idx) => {
        return (
          <div className="form-check" key={idx}>
            <input
              onChange={(e) => {
                setValue((response) => {
                  return {
                    ...response,
                    [attributeCode]: e.target.checked,
                  };
                });
              }}
              className={"form-check-input" + (invalid ? " is-invalid" : "")}
              type="checkbox"
              name={attributeCode + option.name}
              checked={typeof value === "undefined" ? option.value : value}
              id={attributeCode + option.name}
            />
            <label className="form-check-label" htmlFor={attributeCode + option.name}>
              {option.name}
            </label>
          </div>
        );
      })}
      {invalid && <div className="invalid-feedback">{validationMessage ?? ""}</div>}
    </fieldset>
  );
};

export default FormCheckboxGroupField;
