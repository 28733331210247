//COMPONENTS
import { ProductCardActions } from "./Components/ProductCardActions/ProductCardActions.component";

//CONTROLS
import { DropdownButtons } from "./Controls/DropdownButtons";
import { ListingButtons } from "./Controls/ListingButtons";
import { ProductCardButton } from "./Controls/ProductCardButton";
import { ProductCard, SkuCard } from "./Controls/ProductCardCardView";
import { ProductCardImage } from "./Controls/ProductCardImage";
import { ProductCardWithDetail, SkuCardWithDetail } from "./Controls/ProductCardWithDetail";

//CUSTOM

export namespace ProductCardModule {
  export const CoreComponents = {
    ProductCardActions,
  };

  export const CoreControl = {
    DropdownButtons,
    ListingButtons,
    ProductCardButton,
    ProductCardImage,
    SkuCard,
    ProductCard,
    ProductCardWithDetail,
    SkuCardWithDetail,
  };

  export const Custom = {};
}
