import React from "react";

import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";

import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

export const initApp = (devData: any) => {
  const release = process.env.REACT_APP_NAME + "@" + process.env.REACT_APP_VERSION;
  const dsn = process.env.REACT_APP_SENTRY_DSN;
  const tracesSampleRate = parseInt(process.env.REACT_APP_SENTRY_SAMPLE_RATE || "0", 10) || 0.5;

  console.log(
    "#### Experience Layer:",
    process.env.REACT_APP_DATASTORE_URL,
    "| Hard Set Store Domain (.env): ",
    process.env.REACT_APP_SITE_DOMAIN,
    "####",
  );

  if (devData.analytics.tagManager.gtmId) TagManager.initialize({ gtmId: devData.analytics.tagManager.gtmId });
  if (dsn)
    Sentry.init({
      release,
      beforeSend: (event) => {
        if (!(window as any)["Statistics-Allowed"]) return null;
        return event;
      },
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
      ],
      tracesSampleRate,
    });
};
