import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../../actions";
import { MouseEventHandler, useState } from "react";

export interface LogoutSpecialMenuProps {
  color?: string;
  cssClass?: string;
  linkLabel?: string;
  onMenuItemClick?: MouseEventHandler;
}

const LogoutSpecialMenu = ({
  color = "",
  cssClass = "",
  linkLabel = "Logout",
  onMenuItemClick,
}: LogoutSpecialMenuProps) => {
  const user = useSelector((state: any) => state.userReducer);
  const { accountID } = user;
  const dispatch = useDispatch();
  const [disableButton, setdisableButton] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!accountID) {
    return null;
  }

  return (
    <Link
      className={`nav-item link-button d-block user-logout p-2 ${cssClass}`}
      style={{ color: color }}
      to="/my-account/logout"
      onClick={async (e) => {
        e.preventDefault();
        if (disableButton) return;
        setdisableButton(true);
        await dispatch<any>(logout(t("frontend.account.logout_success"), t("frontend.account.logout_failure")));
        navigate("/");
        onMenuItemClick?.(e);
      }}
    >
      {linkLabel}
    </Link>
  );
};

export { LogoutSpecialMenu };
