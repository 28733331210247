import { useEffect, useState } from "react";

import { useGetAllOrders } from "../..";

const useOrderHistoryList = ({ pageRecordsShow = 10, params = "{}" }) => {
  const [keyword, setSearchTerm] = useState("");
  let [orders, setRequest] = useGetAllOrders();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const preFilter = JSON.parse(params);
    setRequest((prevState) => ({
      ...prevState,
      isFetching: true,
      isLoaded: false,
      params: { ...preFilter, pageRecordsShow: pageRecordsShow, keyword, currentPage },
      makeRequest: true,
    }));
  }, [keyword, setRequest, pageRecordsShow, params, currentPage]);

  return {
    search: setCurrentPage,
    setSearchTerm,
    keyword,
    orders,
    totalPages: Math.ceil(orders.data.records / pageRecordsShow),
    currentPage,
  };
};
export { useOrderHistoryList };
