import { useCallback, useMemo, useState } from "react";
import { disableInteractionSelector } from "../../../../selectors";
import { useSelector } from "react-redux";
import { CartPromoBoxModel, CartPromoBoxProps } from "./CartPromoBox.componentModel";

export const useComponentData = (props: CartPromoBoxProps) => {
  const [promoCode, setPromoCode] = useState("");

  const disableInteraction: boolean = useSelector(disableInteractionSelector);

  const componentData = useMemo(
    () => new CartPromoBoxModel({ promoCode, disableInteraction }),
    [disableInteraction, promoCode],
  );

  const onApplyCode = useCallback(() => {
    props.onApplyCode(promoCode, setPromoCode);
  }, [promoCode, props]);

  return {
    componentData,
    componentService: {
      setPromoCode,
      onApplyCode,
    },
  };
};
