import { useTranslation } from "react-i18next";
import { useElementContext } from "../../contexts";

const BulkOrderListingListView = ({ Card, isFetching, pageRecords, bulkOrderList, config, onButtonClick }) => {
  const {
    ProductListingModule,
    CommonModule: { Spinner },
  } = useElementContext();
  const { t } = useTranslation();
  // const {headings} = config TODO: use this one day to make headings dynamic
  return (
    <>
      {isFetching && <Spinner />}
      {!isFetching && (
        <table className="table table-styled">
          <thead className="tableHeader bulkOrder">
            <tr>
              <th scope="col"></th>
              <th scope="col">{t("frontend.bulkorder.listview.modal.sku")}:</th>
              <th scope="col">{t("frontend.bulkorder.listview.price")}:</th>
              <th scope="col">{t("frontend.bulkorder.listview.qty")}:</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {!isFetching &&
              pageRecords.length > 0 &&
              pageRecords.map((product, index) => {
                let skuID = product.skuID;
                if (!skuID) {
                  const defaultSku =
                    product.skus?.find((sku) => sku.skuID === product.defaultSku_skuID) || product.skus?.[0];
                  skuID = defaultSku?.skuID;
                }

                return (
                  <Card
                    key={`${index}`}
                    showInput
                    config={config}
                    quantity={bulkOrderList[skuID]?.quantity || 0}
                    product={product}
                    onButtonClick={onButtonClick}
                  />
                );
              })}
          </tbody>
        </table>
      )}
      {!isFetching && pageRecords.length === 0 && <ProductListingModule.CoreControl.NoProductFound />}
    </>
  );
};

export { BulkOrderListingListView };
