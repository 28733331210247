import { Event } from "../../pages/Event/interface";
import { useElementContext } from "../../contexts";

export interface IProps {
  contentElementName: string;
  events: Event[];
  customStyleClasses?: string;
}

const EventListing = ({ contentElementName, events, customStyleClasses }: IProps) => {
  const {
    CommonModule: { EventCard },
  } = useElementContext();
  return (
    <div className={customStyleClasses || ""}>
      {contentElementName && (
        <div className="section-title">
          <h2 className="text-center">{contentElementName}</h2>
        </div>
      )}
      {events.length > 0 ? (
        <div className="row">
          {events.map((event) => (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" key={event.slug}>
              <EventCard event={event} />
            </div>
          ))}
        </div>
      ) : (
        <h5 className="text-center">No event listed</h5>
      )}
    </div>
  );
};

export default EventListing;
