import { FilterSpec } from "./FilterBanner";

export interface FilterLabeledInputProps extends FilterSpec {
  isSelected: boolean;
  value: string;
  updateFiltersAndLocations: (key: string, selection: string, searchText?: string) => void;
}

export const FilterLabeledInput = (props: FilterLabeledInputProps) => {
  const { title, value, inputType, updateFiltersAndLocations, isSelected = false } = props;
  const name = `filter-labled-input-${title}`;
  const id = `${name}-${value}`;

  return (
    <div>
      <input
        id={id}
        value={value}
        checked={isSelected}
        type={inputType}
        onChange={() => {
          updateFiltersAndLocations(title, value);
        }}
        name={name}
      />

      <label htmlFor={id} className={"control-label px-2"}>
        {value}
      </label>
    </div>
  );
};
