import { useState } from "react";

const HardwareSearch = ({ searchMeta, setPreFilter, keyword, setKeyword, selectedOption, setSelectedOption }) => {
  const [selectedInput, setSelectedInput] = useState(keyword);

  return (
    <>
      <div className="input-group input-group-lg rounded-pill hardware-searchbar">
        {selectedOption?.search_title && (
          <button className="btn selected-search-slug" type="button" disabled>
            {selectedOption?.search_title.replace("Search By ", "")}
          </button>
        )}
        <div className="col-9">
          <input
            className="form-control appended-form-control rounded-pill"
            type="text"
            value={selectedInput}
            placeholder="Search for products, brands or categories"
            required=""
            onChange={(e) => {
              setSelectedInput(e.target.value);
              const handler = setTimeout(() => {
                setKeyword(e.target.value);
              }, 800);
              return () => {
                clearTimeout(handler);
              };
            }}
          />
          {selectedOption?.attribute?.length > 0 && (
            <div className="input-group bg-light borderless d-flex btm-strip">
              {selectedOption?.attribute?.map((attribute, i) => {
                return (
                  <span className="badge text-secondary" key={`${attribute.optionGroupName}_${i}`}>
                    <span className="attrribute-title">{attribute.optionGroupName} :</span>
                    <input name="" type="number" className="option-input" />
                  </span>
                );
              })}
            </div>
          )}
        </div>
        <div className="multi-mega-menu hardware-search align-items-center d-flex">
          <span className="nav-link text-dark dropdown-toggle">General Search</span>
          <div className="mega-menu dropdown-menu shaddow-sm">
            <div className="d-flex flex-wrap px-2">
              <div className="mega-dropdown-column pt-3 px-3">
                <div
                  className="widget widget-links mb-0"
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                >
                  <ul className="p-0">
                    {searchMeta?.length > 0 &&
                      searchMeta.map((option, i) => {
                        return (
                          <li key={i}>
                            <span
                              className="text-decoration-none p-1"
                              onClick={() => {
                                setPreFilter((prevState) => ({
                                  ...prevState,
                                  propertiesIdentifierList: option?.propertiesIdentifierList,
                                }));
                                setSelectedOption(option);
                              }}
                            >
                              {option.search_title}
                            </span>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="btn px-2" type="button">
          <i className="bi bi-search"></i>
        </button>
      </div>
    </>
  );
};

export { HardwareSearch };
