import { useUtilities } from "../../hooks/useUtilities";

const BasicSidebar = ({ customStyleClasses, children }) => {
  let { eventHandlerForWSIWYG } = useUtilities();
  if (!children?.length) return null;
  return (
    <div
      onClick={eventHandlerForWSIWYG}
      className={`pageSidebar rounded-lg box-shadow-lg p-4 mb-5 ${customStyleClasses || ""}`}
    >
      {children}
    </div>
  );
};
export { BasicSidebar };
