import { t } from "i18next";
import { getCurrentSiteCode } from "../../utils";
import { getSdkURL } from "../../services";

const Oauth0Login = ({ buttonText }) => {
  //get site code
  const siteCode = getCurrentSiteCode();
  //set redirect url
  const redirectURL =
    getSdkURL() + "?slatAction=main.OAuthLoginStorefrontHandler&integrationName=auth0&siteCode=" + siteCode;

  return (
    <div id="auth0" className="btn btn-primary btn-lg m-2" onClick={() => window.location.replace(redirectURL)}>
      <span className="icon"></span>
      <span className="buttonText">{buttonText || t("frontend.oauth.auth0signin")}</span>
    </div>
  );
};

export { Oauth0Login };
