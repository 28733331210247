import { useElementContext } from "../../../contexts";
import { FooterModel } from "./Footer.componentModel";

export const FooterView = ({
  componentData: { preFooter, ribbonMenu, logo, theme, contentStack, formElement },
}: {
  componentData: FooterModel;
}) => {
  const {
    CommonModule: { FooterContent, DynamicContent },
  } = useElementContext();

  return (
    <>
      <div className="footer no-print col-12">
        <DynamicContent contentElements={preFooter} />
      </div>

      <footer className="footer no-print col-12">
        <FooterContent
          ribbonMenu={ribbonMenu}
          logo={logo}
          theme={theme}
          contentStack={contentStack}
          formElement={formElement}
        />
      </footer>
    </>
  );
};
