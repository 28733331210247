import { PropsWithChildren } from "react";
import { createPortal } from "react-dom";

const Portal = ({
  children,
  withPortal = true,
  parent = document.body,
}: PropsWithChildren<{ parent?: HTMLElement; withPortal?: boolean }>) => {
  if (!withPortal) return <>{children}</>;
  return createPortal(children, parent);
};

export default Portal;
