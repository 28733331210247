import { jsonParse } from ".";

window.setSiteConfig = (siteConfig: { domain?: string } = {}) => {
  if (siteConfig) sessionStorage.setItem("devSiteConfig", JSON.stringify(siteConfig));
  else sessionStorage.removeItem("devSiteConfig");

  window.location.reload();
};

window.getSiteConfig = () => {
  const { domain = process.env.REACT_APP_SITE_DOMAIN || window.location.host } =
    jsonParse(sessionStorage.getItem("devSiteConfig")) || {};
  return { domain };
};
