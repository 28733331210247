const FormChronoField = ({
  attributeName,
  attributeCode,
  requiredFlag,
  setValue,
  value,
  invalid,
  validationMessage,
  chronoType,
}) => {
  return (
    <fieldset>
      <div className={`form-label ${requiredFlag ? "required" : ""}`}>{attributeName}</div>
      <input
        type={chronoType}
        value={value}
        onChange={(e) => {
          setValue((response) => ({ ...response, [attributeCode]: e.target.value }));
        }}
      />
      {invalid && <div className="invalid-feedback">{validationMessage ?? ""}</div>}
    </fieldset>
  );
};

export default FormChronoField;
