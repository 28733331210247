import { useFormatCurrency } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { ProductDetailHeadingModel } from "./ProductDetailHeading.componentModel";

export const ProductDetailHeadingView = ({
  componentData: { skuCode, linealMeter, skuLabel },
}: {
  componentData: ProductDetailHeadingModel;
}) => {
  const { t } = useTranslation();
  const [formatCurrency] = useFormatCurrency({});

  return (
    <>
      {skuCode && (
        <div className="row">
          <div className="col">
            {!!skuLabel && <strong className="mb-2 text-muted small">{skuLabel}: </strong>}
            {skuCode}
          </div>
        </div>
      )}

      {linealMeter && (
        <div className="row">
          <div className="col">
            <strong className="mb-2 text-muted small">{t("frontend.product.linealmeter")} </strong>
            {formatCurrency(parseFloat(linealMeter))}
            &nbsp;<span>{t("frontend.includegst")}</span>
          </div>
        </div>
      )}
    </>
  );
};
