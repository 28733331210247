import { useElementContext } from "../../contexts";

const DraftOrderList = () => {
  const {
    CommonModule: { AccountContent, AccountLayout, OverviewDraftOrders },
  } = useElementContext();
  return (
    <AccountLayout>
      <AccountContent />
      <OverviewDraftOrders />
    </AccountLayout>
  );
};

export default DraftOrderList;
