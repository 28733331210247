import { useUtilities } from "../../hooks/useUtilities";
import { Event } from "../../pages/Event/interface";
import { useElementContext } from "../../contexts";

const EventCard = ({ event }: { event: Event }) => {
  let { eventHandlerForWSIWYG } = useUtilities();
  const {
    CommonModule: { Truncate, EventDateTime, SimpleImage },
  } = useElementContext();
  return (
    <div className="eventCard w-100 text-center h-100 d-flex flex-column">
      <div className="position-relative">
        <SimpleImage
          alt=""
          type="event"
          className="eventCard-img rounded-top"
          src={event.thumbnail || event.featuredImage || undefined}
          style={{ aspectRatio: "3/2" }}
        />
        {event.labelText && (
          <label
            className={`w-100 p-1 px-2 m-0 rounded position-absolute bottom-0 start-50 translate-middle-x text-break ${
              event.labelStyles || "bg-primary text-light"
            }`}
          >
            {event.labelText}
          </label>
        )}
      </div>
      <div className="flex-grow-1 d-flex flex-column gap-2 p-2 border border-top-0 rounded-bottom">
        <h4 className="font-weight-bold m-0 p-0">{event.title}</h4>
        <EventDateTime cssClass="small" startDateTime={event.startDateTime} endDateTime={event.endDateTime} />
        <Truncate showExpandBtn={false} lineClamp="3">
          <div
            onClick={eventHandlerForWSIWYG}
            dangerouslySetInnerHTML={{
              __html: event.description,
            }}
          />
        </Truncate>
        <a href={`/event/${event.slug}`} className="btn btn-primary align-self-center mt-auto">
          Read More
        </a>
      </div>
    </div>
  );
};

export default EventCard;
