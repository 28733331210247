import { useSelector } from "react-redux";
import { useElementContext } from "../../contexts";
import { getCategoryRoute } from "../../selectors";
import { sortCategories } from "../../utils";

const CategoryList = ({ data, isFetching = true, categroyMetaData = {} }) => {
  const {
    CommonModule: { SimpleImage, Spinner, ExternalLink },
  } = useElementContext();
  const categoryRoute = useSelector(getCategoryRoute);
  return (
    <>
      {isFetching && (
        <div className="container bg-light box-shadow-lg rounded-lg p-5">
          <Spinner />
        </div>
      )}
      {!isFetching && data.children && data.children?.length > 0 && (
        <div className="container pb-4 pb-sm-5">
          <div className="row pt-5">
            {sortCategories(data.children, categroyMetaData).map((cat) => {
              const cmsCategoryData = categroyMetaData[cat.urlTitle];
              const categoryName = cmsCategoryData?.categoryName || cat.categoryName;
              const categoryImage = cmsCategoryData?.categoryLogo || cat.imageFile;
              return (
                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-3" key={cat.categoryID}>
                  <div className="card border-0">
                    <ExternalLink
                      linkUrl={`/${categoryRoute}/${cat.urlTitle}`}
                      className="d-block overflow-hidden rounded-lg"
                    >
                      <SimpleImage
                        className="d-block w-100 categoryList-image"
                        src={categoryImage}
                        alt={categoryName}
                        type="category"
                      />
                    </ExternalLink>
                    <div className="card-body">
                      <h4 className="categoryList-title">
                        <ExternalLink className="link-button" linkUrl={`/${categoryRoute}/${cat.urlTitle}`}>
                          {categoryName}
                        </ExternalLink>
                      </h4>
                      <ul className="list-unstyled font-size-sm mb-0">
                        {sortCategories(cat.children, categroyMetaData).map((cat) => {
                          const cmsCategoryData = categroyMetaData[cat.urlTitle];
                          const categoryName = cmsCategoryData?.categoryName || cat.categoryName;
                          return (
                            <li className="d-flex align-items-center justify-content-between" key={cat.categoryID}>
                              <ExternalLink
                                linkUrl={`/${categoryRoute}/${cat.urlTitle}`}
                                className="link-button nav-link-style d-flex align-items-center justify-content-between text-left"
                              >
                                <i className="bi bi-chevron-circle-right pr-2"></i>
                                {categoryName}
                              </ExternalLink>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
export { CategoryList };
