import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProductFormModel } from "./ProductForm.componentModel";
import { useElementContext } from "../../../../contexts";

const ProductFormView = ({
  viewData: {
    authenticationRequiredForAddToCart,
    sku,
    quotes,
    wishlists,
    showInputLabel,
    quantityInputMode,
    showInventory,
    showExistingQuoteAndLists,
    isLoading,
    listModal,
    quoteModal,
    itemCount,
    skuConfiguration,
  },
  setListModal,
  setQuoteModal,
  setItemCount,
  addToCartOrQuote,
}: {
  viewData: ProductFormModel;
  setListModal: (isOpen: boolean) => void;
  setQuoteModal: (isOpen: boolean) => void;
  setItemCount: (n: number) => void;
  addToCartOrQuote: (quote?: any) => void;
}) => {
  //changes - getting config as a param
  const { t } = useTranslation();
  const { ProductModule } = useElementContext();
  if (authenticationRequiredForAddToCart)
    return (
      <div className="alert alert-warning fs-6" role="alert">
        {t("frontend.product.loginToAdd")}
        <br />
        <Link to="/my-account/login"> {t("frontend.account.login")} </Link>
      </div>
    );

  return (
    <>
      <form
        className="productDetail-form row align-items-end w-100"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <div className="col-12 form-group align-items-center">
          {(sku.settings.skuAllowAddToCartFlag || sku.settings.skuAllowQuoteFlag) && (
            <>
              <ProductModule.CoreComponents.ProductOutOfStock isFetching={isLoading} sku={sku} />
              <ProductModule.CoreControl.ProductQuantityInput
                sku={sku}
                quantity={itemCount}
                setQuantity={setItemCount}
                showLabel={showInputLabel}
                quantityInputType={quantityInputMode}
                showInventory={showInventory}
              />
            </>
          )}
        </div>
        <div className="col-12">
          <ProductModule.CoreControl.ProductMultiCartButtons
            sku={sku}
            quotes={quotes}
            wishlists={wishlists}
            isLoading={isLoading}
            setQuoteModal={setQuoteModal}
            addToCartOrQuote={addToCartOrQuote}
            setListModal={setListModal}
            showExistingQuoteAndLists={showExistingQuoteAndLists}
          />
        </div>
      </form>
      {listModal && <ProductModule.CoreComponents.AddProductToListModal sku={sku} setListModal={setListModal} />}
      {quoteModal && (
        <ProductModule.CoreComponents.AddProductToQuoteModal
          skuID={sku?.skuID || ""}
          setQuoteModal={setQuoteModal}
          quantity={itemCount}
          skuConfiguration={skuConfiguration}
        />
      )}
    </>
  );
};

export { ProductFormView };
