import { useComponentData } from "./ProductBundle.componentService";
import { ProductBundleProps } from "./ProductBundle.componentModel";
import { ProductBundleView } from "./ProductBundle.componentView";

const ProductBundle = (props: ProductBundleProps) => {
  const {
    componentData,
    componentService: { setBundleBuildsOnAccount, addToCart, removeProductBundleBuild, removeProductBundleItem },
  } = useComponentData(props);

  return (
    <ProductBundleView
      componentData={componentData}
      addToCart={addToCart}
      removeProductBundleItem={removeProductBundleItem}
      setBundleBuildsOnAccount={setBundleBuildsOnAccount}
      removeProductBundleBuild={removeProductBundleBuild}
    />
  );
};

export { ProductBundle, type ProductBundleProps };
