import { Sku } from "../../../../interface/Product";
import { useMemo } from "react";
import { ProductOutOfStockModel, ProductOutOfStockProps } from "./ProductOutOfStock.componentModel";
import { SearchProduct, SearchSku } from "../../../../interface/SearchProduct";

const useComponentData = (props: ProductOutOfStockProps) => {
  const componentData = useMemo(() => new ProductOutOfStockModel({ props }), [props]);
  return { componentData };
};

//we can rely on calcualtedQATS to check if item is in stock
//if track invetory is off, it'll contain default value
//if track inventory is on, it'll contain real value
const validateProductOutOfStock = (sku?: Sku | SearchSku | SearchProduct) => {
  return !sku || sku?.calculatedQATS < 1;
};
const validateProductInStock = (sku?: Sku | SearchSku | SearchProduct) => {
  return !validateProductOutOfStock(sku);
};

export { useComponentData, validateProductOutOfStock, validateProductInStock };
