import { toBoolean } from "../../utils";

const Row = (props) => {
  const {
    systemCode,
    stylingCustomClasses,
    children,
    forceColumnsFlag = true,
    bootStrapLayoutClass,
    contentElementName,
    customStyleClasses,
  } = props;

  return (
    <div className={[systemCode, stylingCustomClasses, bootStrapLayoutClass].join(" ")}>
      <div className={`container ${customStyleClasses}`}>
        {contentElementName && <h2 className="text-center">{contentElementName}</h2>}
        <div className="row p-4">
          {children?.map((el, idx) => {
            return (
              <div key={idx} className={toBoolean(forceColumnsFlag) ? "col" : "rowEl"}>
                {el}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export { Row };
