import { Location } from "../../../interface/Location";
import { FilterSpec } from "./FilterBanner";
import { useElementContext } from "../../../contexts";

interface FilterCardPorps {
  isSlim?: boolean;
  data: Location[];
  updateFiltersAndLocations: (key: string, selection: string, searchText?: string) => void;
  metric: string | number;
  filter: FilterSpec;
  options: string[];
}

const FilterCard = (props: FilterCardPorps) => {
  if (props.isSlim) return <FilterCardViewSlim {...props} />;
  return <FilterCardView {...props} />;
};

const FilterCardView = (props: FilterCardPorps) => {
  const { LocationModule } = useElementContext();
  const { options, filter, updateFiltersAndLocations } = props;
  return (
    <div className={"filter-list-container g-col-4 my-1"}>
      <div className={"d-flex justify-content-between"}>
        <span className="lead">{filter.title}</span>
        <input
          type="reset"
          value="clear"
          onClick={() => {
            updateFiltersAndLocations(filter.title, "");
          }}
          className={"d-inline bg-light"}
          style={{ border: "grey", fontSize: "revert" }}
        />
      </div>
      {options
        ?.sort((a, b) => a.localeCompare(b))
        .map((inputField) => {
          return (
            <LocationModule.CoreControl.FilterLabeledInput
              {...filter}
              isSelected={filter.selection === inputField}
              value={inputField}
              updateFiltersAndLocations={updateFiltersAndLocations}
              key={inputField}
            />
          );
        })}
    </div>
  );
};

const FilterCardViewSlim = (props: FilterCardPorps) => {
  const { options, filter, updateFiltersAndLocations } = props;
  return (
    <div>
      <div className={"d-flex justify-content-between"}>
        <span className="lead">{filter.title}</span>
      </div>
      <select
        name={filter.title}
        onChange={(e) => {
          e.preventDefault();
          if (e.target.value === "all") {
            updateFiltersAndLocations(filter.title, "");
          } else {
            updateFiltersAndLocations(filter.title, e.target.value);
          }
        }}
      >
        <option value="all">Show All</option>
        {options
          ?.sort((a, b) => a.localeCompare(b))
          ?.map((inputField) => {
            return (
              <option key={`filter-labled-input-${inputField}-${inputField}`} value={inputField}>
                {inputField}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export { FilterCard, type FilterCardPorps };
