import { Link } from "react-router-dom";

const ColumnResource = (props) => {
  console.log(props);
  return (
    <div className="d-flex flex-column align-items-start text-break">
      <h3>{props.category}</h3>
      {props.resources.map((resource, i) => {
        return (
          <Link key={i} to={`//${resource.fileLink.replace(/^https?:\/\//, "")}`}>
            <p>{resource.title}</p>
          </Link>
        );
      })}
    </div>
  );
};

export { ColumnResource };
