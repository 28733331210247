import { Dispatch, SetStateAction } from "react";
import { Sku, OptionGroup, Option } from "../../../../interface/Product";
import { DisplayConfig } from "../../product.moduleModels";

export interface SkuOptionProps {
  selection: { [optionGroupCode: string]: string };
  setSelection: Dispatch<SetStateAction<{ [optionGroupCode: string]: string }>>;
  product: any; ///TODO: update to product model once it is coded
  config: DisplayConfig;
  disableQs?: boolean;
}

//MODEL
export class SkuOptionModel {
  public optionGroups: OptionGroup[] = [];
  public availableProducts: Sku[] = [];
  public activeFilters: string[] = [];
  public optionStyle: "tile" | "dropdown";
  public selection: SkuOptionProps["selection"] = {};
  public filteredProducts: Sku[] = [];
  public filteredOptions: OptionGroup[] = [];
  public customProps: any;

  constructor({ product: { optionGroups, skus }, selection, config }: SkuOptionProps) {
    this.optionGroups = optionGroups;
    this.availableProducts = skus;
    this.optionStyle = config?.optionsStyle || "tile";
    this.selection = selection;
    this.activeFilters = Object.entries(selection).reduce<string[]>((ids, [optionGroupCode, optionCode]) => {
      const optionGroup = optionGroups.find((optionGroup: any) => optionGroup.optionGroupCode === optionGroupCode);
      const option = optionGroup?.options.find((option: any) => option.optionCode === optionCode);
      if (option) ids.push(option.optionID);
      return ids;
    }, []);

    this.applyFiltersToAvailableProducts(); //Initial data processing
    this.calculateValidOptionGroups(); //Initial data processing
  }

  private sortOptionGroups(optionGroups: OptionGroup[]) {
    return optionGroups
      .map((optionGroup: OptionGroup) => {
        optionGroup.options = optionGroup.options.sort((a: Option, b: Option) => a.sortOrder - b.sortOrder);
        return optionGroup;
      })
      .sort((a: OptionGroup, b: OptionGroup) => a.sortOrder - b.sortOrder);
  }

  private calculateValidOptionGroups() {
    this.filteredOptions = this.optionGroups.map((optionGroup: OptionGroup) => {
      //map option group
      const options = optionGroup.options.map((option: Option) => {
        let productCount = 0;
        this.filteredProducts.forEach((product: Sku) => {
          if (product.options.find((productOption: any) => productOption.optionID === option.optionID)) productCount++; //find if our product uses option and increment the option count
        });
        return { ...option, active: this.activeFilters.includes(option.optionID), productCount };
      });
      return { ...optionGroup, options };
    });
    this.filteredOptions = this.sortOptionGroups(this.filteredOptions);
  }

  private applyFiltersToAvailableProducts() {
    let filteredProducts = this.availableProducts;
    this.activeFilters.forEach((optionFilter: string) => {
      //map each filter option
      filteredProducts = filteredProducts.filter((product: Sku) => {
        //filter down to only the products that meet this filter option
        return product.options.find((productOption: Option) => productOption.optionID === optionFilter);
      });
    });
    this.filteredProducts = filteredProducts;
  }
}
