import { PropsWithChildren, createContext, useContext } from "react";

import {
  ProductCardModule,
  CartModule,
  LocationModule,
  ProductModule,
  ProductListingModule,
  CommonModule,
  PageModule,
} from "../modules/index";

const coreComponents = {
  ProductCardModule,
  CartModule,
  LocationModule,
  ProductModule,
  ProductListingModule,
  CommonModule,
  PageModule,
};

const ElementContext = createContext<typeof coreComponents>(coreComponents);

const ElementContextProvider = ({
  children,
  customComponents = {},
}: PropsWithChildren<{
  customComponents?: Partial<typeof coreComponents>;
}>) => {
  return (
    <ElementContext.Provider value={{ ...coreComponents, ...customComponents }}>{children}</ElementContext.Provider>
  );
};

const useElementContext = () => {
  // get the context
  const context = useContext(ElementContext);
  // if `undefined`, throw an error
  if (!context === undefined) {
    throw new Error("useElementContext was used outside of its Provider");
  }

  // return proxy to context to prevent hard errors from being thrown if an element is missing from the context
  return context;
};

/*
 * we return ElementContext because a user may want to bypass the API call if th
 * user got the Content data from another spot and wants to Hydrate Manually.
 */
export { ElementContext, ElementContextProvider, useElementContext };
