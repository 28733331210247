import { useTranslation } from "react-i18next";
import { SearchConfig, viewModes, GRID, LISTING, LINEBYLINE } from "../productListing.moduleModels";

export interface ListingViewToggleProps {
  config: SearchConfig;
  viewMode: typeof GRID | typeof LISTING | typeof LINEBYLINE;
  setViewMode: (n: typeof GRID | typeof LISTING | typeof LINEBYLINE) => void;
}

const ListingViewToggle = ({ config, viewMode, setViewMode }: ListingViewToggleProps) => {
  const { t } = useTranslation();
  return (
    <ul className="nav nav-pills justify-content-end" id="pills-tab" role="tablist">
      {config?.viewModeOptions?.length > 1 &&
        config?.viewModeOptions?.map((key: keyof typeof viewModes) => {
          if (!viewModes[key]) return null;
          return (
            <li key={key} className="nav-item border" role="presentation">
              <button
                className={`nav-link ${viewMode === key ? "active" : ""}`}
                id="pills-home-tab"
                data-bs-toggle="pill"
                type="button"
                onClick={() => setViewMode(key)}
              >
                {t(viewModes[key].name)}
              </button>
            </li>
          );
        })}
    </ul>
  );
};
export { ListingViewToggle };
