export interface ProductPriceProps {
  salePrice?: number;
  listPrice?: number;
  type?: string;
  showPriceForUserType?: string;
  ignoreDisplayRules?: boolean;
}

export class ProductPriceModel {
  showPriceType?: "showLoginToViewPrice" | "showVerifyForPricing" | "showMissingPrice";

  salePrice: number = 0;
  listPrice: number = 0;

  noPriceText: string = "";
  pricingPreText: string = "";
  pricingPostText: string = "";

  constructor({
    props: { listPrice = 0, salePrice = 0, type = "product", showPriceForUserType = "All" },
    theme,
    isAuthenticated,
    isAccountVerified,
  }: {
    props: ProductPriceProps;
    theme: { noPriceText: string; pricingPreText: string; pricingPostText: string };
    isAuthenticated: boolean;
    isAccountVerified: boolean;
  }) {
    this.salePrice = salePrice;
    this.listPrice = listPrice;

    this.noPriceText = theme.noPriceText;
    this.pricingPostText = theme.pricingPostText;
    this.pricingPreText = theme.pricingPreText;

    if ((showPriceForUserType === "Authenticated" || showPriceForUserType === "Verified") && !isAuthenticated) {
      this.showPriceType = "showLoginToViewPrice";
    } else if (showPriceForUserType === "Verified" && !isAccountVerified) {
      this.showPriceType = "showVerifyForPricing";
    } else if (!listPrice && !salePrice && type === "product") {
      this.showPriceType = "showMissingPrice";
    }
  }
}
