import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useContentContext } from "../../contexts/ContentContext";
import { useUtilities } from "../../hooks/useUtilities";
import { useElementContext } from "../../contexts";

const DynamicPage = ({ children, customComponents = {}, ignoreLayout = false }) => {
  const {
    CommonModule: { Layout, DynamicComponent, DynamicContent, Spinner },
  } = useElementContext();
  const pageData = useContentContext();
  let { eventHandlerForWSIWYG } = useUtilities();
  const siteName = useSelector((state) => state.configuration.site.siteName);
  const header = useSelector((state) => state.configuration.site?.header);
  const footer = useSelector((state) => state.configuration.site?.footer);

  if (!!pageData?.is500 || !!pageData?.is404) {
    //TODO: Modve this out to it's own page template
    return (
      <div className={"DynamicPage p-0"}>
        <div className="page-title-overlap bg-lightgray pt-4">
          <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
            <div className="order-lg-1 pr-lg-4 text-center">
              <h1 className="h3 text-dark mb-0 font-accent text-center">{pageData?.contentHeading?.trim()}</h1>
            </div>
          </div>
        </div>
        <div className="container p-4 p-lg-5 mb-5">
          <div onClick={eventHandlerForWSIWYG} dangerouslySetInnerHTML={{ __html: pageData?.contentBody }} />
          {children}
        </div>
      </div>
    );
  }

  const renderDynamicContent = () => (
    <DynamicContent key={pageData?.urlTitlePath} contentElements={pageData?.contentElements} />
  );

  return (
    <Layout>
      {!!pageData?.settings?.contentHTMLTitleString && <Helmet title={pageData.settings.contentHTMLTitleString} />}
      {!pageData?.settings?.contentHTMLTitleString && (
        <Helmet title={[siteName, pageData?.title].filter((e) => e).join(" | ")} />
      )}

      {/* A funky way to render the Header through dynamic component until this can be abstracted */}
      <DynamicComponent el={header} key={"element-header"} />
      {pageData.isLoading && <Spinner />}
      {typeof children === "function" ? (
        children({ renderDynamicContent })
      ) : (
        <>
          {renderDynamicContent()}
          {children}
        </>
      )}
      {/* A funky way to render the Footer through dynamic component until this can be abstracted */}
      <DynamicComponent el={footer} key={"element-footer"} />
    </Layout>
  );
};
export default DynamicPage;
