import { Link } from "react-router-dom";
import { isAuthenticated } from "../../../utils";

export const MobileHeader = ({ siteSetting }: { siteSetting: any }) => {
  const accountBubbleRoute = isAuthenticated() ? "/my-account/overview" : "/my-account/login";
  return (
    <div className="ms-2 d-lg-none d-flex col-6 align-items-center justify-content-end px-0">
      <Link to={accountBubbleRoute} className="text-dark">
        <span className="nav-link text-center px-2">
          <i className="bi bi-person-circle account-bubble-icon fs-4"></i>{" "}
        </span>
      </Link>
      {siteSetting?.sitePurpose === "Transactional" && (
        <Link to="/shopping-cart" className="text-dark">
          <span className="nav-link text-center px-2">
            <i className="bi bi-cart fs-4"></i>{" "}
          </span>
        </Link>
      )}
      <button
        className="nav-link collapsed align-items-end d-lg-none bg-transparent border-0 pe-0"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i className="bi bi-list fs-4"></i>
      </button>
    </div>
  );
};
