import { useMemo } from "react";
import { useSelector } from "react-redux";
import { ProductPriceModel, ProductPriceProps } from "./ProductPrice.componentModel";
import { isAuthenticated } from "../../../../utils";
import { getVerifiedAccountFlag } from "../../../../selectors";

export const useComponentData = (props: ProductPriceProps) => {
  const theme = useSelector((state: any) => state.configuration.site.theme) as any;
  const isAccountVerified = useSelector(getVerifiedAccountFlag) as boolean;
  const componentData = useMemo(
    () => new ProductPriceModel({ props, theme, isAuthenticated: isAuthenticated(), isAccountVerified }),
    [isAccountVerified, props, theme],
  );

  return { componentData };
};
