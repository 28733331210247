import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { PotentialFilters } from "../../../interface/SearchProduct";

export interface SearchPreFilterProps {
  params: any;
  potentialFilters: PotentialFilters;
  requiredFilterSlug: string;
}

const SearchPreFilter = ({ params, potentialFilters, requiredFilterSlug }: SearchPreFilterProps) => {
  const { t } = useTranslation();
  const loc = useLocation();
  if (params[`facet_${requiredFilterSlug}`]?.length) return null; // hide if satisfied
  return (
    <div className="mt-md-5 mt-sm-0">
      <h4 className="text-center">{t("frontend.profiles.preFilterMessage")} </h4>
      <div className="text-center list-style-none ms-0 mt-4">
        {potentialFilters[requiredFilterSlug]?.options.map((option, idx: number) => {
          return (
            <Link
              key={idx}
              to={
                `${loc.pathname}${loc.search ? `${loc.search}&` : "?"}${`facet_${requiredFilterSlug}`}=` +
                encodeURIComponent(option.slug)
              }
              id={option.slug}
              className="btn btn-primary m-2"
            >
              {option?.name || option?.slug}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export { SearchPreFilter };
