import { Location } from "../../../interface/Location";
import { useElementContext } from "../../../contexts";

export interface FilterSpec {
  title: string;
  selection: string;
  inputType: "radio" | "checkbox" | "string";
  inputFields: {
    label: string;
    value: any;
  }[];
  filteredObjectProperty: string | number;
  comparator: "eq" | "neq" | "geq" | "leq" | "lt" | "gt";
}

export interface FilterBannerProps {
  isSlim?: boolean;
  updateFiltersAndLocations: (key: string, selection: string, searchText?: string) => void;
  data: Location[];
  filters: FilterSpec[];
  searchValue: string;
  setSearchValue: (value: string) => void;
  showSearch: boolean;
  filterHeading?: boolean;
}

const getUniqueValuesForAttribute = (arr: Location[], attribute: string | number) =>
  arr
    ?.map((elem) => (elem[attribute as keyof Location] as string)?.trim())
    .filter((value, index, self) => self.indexOf(value) === index)
    .filter((a) => a);

const FilterBanner = (props: FilterBannerProps) => {
  if (props.isSlim) return <FilterBannerViewSlim {...props} />;
  return <FilterBannerView {...props} />;
};

const FilterBannerView = (props: FilterBannerProps) => {
  const { LocationModule } = useElementContext();
  const { data, filters, updateFiltersAndLocations, filterHeading, searchValue, showSearch } = props;
  return (
    <div>
      <div className={"filter-block"}>
        <div className={filterHeading ? "lead" : "d-none"}>Filters</div>
        {showSearch && (
          <div className={"g-col-4 mb-3"}>
            <span className="lead">Search</span>
            <div className={"d-flex justify-content-between"}>
              <input
                value={searchValue}
                type="text"
                onChange={(e) => {
                  e.preventDefault();
                  updateFiltersAndLocations("search", "", e.target.value);
                }}
                className="form-control appended-form-control rounded-pill"
              />
            </div>
          </div>
        )}
        {filters?.map((filter) => (
          <LocationModule.CoreControl.FilterCard
            data={data}
            updateFiltersAndLocations={updateFiltersAndLocations}
            metric={filter.filteredObjectProperty}
            filter={filter}
            options={getUniqueValuesForAttribute(data, filter.filteredObjectProperty)}
            key={filter.title}
          />
        ))}
      </div>
    </div>
  );
};

const FilterBannerViewSlim = (props: FilterBannerProps) => {
  const { LocationModule } = useElementContext();
  const { data, filters = [], updateFiltersAndLocations, searchValue, showSearch } = props;
  const colSize = 12 / (filters.length + 1);
  return (
    <div>
      <div className={`filter-block my-1 grid`}>
        {showSearch && (
          <div className={`mb-3  g-col-12 g-col-md-${colSize}`}>
            <span className="lead">Search</span>
            <div className={"d-flex justify-content-between"}>
              <input
                value={searchValue}
                type="text"
                onChange={(e) => {
                  e.preventDefault();
                  updateFiltersAndLocations("search", "", e.target.value);
                }}
                className="form-control appended-form-control rounded-pill"
              />
            </div>
          </div>
        )}
        {filters?.map((filter) => (
          <div key={filter.title} className={`filter-list-container-slim g-col-12 g-col-md-${colSize} my-1`}>
            <LocationModule.CoreControl.FilterCard
              isSlim
              data={data}
              updateFiltersAndLocations={updateFiltersAndLocations}
              metric={filter.filteredObjectProperty}
              filter={filter}
              options={getUniqueValuesForAttribute(data, filter.filteredObjectProperty)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export { FilterBanner };
