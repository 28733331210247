import { ProductCardActionsModel } from "../Components/ProductCardActions/ProductCardActions.componentModel";
import { useElementContext } from "../../../contexts";

const ListingButtons = ({
  buttonStack,
  ...rest
}: Omit<ProductCardActionsModel, "type"> & { onAddToCart: () => void }) => {
  const { ProductCardModule } = useElementContext();
  return (
    <div className="d-flex flex-wrap px-2 gap-2">
      {buttonStack?.map((buttonProps: any, idx: number) => (
        <div key={idx} className="productCard-button">
          <ProductCardModule.CoreControl.ProductCardButton {...rest} {...buttonProps} />
        </div>
      ))}
    </div>
  );
};

export { ListingButtons };
