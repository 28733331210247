import { useContentContext } from "../../contexts/ContentContext";
import { useElementContext } from "../../contexts";

const Blog = () => {
  const pageData = useContentContext();
  const {
    PageModule: { DynamicPage },
    CommonModule: { BlogListing },
  } = useElementContext();

  return (
    <DynamicPage ignoreLayout={true}>
      {({ renderDynamicContent }) => (
        <>
          <div className="blogTitleContainer p-5 text-center">
            <h1 className="blogTitle">{pageData.title}</h1>
          </div>
          <BlogListing />
          {renderDynamicContent()}
        </>
      )}
    </DynamicPage>
  );
};

export default Blog;
