import { useState, useEffect, useCallback } from "react";
import { useGetOrderDetails } from "../../hooks";
import { useElementContext } from "../../contexts";

const DraftOrder = ({ orderID = "" }) => {
  const {
    CommonModule: { DraftOrderHeader, DraftOrderTab, AddProductsTab, QuickAddTab, CSVAddTab, Spinner },
  } = useElementContext();
  const [showFilters, setShowFilters] = useState(false);
  const [orderFilter, setOrderFilter] = useState(true);
  const [activeTab, setActiveTab] = useState("draft");
  const [showSummary, setShowSummary] = useState(false);
  const [orderEditable, setOrderEditable] = useState(true);
  const [filter, setFilter] = useState({
    orderID,
    categoryID: "",
    brandID: "",
    keyword: "",
  });

  let [order, setRequest] = useGetOrderDetails(filter);

  const getOrderInfo = useCallback(
    ({ isFetching = true, makeRequest = true, isLoaded = false } = {}) => {
      setRequest((prevState) => ({
        ...prevState,
        isFetching,
        isLoaded,
        params: filter,
        makeRequest,
      }));
    },
    [filter, setRequest],
  );

  useEffect(() => {
    getOrderInfo();
  }, [getOrderInfo]);

  const applyFilter = useCallback((filterItem) => {
    setFilter((prevState) => ({ ...prevState, ...filterItem }));
  }, []);

  const orderInfo = order?.data?.orderInfo?.at(0);
  useEffect(() => {
    if (orderInfo?.orderStatusType_typeCode !== undefined && orderInfo?.orderStatusType_typeCode !== "ostDraft") {
      setShowSummary(true);
      setOrderEditable(false);
    }
  }, [orderInfo]);

  const orderFulfillment = order?.data?.orderFulfillments?.at(0);

  const renderDraftOrderTab = () => {
    return (
      <DraftOrderTab
        showFilters={showFilters}
        orderItems={order?.data?.orderItems}
        orderTotal={orderInfo?.calculatedTotal}
        orderFilters={order?.data?.orderFilters}
        orderID={orderID}
        filter={filter}
        setFilter={applyFilter}
        getOrderInfo={getOrderInfo}
        showSummary={showSummary}
      />
    );
  };

  return (
    <div className="container">
      <DraftOrderHeader
        orderInfo={orderInfo}
        orderFulfillment={orderFulfillment === undefined ? null : orderFulfillment}
        orderPayments={order?.data?.orderPayments?.at(0)}
        setShowSummary={setShowSummary}
        showSummary={showSummary}
        orderEditable={orderEditable}
        getOrderInfo={getOrderInfo}
      />
      {!showSummary && (
        <div className="d-flex col-12 justify-content-between nav-tablist draft-order-tabs">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link text-primary active"
                id="draft-order-tab"
                data-bs-toggle="tab"
                data-bs-target="#draft-order"
                type="button"
                role="tab"
                aria-controls="draft-order"
                aria-selected="true"
                onClick={() => {
                  setOrderFilter(true);
                  setActiveTab("draft");
                }}
              >
                Line Items
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link text-primary"
                id="addProduct-tab"
                data-bs-toggle="tab"
                data-bs-target="#addProduct"
                type="button"
                role="tab"
                aria-controls="addProduct"
                aria-selected="false"
                onClick={() => {
                  setOrderFilter(false);
                  setActiveTab("addProduct");
                }}
              >
                Add Products +
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link text-primary"
                id="quickAdd-tab"
                data-bs-toggle="tab"
                data-bs-target="#quickAdd"
                type="button"
                role="tab"
                aria-controls="quickAdd"
                aria-selected="false"
                onClick={() => {
                  setOrderFilter(false);
                  setActiveTab("quickAdd");
                }}
              >
                Quick Add
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link text-primary"
                id="csv-tab"
                data-bs-toggle="tab"
                data-bs-target="#csvAdd"
                type="button"
                role="tab"
                aria-controls="csvAdd"
                aria-selected="false"
                onClick={() => {
                  setOrderFilter(false);
                  setActiveTab("csvAdd");
                }}
              >
                Upload CSV
              </button>
            </li>
          </ul>
          {orderFilter && (order?.data?.orderItems ?? []).length > 0 && (
            <button className="btn btn-light btn-rounded my-1" onClick={() => setShowFilters(!showFilters)}>
              {showFilters ? `Hide Order Filter` : `Show Order Filter`}
              <i className="bi bi-toggles2"></i>
            </button>
          )}
        </div>
      )}
      {showSummary && renderDraftOrderTab()}
      {!showSummary && (
        <div className="d-flex">
          <div className="tab-content w-100" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="draft-order"
              role="tabpanel"
              aria-labelledby="draft-order-tab"
            >
              {order.isFetching ? <Spinner /> : renderDraftOrderTab()}
            </div>
            <div className="tab-pane fade" id="addProduct" role="tabpanel" aria-labelledby="addProduct-tab">
              <AddProductsTab orderID={orderID} getOrderInfo={getOrderInfo} />
            </div>
            <div className="tab-pane fade" id="quickAdd" role="tabpanel" aria-labelledby="quickAdd-tab">
              <QuickAddTab orderID={orderID} getOrderInfo={getOrderInfo} />
            </div>
            <div className="tab-pane fade" id="csvAdd" role="tabpanel" aria-labelledby="csvAdd-tab">
              <CSVAddTab orderID={orderID} getOrderInfo={getOrderInfo} />
            </div>
          </div>
        </div>
      )}
      <div style={{ minHeight: "150px" }}> </div>
    </div>
  );
};

export default DraftOrder;
