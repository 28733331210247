import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { getCurrentSiteCode } from "../../utils";
const baseVars = [
  "--bs-primary: var(--uc-primarycolor)",
  "--bs-secondary: var(--uc-secondarycolor)",
  "--bs-success: var(--uc-successcolor)",
  "--bs-info: var(--uc-infocolor)",
  "--bs-warning: var(--uc-warningcolor)",
  "--bs-danger: var(--uc-dangercolor)",
  "--bs-light: var(--uc-lightcolor)",
  "--bs-dark: var(--uc-darkcolor)",
  "--bs-body-font-size: var(--uc-fontSize)",
];

const rgbColorVarsMap = {
  primarycolor: "--bs-primary-rgb: var(--uc-primarycolor-rgb)",
  secondarycolor: "--bs-secondary-rgb: var(--uc-secondarycolor-rgb)",
  successcolor: "--bs-success-rgb: var(--uc-successcolor-rgb)",
  infocolor: "--bs-info-rgb: var(--uc-infocolor-rgb)",
  warningcolor: "--bs-warning-rgb: var(--uc-warningcolor-rgb)",
  dangercolor: "--bs-danger-rgb: var(--uc-dangercolor-rgb)",
  lightcolor: "--bs-light-rgb: var(--uc-lightcolor-rgb)",
  darkcolor: "--bs-dark-rgb: var(--uc-darkcolor-rgb)",
};

function hexToRgb(hex) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const fullHex = hex?.replace?.(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(fullHex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

const Theme = ({ children }) => {
  const [safeToLoad, setSafeToLoad] = useState(false);
  const [stylesToLoad, setStylesToLoad] = useState([]);
  const [dynamicCSS, setDynamicCSS] = useState("");
  const theme = useSelector((state) => state.configuration.site.theme);
  const siteCode = getCurrentSiteCode();

  useEffect(() => {
    const temporaryTheme = { ...theme };
    setDynamicCSS(temporaryTheme.customCss);
    delete temporaryTheme.customCss;
    delete temporaryTheme.themeDescription;
    delete temporaryTheme.themeID;
    let cssVariables = [];
    Object.keys(temporaryTheme)
      .sort()
      .forEach((item) => {
        const defaultHex = getComputedStyle(document.documentElement)
          .getPropertyValue(`--uc-${item.toLowerCase()}`)
          .trim();
        const rgb = hexToRgb(temporaryTheme[item] || defaultHex);

        cssVariables = [
          ...cssVariables,
          ...(temporaryTheme[item] ? [`--uc-${item.toLowerCase()}: ${temporaryTheme[item]}`] : []),
          ...(rgb ? [`--uc-${item.toLowerCase()}-rgb: ${rgb.r}, ${rgb.g}, ${rgb.b}`] : []),
          ...(rgb && rgbColorVarsMap[item.toLowerCase()] ? [rgbColorVarsMap[item.toLowerCase()]] : []),
        ];
      });
    setStylesToLoad([...cssVariables, ...baseVars].join(";\n"));
    setSafeToLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme, theme?.themeID]);

  let helmetStyles = [
    { type: "text/css", cssText: `:root {\n${stylesToLoad}\n}` },
    { type: "text/css", cssText: dynamicCSS },
  ];
  let helmetLinks = [];
  if (theme?.favicon?.trim()?.length > 0) {
    let oldFavicon = document.querySelector("link[rel~='icon']");
    if (oldFavicon) {
      document.head.removeChild(oldFavicon);
    }
    helmetLinks.push({
      rel: "icon",
      type: "image/png",
      href: theme.favicon,
    });
  }
  if (safeToLoad) {
    return (
      <div className={[siteCode, theme?.themeKey, theme?.themeName].filter((a) => a).join(" ")}>
        <Helmet style={helmetStyles} link={helmetLinks} />
        {children}
      </div>
    );
  }
  return null;
};

export { Theme };
