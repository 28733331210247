import { Sku } from "../../../../interface/Product";
import { SearchSku } from "../../../../interface/SearchProduct";
import { validateProductOutOfStock } from "./ProductOutOfStock.componentService";

export interface ProductOutOfStockProps {
  isFetching?: boolean;
  sku: SearchSku | Sku;
  cssClass?: string;
}

export class ProductOutOfStockModel {
  cssClass?: string;
  isProductOutOfStock: boolean = false;
  constructor({ props }: { props: ProductOutOfStockProps }) {
    this.cssClass = props.cssClass;
    this.isProductOutOfStock = !props.isFetching && validateProductOutOfStock(props.sku);
  }
}
