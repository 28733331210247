import { useElementContext } from "../../../contexts";

const ContentCard = (props) => {
  const {
    CommonModule: { BasicCard, OverlayCard, HorizontalCard },
  } = useElementContext();
  const { contentCardLayout = "card-basic", contentCardImage, ...rest } = props;

  if (contentCardImage === "")
    return <BasicCard imagePosition="top" noImage={true} contentCardImage={contentCardImage} {...rest} />;
  if (contentCardLayout === "card-basic")
    return <BasicCard imagePosition="top" contentCardImage={contentCardImage} {...rest} />;
  if (contentCardLayout === "card-basic-image-bottom")
    return <BasicCard imagePosition="bottom" contentCardImage={contentCardImage} {...rest} />;

  if (contentCardLayout === "card-overlay-left")
    return <OverlayCard overlayType="left" contentCardImage={contentCardImage} {...rest} />;
  if (contentCardLayout === "card-overlay-right")
    return <OverlayCard overlayType="right" contentCardImage={contentCardImage} {...rest} />;
  if (contentCardLayout === "card-overlay-heading")
    return <OverlayCard overlayType="heading" contentCardImage={contentCardImage} {...rest} />;

  if (contentCardLayout === "card-horizontal-image-left")
    return <HorizontalCard imagePosition="left" contentCardImage={contentCardImage} {...rest} />;
  if (contentCardLayout === "card-horizontal-image-right")
    return <HorizontalCard imagePosition="right" contentCardImage={contentCardImage} {...rest} />;

  return null;
};

export { ContentCard };
