import { useState } from "react";
import { useElementContext } from "../../contexts";

const ImageGallery = (props) => {
  const {
    CommonModule: { Button, Modal },
  } = useElementContext();
  const [selectedImage, setSelectedImage] = useState(null);
  const { contentElementName, innerElements, theme, customStyleClasses } = props;

  const [filterImagesDataList, setfilterImagesDataList] = useState(getImagesData());

  const ids = innerElements.map((o) => o.categoryName);
  const filtered = innerElements.filter(({ categoryName }, index) => !ids.includes(categoryName, index + 1));

  function getImagesData() {
    const getImagesDataList = innerElements;
    return getImagesDataList;
  }

  function filterImagesData(pokeType) {
    let filterImagesDataList = getImagesData().filter((type) => type.categoryName === pokeType);
    return filterImagesDataList;
  }

  function handleImagesData(value) {
    let typeImage = value;
    typeImage !== "all"
      ? setfilterImagesDataList(filterImagesData(typeImage))
      : setfilterImagesDataList(getImagesData());
  }

  const BuildList = ({ catgory, idx }) => {
    const content = innerElements
      .filter((o) => o.categoryName === catgory)
      .map((o, index) => (
        <div key={idx} className={`g-col-12 g-col-sm-6 g-col-lg-4`}>
          <div className="galleryBox">
            <Button
              onClick={() => {
                setSelectedImage(o);
              }}
              classList="border-0 bg-transparent"
              data-lightbox="gallery-set1"
            >
              <img
                alt=""
                className="galleryImage  p-3 border"
                src={o?.imagePath}
                width="100%"
                style={{ maxHeight: "300px" }}
              />
            </Button>
          </div>
        </div>
      ));

    return (
      <>
        <div key={idx} className={`g-col-12 g-col-sm-12 g-col-lg-12`}>
          <h5 className="text-black mb-3">
            <b>{catgory}</b>
          </h5>
          <div className="justify-content grid ">{content}</div>
        </div>
      </>
    );
  };

  return (
    <div className={`wrapper-container px-0 ${customStyleClasses}`}>
      <div className="row px-4">
        {!!contentElementName && <h2 className="text-black float-center py-4">{contentElementName}</h2>}

        <div className="inner-container">
          <div className="justify-content grid ">
            {theme === "Standard" && (
              <>
                {innerElements?.map((innerElement, idx) => {
                  // const attributeName = 'imagePath'
                  return (
                    <div key={idx} className={`g-col-12 g-col-sm-6 g-col-lg-4 ${innerElement.categoryName}`}>
                      <div className="galleryBox">
                        <Button
                          onClick={() => {
                            setSelectedImage(innerElement);
                          }}
                          classList="border-0 bg-transparent"
                          data-lightbox="gallery-set1"
                        >
                          <img
                            alt=""
                            className="galleryImage  p-3 border"
                            src={innerElement?.imagePath}
                            width="100%"
                            style={{ maxHeight: "300px" }}
                          />
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </>
            )}

            {theme === "Filters" && (
              <>
                <div className="g-col-12 g-col-sm-12 g-col-lg-12">
                  <div className="categoryRow">
                    <Button
                      key="0"
                      value="all"
                      onClick={() => handleImagesData("all")}
                      classList="btn btn-primary btn-block me-2"
                    >
                      All
                    </Button>
                    {filtered &&
                      filtered.map((type, index) => (
                        <>
                          <Button
                            key={index}
                            value={type.categoryName}
                            onClick={() => handleImagesData(type.categoryName)}
                            classList="btn btn-primary btn-block me-2"
                          >
                            {type.categoryName}
                          </Button>
                        </>
                      ))}
                  </div>
                </div>

                {filterImagesDataList &&
                  filterImagesDataList.map((type, i) => (
                    <div key={i} className={`g-col-12 g-col-sm-6 g-col-lg-4 ${type.categoryName}`}>
                      <div className="galleryBox">
                        <Button
                          onClick={() => {
                            setSelectedImage(type);
                          }}
                          classList="border-0 bg-transparent"
                          data-lightbox="gallery-set1"
                        >
                          <img
                            alt=""
                            className="galleryImage  p-3 border"
                            src={type?.imagePath}
                            width="100%"
                            style={{ maxHeight: "300px" }}
                          />
                        </Button>
                      </div>
                    </div>
                  ))}
              </>
            )}

            {theme === "Grouped" &&
              filtered &&
              filtered.map((type, index) => <BuildList catgory={type.categoryName} idx={index} />)}
          </div>
          <Modal
            show={!!selectedImage}
            setShow={() => {
              setSelectedImage(null);
            }}
            title=""
            modalClass="imageGalleryModal"
            size="large"
          >
            <div className="container p-0">
              <img className="imageGalleryModal-image" src={selectedImage?.imagePath} alt="" />
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export { ImageGallery };
