import { ReactElement, ReactNode } from "react";

export interface FooterProps {
  preFooter: any[];
  children: ReactElement[];
}

export class FooterModel {
  preFooter: any[] = [];
  ribbonMenu: ReactNode = null;
  logo: string = "";
  theme: any;
  contentStack: ReactElement[] = [];
  formElement?: ReactElement | null;

  constructor({ preFooter, ribbonMenu, logo, theme, contentStack, formElement }: FooterModel) {
    this.preFooter = preFooter;
    this.ribbonMenu = ribbonMenu;
    this.logo = logo;
    this.theme = theme;
    this.contentStack = contentStack;
    this.formElement = formElement;
  }
}
