import { PropsWithChildren } from "react";
import { useComponentData } from "./Header.componentService";
import { HeaderView } from "./Header.componentView";

const Header = (props: PropsWithChildren) => {
  const { componentData } = useComponentData(props);
  return <HeaderView componentData={componentData} />;
};

export { Header };
