import { useState, useEffect, useMemo } from "react";
import { axios } from "../../../services";
import { toast } from "react-toastify";
import { ProductFactory } from "../../../factories/ProductFactory";
import { getErrorMessage } from "../../../utils";
import { useServiceContext } from "../../../contexts";

export const useGetProductByUrlTitle = (urlTitle: any, lowestPriceCheck = false) => {
  let source = axios.CancelToken.source();
  let [isFetching, setFetching] = useState(true);

  let [data, setData] = useState<ProductFactory>(new ProductFactory());
  let [error, setError] = useState({ isError: false, message: "" });

  const { ProductService } = useServiceContext();
  const productService = useMemo(() => new ProductService(), [ProductService]);

  useEffect(() => {
    setFetching(true);
    productService.getProduct(urlTitle, { source, processingSettings: { lowestPriceCheck } }).then((res: any) => {
      setData(res);
      if (res.error) {
        setError(res["message"]);
        toast.error(getErrorMessage(res.error));
      }
      setFetching(false);
    });

    return () => {
      source.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlTitle]);

  return { isFetching, error, ...data };
};
