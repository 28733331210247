import { Sku } from "../../../../interface/Product";
import { SearchSku } from "../../../../interface/SearchProduct";

export interface AddProductToListModalProps {
  sku: Sku | SearchSku;
  setListModal: (isOpen: boolean) => void;
}

export class AddProductToListModalModel {
  existingList: { key: string; value: string }[] = [];
  existingListVal: string = "";
  isLoading: boolean = false;
  isLoadingForExisitingList: boolean = false;
  listName: string = "";
  isAuthenticated: boolean = false;

  constructor({
    isLoading,
    isLoadingForExisitingList,
    isAuthenticated,
    existingList,
    existingListVal,
    listName,
  }: {
    isLoading: boolean;
    isAuthenticated: boolean;
    isLoadingForExisitingList: boolean;
    existingList: { key: string; value: string }[];
    existingListVal: string;
    listName: string;
  }) {
    this.isLoading = isLoading;
    this.isAuthenticated = isAuthenticated;
    this.isLoadingForExisitingList = isLoadingForExisitingList;
    this.existingList = existingList;
    this.existingListVal = existingListVal;
    this.listName = listName;
  }
}
