import { CSSProperties, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useElementContext } from "../../contexts";
import { getCategoryRoute } from "../../selectors";

export interface CategoryTileProps {
  customStyleClasses?: string;
  cardStyle: "Text over image" | "Text below image";
  itemPreRowDesktop: number;
  itemPreRowTablet: number;
  itemPreRowMobile: number;
  textOverlayColor: string;
  textOverlayOpacity: number;
  textColor: string;
  title?: string;
  categories: {
    categoryLogo?: string;
    categoryName?: string;
    categoryDescription?: string;
    linkedPimCategory: string;
  }[];
}

const MAX_ROW_PRE_PAGE = 4;

const CategoryTile = ({
  customStyleClasses,
  title,
  categories,
  itemPreRowDesktop,
  itemPreRowMobile,
  itemPreRowTablet,
  textOverlayColor,
  textOverlayOpacity,
  textColor,
  cardStyle,
}: CategoryTileProps) => {
  const {
    CommonModule: { SimpleImage, Truncate, ListingPagination },
  } = useElementContext();
  const categoryRoute = useSelector(getCategoryRoute);

  const [currentPage, setPage] = useState(1);
  const itemsPrePage = itemPreRowDesktop * MAX_ROW_PRE_PAGE;
  const totalPage = Math.ceil(categories.length / itemsPrePage);

  const categoriesInPage = useMemo(
    () => categories.slice(itemsPrePage * (currentPage - 1), itemsPrePage * currentPage),
    [categories, itemsPrePage, currentPage],
  );

  if (!categoriesInPage.length) return null;

  return (
    <div
      className={`category-tile ${customStyleClasses || ""}`}
      style={
        {
          "--item-pre-row-desktop": itemPreRowDesktop,
          "--item-pre-row-mobile": itemPreRowMobile,
          "--item-pre-row-tablet": itemPreRowTablet,
          "--text-overlay-color": textOverlayColor,
          "--text-overlay-opacity": textOverlayOpacity,
          "--text-color": textColor,
        } as CSSProperties
      }
    >
      {title && (
        <header className="section-title">
          <h2>{title}</h2>
        </header>
      )}
      <div className="category-tile-list">
        {categoriesInPage.map((category, index) => {
          return (
            <Link to={`/${categoryRoute}/${category.linkedPimCategory}`} className="category-tile-card" key={index}>
              <SimpleImage className="category-tile-card-image" type="category" src={category.categoryLogo} />
              <div
                className={`category-tile-card-content ${
                  cardStyle === "Text over image" ? "category-tile-card-overlay" : ""
                }`}
              >
                <Truncate lineClamp="2" showExpandBtn={false}>
                  <p className="category-tile-card-title">{category.categoryName}</p>
                </Truncate>
              </div>
            </Link>
          );
        })}
      </div>
      <ListingPagination
        recordsCount={categories.length}
        currentPage={currentPage}
        totalPages={totalPage}
        setPage={setPage}
      />
    </div>
  );
};

export { CategoryTile };
