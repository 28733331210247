import { WhereToBuyLocationProps } from "./WhereToBuyLocation.componentModel";
import { useComponentData } from "./WhereToBuyLocation.componentService";
import { WhereToBuyLocationView } from "./WhereToBuyLocation.componentView";

const WhereToBuyLocation = (props: WhereToBuyLocationProps) => {
  const { componentData, componentService } = useComponentData(props);
  return <WhereToBuyLocationView componentData={componentData} {...componentService} />;
};

export { WhereToBuyLocation, type WhereToBuyLocationProps };
