const OrderNotes = ({ isFetching, orderProperties, setOrderProperties }) => {
  return (
    <div className="form-group mb-2">
      <textarea
        className="form-control UpdatePropertyTextArea"
        rows="2"
        disabled={isFetching}
        id="order-comments"
        value={orderProperties.orderNotes}
        placeholder={"Order notes"}
        onChange={(e) => {
          e.preventDefault();
          setOrderProperties({ ...orderProperties, orderNotes: e.target.value });
        }}
      />
    </div>
  );
};
export { OrderNotes };
