import { useTranslation } from "react-i18next";

const CheckPaymentDetails = ({ payment, hideHeading }) => {
  const { paymentMethod } = payment;
  const { t } = useTranslation();

  return (
    <>
      {!hideHeading && <h6 className="h6 fw-bold payment-method-title">{t("frontend.checkout.payment_method")}</h6>}
      <p className="mb-1">
        <em>{paymentMethod.paymentMethodName}</em>
      </p>
    </>
  );
};
export { CheckPaymentDetails };
