import { useUtilities } from "../../../hooks/useUtilities";

const Body = ({ contentBody, systemCode, componentLayout, contentElementBody }) => {
  let { eventHandlerForWSIWYG } = useUtilities();
  return (
    <div
      className={`d-flex mb-3 ${systemCode} ${
        componentLayout === "Half Width"
          ? "col-lg-6 col-12"
          : componentLayout === "Third Width"
          ? "col-lg-4 col-12"
          : componentLayout === "Quarter Width"
          ? "col-lg-3 col-12"
          : "col-12 container"
      }`}
    >
      <div className="w-100 border-0">
        <div className="card-body d-flex flex-column px-0">
          <div
            className="card-text"
            onClick={eventHandlerForWSIWYG}
            dangerouslySetInnerHTML={{
              __html: contentBody || contentElementBody || "",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export { Body };
