import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useGetEntityWithPagination } from "../../hooks";
import { Link } from "react-router-dom";
import { getBrandRoute } from "../../selectors";
import queryString from "query-string";
import { useUtilities } from "../../hooks/useUtilities";
import { useElementContext } from "../../contexts";

const Manufacturer = () => {
  const {
    PageModule: { DynamicPage },
    CommonModule: { ListingPagination, SimpleImage },
  } = useElementContext();
  let { eventHandlerForWSIWYG } = useUtilities();
  const navigate = useNavigate();
  let loc = useLocation();
  const content = useSelector((state) => state.content[loc.pathname.substring(1)]);
  const brandRoute = useSelector(getBrandRoute);
  const { contentBody } = content || {};
  const { maxCount } = useSelector((state) => state.configuration.shopByManufacturer);
  let { currentPage = 1 } = queryString.parse(loc.search, {
    arrayFormat: "separator",
    arrayFormatSeparator: ",",
  });
  let { isFetching, records, totalRecords, totalPages } = useGetEntityWithPagination(
    "brand",
    currentPage,
    maxCount,
    "brandFeatured|desc,brandName|asc",
    '{"includeImages":true}',
  );

  return (
    <DynamicPage>
      <div className="container pb-4 pb-sm-5">
        {!contentBody && (
          <div
            className="content-body"
            onClick={eventHandlerForWSIWYG}
            dangerouslySetInnerHTML={{
              __html: contentBody || "",
            }}
          />
        )}
        <div className="row pt-4">
          {!isFetching &&
            records?.map((brand) => {
              return (
                <div key={brand.brandID} className="col-6 col-md-4 col-lg-3 col-xl-2 d-flex mb-4">
                  <div className="card border-0 w-100">
                    <Link
                      className="d-block overflow-hidden rounded-lg w-100 h-100"
                      to={`/${brandRoute}/${brand.urlTitle}`}
                    >
                      {brand.images && brand.images?.at(0) ? (
                        <SimpleImage
                          className="d-block w-100"
                          src={brand.images && brand.images?.at(0)}
                          alt={brand.brandName}
                          type="brand"
                        />
                      ) : (
                        <div className="d-flex align-items-center justify-content-center w-100 h-100">
                          <h2 className="h4 link my-2 pb-2 mx-2 text-center">{brand.brandName}</h2>
                        </div>
                      )}
                    </Link>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="container pb-4 pb-sm-5">
        <ListingPagination
          recordsCount={totalRecords}
          currentPage={currentPage}
          totalPages={totalPages}
          setPage={(pageNumber) => {
            navigate({
              pathname: loc.pathname,
              search: `?currentPage=${pageNumber}`,
            });
          }}
        />
      </div>
    </DynamicPage>
  );
};

export default Manufacturer;
