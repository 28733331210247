import { AttributeSet, Product } from "../../../../interface/Product";

export interface ProductAttributesProps {
  product: Product & { [key: string]: any };
  attributeSets?: AttributeSet[];
}

export class ProductAttributesModel {
  filteredAttributeSets: AttributeSet[] = [];
  product!: Product & { [key: string]: any };

  constructor({ props: { attributeSets, product } }: { props: ProductAttributesProps }) {
    this.product = product;

    this.filteredAttributeSets = (attributeSets || [])
      .map((set) => {
        return {
          ...set,
          //changes - "typeof product[attr.attributeCode] === 'string'" added for resolving error(.length not a function) on the product details page.
          attributes: set.attributes
            .filter(
              (attr) =>
                attr.attributeCode in product &&
                typeof product[attr.attributeCode] === "string" &&
                product[attr.attributeCode].trim().length > 0,
            )
            .sort((a, b) => a.sortOrder - b.sortOrder),
        };
      })
      .filter((set) => set.attributes.length);
  }
}
