import { useTranslation } from "react-i18next";

import { CartPromoBoxModel } from "./CartPromoBox.componentModel";

const CartPromoBoxView = ({
  componentData: { promoCode, disableInteraction },
  setPromoCode,
  onApplyCode,
}: {
  componentData: CartPromoBoxModel;
  setPromoCode: (code: string) => void;
  onApplyCode: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-sm-12">
          <div className="card mb-4">
            <div className="card-header">
              <h4 className="mb-0 pt-2 pb-2 promotional-title">{t("frontend.cart.promoHeading")}</h4>
            </div>
            <div className="card-body">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onApplyCode();
                }}
              >
                <div className="input-group input-group-lg rounded-pill">
                  <input
                    disabled={disableInteraction}
                    className="form-control appended-form-control rounded-pill"
                    type="text"
                    placeholder="Promo code"
                    value={promoCode}
                    required
                    onChange={(e) => setPromoCode(e.target.value)}
                  />
                  <span className="input-group-append">
                    <button className="btn btn-primary btn-primary-imp" type="submit">
                      {t("frontend.cart.apply")}
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export { CartPromoBoxView };
