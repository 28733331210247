import { useTranslation } from "react-i18next";
import { Option, OptionGroup } from "../../../interface/Product";

export interface OptionDropdownProps {
  optionData: OptionGroup[];
  updateFilters: (optionGroup: OptionGroup, option: Option) => void;
  resetFilters: () => void;
}

const OptionDropdown = ({ optionData, updateFilters, resetFilters }: OptionDropdownProps) => {
  //new component - Display of product variants as dropdowns
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column">
      {optionData.length > 0 && (
        <button className="btn link-btn" onClick={resetFilters}>
          Clear
        </button>
      )}
      {optionData.length > 0 &&
        optionData.map((optionGroup) => {
          const { optionGroupName, options, optionGroupID } = optionGroup;
          const selectedOptionCode = options.find((option) => option.active)?.optionCode || "select";
          return (
            <div className="form-group pe-4 mb-4" key={optionGroupID}>
              <div className="d-flex justify-content-between align-items-center pb-1">
                <label className="font-weight-medium" htmlFor={optionGroupID}>
                  {optionGroupName}
                </label>
              </div>
              <select
                className="custom-select rounded-3 w-50"
                required
                value={selectedOptionCode}
                id={optionGroupID}
                onChange={(e) => {
                  updateFilters(optionGroup, options.find((option) => option.optionCode === e.target.value)!);
                }}
              >
                {selectedOptionCode === "select" && (
                  <option className={`option nonactive`} value="select">
                    {t("frontend.product.select")}
                  </option>
                )}
                {options &&
                  options.map((option) => {
                    return (
                      <option
                        className={`option ${option.active ? "active" : "nonactive"}`}
                        key={option.optionID}
                        value={option.optionCode}
                        disabled={!option.productCount}
                      >
                        {option.optionName}
                      </option>
                    );
                  })}
              </select>
            </div>
          );
        })}
    </div>
  );
};

export { OptionDropdown };
