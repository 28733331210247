import { Suspense, memo, useMemo } from "react";
import { Routes as RouterRoutes, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useSelector } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import { useContentContext, useElementContext, useServiceContext } from "./contexts";

const Routes = Sentry.withSentryReactRouterV6Routing(RouterRoutes);

//https://itnext.io/react-router-transitions-with-lazy-loading-2faa7a1d24a
function App() {
  const pageData = useContentContext();

  const {
    PageModule,
    CommonModule: { Loading },
  } = useElementContext();

  const { RouteService } = useServiceContext();
  const state = useSelector((state) => state);
  const routeService = useMemo(() => new RouteService(), [RouteService]);
  const routes = useMemo(() => routeService.genRoutes(state), [routeService, state]);

  if (pageData?.contentID === null || pageData?.contentID === undefined) return null;

  return (
    <Suspense fallback={<Loading />}>
      <ErrorBoundary
        FallbackComponent={PageModule.ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <Routes>
          {routes.map((route, index) => {
            const PageComponent = PageModule[route.elementKey];
            return <Route key={index} path={route.path} element={<PageComponent />} {...route.props} />;
          })}
        </Routes>
      </ErrorBoundary>
    </Suspense>
  );
}

export default memo(App);
