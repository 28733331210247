import { useMemo } from "react";
import { useContentContext } from "../../../contexts/ContentContext";
import { dynamicAreaLayoutEngine } from "../../../services/factory/Factory_Content_LayoutEngine.class";
import { useElementContext } from "../../../contexts";

const AccountOverview = () => {
  const pageData = useContentContext();
  const {
    CommonModule: { AccountLayout, DynamicContent },
  } = useElementContext();
  const { overviewDynamicContent = [] } = pageData.templateData;

  const contentElements = useMemo(() => {
    const pageLayoutEngine = new dynamicAreaLayoutEngine();
    return pageLayoutEngine.processPageComponents(overviewDynamicContent);
  }, [overviewDynamicContent]);

  return (
    <AccountLayout>
      <DynamicContent contentElements={contentElements} />
    </AccountLayout>
  );
};

export { AccountOverview };
