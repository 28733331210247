import { Location } from "../../../interface/Location";
import { Link } from "react-router-dom";
import { useElementContext } from "../../../contexts";

export interface WhereToBuyLocationCardProps extends Location {
  onLocationClicked: (addressLatitude: string, addresslongitude: string) => void;
}

export const WhereToBuyLocationCard = (props: WhereToBuyLocationCardProps) => {
  const { LocationModule } = useElementContext();
  const {
    locationId,
    WebsiteURL,
    CustomerName,
    FaxNo,
    AddressLatitude,
    AddressLongitude,
    PhoneNo,
    locationType,
    PhysicalAddress1,
    PhysicalCity,
    PhysicalPostcode,
    PhysicalState,
    email,
    onLocationClicked,
  } = props;

  return (
    <div
      className="card h-100"
      onClick={() => {
        onLocationClicked(AddressLatitude, AddressLongitude);
      }}
    >
      <div className="card-body">
        <Link to={`/location/${locationId}`} style={{ display: "inline-block" }}>
          <h5 className="card-title text-uppercase">{CustomerName}</h5>
        </Link>
        <LocationModule.CoreControl.LocationDetail
          address={PhysicalAddress1}
          city={PhysicalCity}
          postcode={PhysicalPostcode}
          locationType={locationType}
          websiteURL={WebsiteURL}
          faxNo={FaxNo}
          phoneNo={PhoneNo}
          state={PhysicalState}
          email={email}
        />
      </div>
    </div>
  );
};
