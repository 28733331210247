import { useEffect, useRef } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { useSelector } from "react-redux";

export interface LocationMapProps {
  locations: { lat: number; lng: number; title: string; address: string; phoneNo: string }[];
  center?: { lat: number; lng: number };
  zoom?: number;
}

export const LocationMap = (props: LocationMapProps) => {
  const theme = useSelector((state: any) => state.configuration.site.theme);
  const apiKey = theme.mapsBaseConfig?.apiKey;
  if (!apiKey) return null;

  return (
    <Wrapper apiKey={apiKey}>
      <LocationMapView
        {...props}
        zoom={props.zoom || theme.mapsBaseConfig?.defaultZoom}
        center={
          props.center || {
            lat: parseFloat(theme.mapsBaseConfig?.mapCenter?.addressLatitude),
            lng: parseFloat(theme.mapsBaseConfig?.mapCenter?.addressLongitude),
          }
        }
      />
    </Wrapper>
  );
};

export const LocationMapView = ({ center, locations, zoom = 4 }: LocationMapProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const map = useRef<any>(null);

  useEffect(() => {
    if (map.current) return;
    map.current = new (window as any).google.maps.Map(ref.current, {
      center,
      zoom,
    });
  }, [locations, center, zoom]);

  useEffect(() => {
    map.current?.panTo(center);
  }, [center]);

  useEffect(() => {
    map.current?.setZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    if (!map.current) return;
    const infoWindow = new (window as any).google.maps.InfoWindow(); //static infoWindow for all your markers
    const markers = locations.map(({ lat, lng, title, address, phoneNo }) => {
      let marker = new (window as any).google.maps.Marker({
        position: { lat, lng },
        map: map.current,
      });

      marker.addListener("click", () => {
        map.current.panTo({ lat, lng });
        infoWindow.setContent(`<div><div><b>${title}</b></div> <div>${address}</div>  <div>${phoneNo}</div> </div>`);
        infoWindow.open({
          anchor: marker,
          map: map.current,
          shouldFocus: false,
        });
      });
      return marker;
    });

    return () => {
      markers.forEach((marker) => marker.setMap(null));
    };
  }, [locations]);

  return <div ref={ref} style={{ height: "100%" }}></div>;
};
