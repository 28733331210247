import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { useFormatDate, useUtilities } from "../../hooks";
import { getBlogRoute } from "../../selectors";
import { getSdkURL } from "../../services";

const BlogListBody = ({ blog }) => {
  const [imageIsMissing, setImageIsMissing] = useState(false);
  const host = getSdkURL();
  const navigate = useNavigate();
  const [formateDate] = useFormatDate();
  const { t } = useTranslation(); // Translate
  let { eventHandlerForWSIWYG } = useUtilities();
  const blogPath = useSelector(getBlogRoute);

  return (
    <article className="shadow mb-5 blog-listing-item bg-white">
      {!imageIsMissing && blog.postImage?.url && (
        <div className="d-flex justify-content-center">
          {blog.postImage && (
            <img
              src={`${host}${blog.postImage.url}`}
              alt={blog.postTitle}
              onError={() => {
                setImageIsMissing(true);
              }}
            />
          )}
        </div>
      )}
      <h2 className="entry-title px-4 py-3 m-0">
        <Link className="link text-dark" to={`/${blogPath}/${blog.slug}`}>
          {blog.postTitle}
        </Link>
      </h2>

      <div className="entry-meta px-4">
        <ul className="list-unstyled d-flex m-0">
          {!!blog.authorName && (
            <li className="d-flex align-items-center me-3">
              <i className="bi bi-person me-2 small line-height-0"></i>
              {blog.authorName}
            </li>
          )}
          {!!blog.publicationDate && (
            <li className="d-flex align-items-center me-3">
              <i className="bi bi-clock me-2 small line-height-0"></i>
              {formateDate(blog.publicationDate)}
            </li>
          )}
        </ul>
      </div>
      <div className="entry-content pt-2 px-4 pb-4">
        {!!blog.postSummary && (
          <p onClick={eventHandlerForWSIWYG} dangerouslySetInnerHTML={{ __html: blog.postSummary }} />
        )}
        <button
          className="btn btn-primary btn-block"
          onClick={(e) => {
            e.preventDefault();
            navigate(`/${blogPath}/${blog.slug}`);
          }}
        >
          {t("frontend.blog.readMore")}
        </button>
      </div>
    </article>
  );
};

export { BlogListBody };
