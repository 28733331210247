import { Dispatch, SetStateAction, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-use";
import { useElementContext } from "../../../../contexts";
import { MiniCartModel } from "./MiniCart.componentModel";

export const MiniCartView = ({
  componentData: { cartList, color, miniCartOpen, cart, quoteModal, miniCartStyle, miniCartShowQuote, miniCartSize },
  setMiniCartOpen,
  setQuoteModal,
}: {
  componentData: MiniCartModel;
  setMiniCartOpen: Dispatch<SetStateAction<boolean>>;
  setQuoteModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    ProductModule,
    CommonModule: { MiniCartItem },
  } = useElementContext();

  const wrapperRef = useRef<HTMLDivElement>(null);

  //Changes - line 59 and 60 the Cart icon is only displayed when more than 992 px and when resized less than 992px, only the cart text is displayed in the drawer
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setMiniCartOpen(false);
      }
    }
    if (miniCartOpen) document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [miniCartOpen, setMiniCartOpen]);

  return (
    <div ref={wrapperRef} className="ms-2 nav-item dropdown align-self-lg-center">
      <span
        onClick={() => {
          setMiniCartOpen((prevState) => !prevState);
        }}
        id="navbarDropdown"
        style={{ color }}
        className={`cart position-relative text-center clickable ${miniCartOpen && "show"} ${
          location?.pathname === "/shopping-cart" && `active`
        }`}
      >
        {cart.totalItemQuantity > 0 && (
          <i className="small position-absolute top-1 start-100 translate-middle badge rounded-pill bg-primary cart-number-badge">
            {cart.totalItemQuantity}
          </i>
        )}
        <i className="bi bi-cart3 fs-4"></i>
        <span className="d-none cart-icon-text">{t("frontend.header.cart")}</span>
      </span>
      <div
        className={`cart-dropdown-container dropdown-menu dropdown-menu-end p-0 border-0 shadow-lg 
        ${miniCartStyle === "Sidepanel(left)" ? "sidepanel left" : ""} 
        ${miniCartStyle === "Sidepanel(right)" ? "sidepanel right" : ""} 
        ${miniCartOpen && "show"}`}
        style={{ width: miniCartSize }}
      >
        <div className="accordion cart-dropdown" id="miniCartAccordion">
          {cartList.map((cartItem) => (
            <MiniCartItem
              key={cartItem.id}
              isSingleCart={cartList.length === 1}
              setMiniCartOpen={setMiniCartOpen}
              cartItem={cartItem}
            />
          ))}
          {miniCartShowQuote && (
            <div className="accordion-item mt-auto">
              <div className="accordion-body bg-light">
                <div className="fw-bold link text-center" onClick={() => setQuoteModal((prevState) => !prevState)}>
                  {cartList.length > 1 ? "Add another quote" : "Create quote"}
                </div>
              </div>
              {quoteModal && <ProductModule.CoreComponents.AddProductToQuoteModal setQuoteModal={setQuoteModal} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
