import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestCart, receiveCart } from "../../../../actions";
import { ProductBundleModel, ProductBundleProps } from "./ProductBundle.componentModel";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getErrorMessage, getFailureMessageOnSuccess } from "../../../../utils";
import { axios, getSdkURL, SlatwalApiService } from "../../../../services";

const useComponentData = (props: ProductBundleProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [bundlesOnAccount, setBundlesOnAccount] = useState<{ [key: string]: any }>({});
  const [bundleBuildsOnAccount, setBundleBuildsOnAccount] = useState(props.productBundleBuildOnAccount);

  const componentData = new ProductBundleModel({ props, bundleBuildsOnAccount, bundlesOnAccount });

  useEffect(() => {
    if (bundleBuildsOnAccount) {
      let skuIDs = [];
      skuIDs = bundleBuildsOnAccount?.bundleItems.map((item) => item.sku_skuID);

      axios({
        method: "GET",
        withCredentials: true,
        url: `${getSdkURL()}api/public/sku`,
        params: { "f:skuID:eq": skuIDs.join(",") },
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response: any) => {
        if (response && !response.data?.failureActions.length) {
          if (response.data.data.pageRecords.length) {
            let obj: { [key: string]: any } = {};
            response.data.data.pageRecords.map((item: { skuID: string }) => (obj[item.skuID] = item));
            bundleBuildsOnAccount?.bundleItems.map((item) => {
              if (`${item.sku_skuID}` in obj) {
                obj[item.sku_skuID].quantity = item.quantity;
                obj[item.sku_skuID].productBundleBuildItemID = item?.productBundleBuildItemID;
              }
              return obj;
            });
            setBundlesOnAccount(obj);
          }
        }
      });
    }
  }, [bundleBuildsOnAccount]);

  const addToCart = useCallback(() => {
    dispatch(requestCart());
    const payload = {
      productBundleBuildID: componentData.bundleBuildsOnAccount?.productBundleBuildID,
      returnJSONObjects: "cart",
    };

    SlatwalApiService.cart.addProductBundleBuild(payload).then((response: any) => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length)
        toast.error(getErrorMessage(response.success().errors));
      getFailureMessageOnSuccess(response, getErrorMessage(response.success().messages));
      if (response.isSuccess() && response.success()?.successfulActions.length > 0) {
        toast.success(t("frontend.product.productBundle.bundle_build_added_to_cart"));
        dispatch(receiveCart(response.success().cart));
        setBundlesOnAccount({});
      } else {
        dispatch(receiveCart({}));
      }
    });
  }, [componentData.bundleBuildsOnAccount?.productBundleBuildID, dispatch, t]);

  const removeProductBundleBuild = useCallback(() => {
    const payload = {
      productBundleBuildID: componentData.bundleBuildsOnAccount?.productBundleBuildID,
    };

    SlatwalApiService.products.removeBundleBuild(payload).then((response: any) => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length)
        toast.error(getErrorMessage(response.success().errors));
      if (response.isSuccess()) {
        toast.success(t("frontend.product.productBundle.bundle_build_deleted"));
        setBundlesOnAccount({});
      }
    });
  }, [componentData.bundleBuildsOnAccount?.productBundleBuildID, t]);

  const removeProductBundleItem = useCallback(
    (productBundleBuildItemID: string) => {
      const payload = {
        productBundleBuildItemID: productBundleBuildItemID,
      };

      axios({
        method: "POST",
        withCredentials: true,
        url: `${getSdkURL()}api/scope/removeProductBundleBuildItem`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response: any) => {
        if (response && !response.data?.failureActions.length) {
          let items = Object.values(bundlesOnAccount).filter(
            (item: any) => item.productBundleBuildItemID !== productBundleBuildItemID,
          );
          setBundlesOnAccount(items);
        } else {
          toast.error(t("frontend.product.productBundle.bundle_build_item_deletion_failure"));
        }
      });
    },
    [bundlesOnAccount, t],
  );

  return {
    componentData,
    componentService: {
      setBundleBuildsOnAccount,
      addToCart,
      removeProductBundleBuild,
      removeProductBundleItem,
    },
  };
};

export { useComponentData };
