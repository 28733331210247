import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { isExternalURL } from "../../utils";
import { PropsWithChildren } from "react";

export interface ExternalLinkProps extends Omit<LinkProps, "to" | "href"> {
  linkUrl: string;
}

const ExternalLink = ({ linkUrl, children, ...rest }: PropsWithChildren<ExternalLinkProps>) => {
  return isExternalURL(linkUrl) ? (
    <a href={linkUrl} target="_blank" rel="noreferrer noopener" {...rest}>
      {children}
    </a>
  ) : (
    <Link to={linkUrl} {...rest}>
      {children}
    </Link>
  );
};

export default ExternalLink;
