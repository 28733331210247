import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export const MobileNav = ({
  filteredMenus = [],
  megaMenus = [],
}: {
  filteredMenus: ReactNode[];
  megaMenus: ReactNode[];
}) => {
  const { t } = useTranslation();

  return (
    <div className="collapse navbar-collapse main-nav no-print" id="navbarCollapse">
      {/*  Mobile */}
      <div className="d-lg-none mobile-nav">
        <div className="mobile-nav-header">
          <h2 className="border-bottom">{t("frontend.nav.menu")}</h2>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="bi bi-x-circle"></i>
          </button>
        </div>
        {[...filteredMenus, ...megaMenus]}
      </div>
      {/*  default */}
      <div className="d-none d-lg-block desktop-nav">{megaMenus}</div>
    </div>
  );
};
