const initState = {};

const cartQuoteReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_CART_OR_QUOTE":
      return { ...action };
    default:
      return { ...state };
  }
};

export { cartQuoteReducer };
