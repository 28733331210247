const FormSelectField = ({
  attributeName,
  attributeCode,
  requiredFlag,
  options,
  setValue,
  value,
  invalid,
  validationMessage,
}) => {
  return (
    <fieldset>
      <div className={`form-label ${requiredFlag ? "required" : ""}`}>{attributeName}</div>
      <select
        name={attributeCode}
        value={value}
        onChange={(e) => {
          e.preventDefault();
          setValue((response) => {
            return {
              ...response,
              [attributeCode]: e.target.value,
            };
          });
        }}
      >
        {options.map((option, idx) => {
          return (
            <option key={idx} value={option.value}>
              {option.name}
            </option>
          );
        })}
      </select>
      {invalid && <div className="invalid-feedback">{validationMessage ?? ""}</div>}
    </fieldset>
  );
};

export default FormSelectField;
