import { PropsWithChildren, createContext, useContext } from "react";

import { ProductService, StorefrontContentService, RouteService, LogService } from "../services";

const defaultServices = {
  StorefrontContentService,
  ProductService,
  RouteService,
  LogService,
};

const ServiceContext = createContext<typeof defaultServices>(defaultServices);

const ServiceContextProvider = ({
  children,
  customServices = {},
}: PropsWithChildren<{
  customServices?: Partial<typeof defaultServices>;
}>) => {
  return (
    <ServiceContext.Provider value={{ ...defaultServices, ...customServices }}>{children}</ServiceContext.Provider>
  );
};

const useServiceContext = () => {
  // get the context
  const context = useContext(ServiceContext);
  // if `undefined`, throw an error
  if (!context === undefined) {
    throw new Error("useServiceContext was used outside of its Provider");
  }

  return context;
};

/*
 * we return ServiceContext because a user may want to bypass the API call if th
 * user got the Content data from another spot and wants to Hydrate Manually.
 */
export { ServiceContext, ServiceContextProvider, useServiceContext };
