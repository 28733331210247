import { SkuSelectorProps } from "./SkuSelector.componentModel";
import { SkuSelectorView } from "./SkuSelector.componentView";
import { useComponentModel } from "./SkuSelector.componentService";

const SkuSelector = (props: SkuSelectorProps) => {
  const { componentData, componentService } = useComponentModel(props);

  return <SkuSelectorView viewData={componentData} onSelectorChange={componentService.onSelectorChange} />;
};
export { SkuSelector, type SkuSelectorProps };
