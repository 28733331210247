import { useTranslation } from "react-i18next";
import { useCheckoutUtilities } from "../../../hooks";
import { useState } from "react";
import { useElementContext } from "../../../contexts";

const FulfillmentList = ({
  orderFulfillments,
  orderItems,
  canSwitchFulfillmentMethod = false,
  eligibleFulfillmentMethods,
  pickupLocations,
  selectedFulfillmentMethod,
  onChangeOrderFullfillment,
  onShipmentSelect,
  onShipmentSave,
  onSelectShippingMethod,
  onChangeDate,
  onChangeLocation,
}) => {
  const {
    CommonModule: { ShippingFulfillment, PickupFulfillment, FulfillmentPicker, AutoFulfillment },
  } = useElementContext();
  const { SHIPPING_CODE, PICKUP_CODE, AUTO_CODE } = useCheckoutUtilities();
  const [changeOrderFulfillment, setChangeOrderFulfillment] = useState(false);
  const { t } = useTranslation();
  if (orderFulfillments.length === 0) {
    return null;
  }
  return (
    <>
      {!changeOrderFulfillment &&
        orderFulfillments.map((fulfillment) => {
          return (
            <div className="row mx-0 pb-4" key={fulfillment.orderFulfillmentID}>
              <FulfillmentPicker
                onSelect={() => {
                  setChangeOrderFulfillment(false);
                }}
                orderItems={orderItems}
                eligibleFulfillmentMethods={eligibleFulfillmentMethods}
                onChangeOrderFullfillment={(fulfillmentMethodID, orderItemIDList) => {
                  onChangeOrderFullfillment(fulfillmentMethodID, orderItemIDList);
                }}
                selectedFulfillmentMethod={selectedFulfillmentMethod}
              />
              <div className="bg-lightgray rounded col p-3 mt-4">
                {fulfillment.fulfillmentMethod.fulfillmentMethodType === SHIPPING_CODE && (
                  <ShippingFulfillment
                    fulfillment={fulfillment}
                    orderItems={orderItems}
                    allowSwith={canSwitchFulfillmentMethod}
                    onShipmentSelect={onShipmentSelect}
                    onShipmentSave={onShipmentSave}
                    onSelectShippingMethod={onSelectShippingMethod}
                  />
                )}
                {fulfillment.fulfillmentMethod.fulfillmentMethodType === PICKUP_CODE && (
                  <PickupFulfillment
                    fulfillment={fulfillment}
                    orderItems={orderItems}
                    allowSwith={canSwitchFulfillmentMethod}
                    onChangeDate={onChangeDate}
                    onChangeLocation={onChangeLocation}
                    pickupLocations={pickupLocations}
                  />
                )}
                {fulfillment.fulfillmentMethod.fulfillmentMethodType === AUTO_CODE && (
                  <AutoFulfillment
                    fulfillment={fulfillment}
                    orderItems={orderItems}
                    allowSwith={canSwitchFulfillmentMethod}
                  />
                )}
              </div>
            </div>
          );
        })}
      {changeOrderFulfillment && (
        <div className="row ">
          <FulfillmentPicker
            onSelect={() => {
              setChangeOrderFulfillment(false);
            }}
            orderItems={orderItems}
            eligibleFulfillmentMethods={eligibleFulfillmentMethods}
            onChangeOrderFullfillment={(fulfillmentMethodID, orderItemIDList) => {
              onChangeOrderFullfillment(fulfillmentMethodID, orderItemIDList);
            }}
            selectedFulfillmentMethod={selectedFulfillmentMethod}
          />
          <div className="col-sm-6">
            <button className="btn btn-secondary mx-2" type="button" onClick={() => setChangeOrderFulfillment(false)}>
              {t("frontend.core.cancel")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export { FulfillmentList };
