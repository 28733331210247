import { useElementContext } from "../../../contexts";

const ContentLayout = (props) => {
  const {
    CommonModule: {
      ContentLayoutCSSBlock,
      ContentLayoutCSSGrid,
      ContentLayoutCardGrid,
      ContentLayoutCardGroup,
      ContentLayoutCardMasontry,
    },
  } = useElementContext();
  const { layoutViewMode = "css-grid", ...rest } = props;

  switch (layoutViewMode) {
    case "css-grid":
      return <ContentLayoutCSSGrid {...rest} />;
    case "card-grid":
      return <ContentLayoutCardGrid {...rest} />;
    case "card-group":
      return <ContentLayoutCardGroup {...rest} />;
    case "card-masonry":
      return <ContentLayoutCardMasontry {...rest} />;
    default:
      return <ContentLayoutCSSBlock {...rest} />;
  }
};

export { ContentLayout };
