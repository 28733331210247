import { useElementContext } from "../../contexts";

const Loading = () => {
  const {
    CommonModule: { Footer, Header },
  } = useElementContext();

  return (
    <>
      <Header />
      <div style={{ minHeight: "800px" }}></div>
      <Footer />
    </>
  );
};

export { Loading };
