import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { ProductBundleItemsProps, ProductBundleItemsModel } from "./ProductBundleItems.componentModel";
import { getErrorMessage, getFailureMessageOnSuccess } from "../../../../utils";
import { SlatwalApiService } from "../../../../services";

export const useComponentData = (props: ProductBundleItemsProps) => {
  const { t } = useTranslation();
  const [productItems, setProductItems] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);

  const [isOpen, setIsOpen] = useState(false);

  const addBundleBuild = useCallback(
    (skuID: string, quantity: number) => {
      if (quantity === 0) return;

      const payload = {
        skuIDList: skuID,
        quantities: quantity,
        defaultSkuID: props.productBundleItem.defaultSkuID,
        productBundleGroupID: props.productBundleItem.productBundleGroupID,
      };

      SlatwalApiService.products.createBundleBuild(payload).then((response: any) => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length)
          toast.error(getErrorMessage(response.success().errors));
        getFailureMessageOnSuccess(response, getErrorMessage(response.success().messages));
        if (response.isSuccess() && response.success()?.successfulActions.length > 0) {
          toast.success(t("frontend.product.productBundle.bundle_build_created"));
          if (response.success()?.data?.productBundleBuildOnAccount)
            props.setBundleBuildsOnAccount(response.success().data.productBundleBuildOnAccount);
        }
      });
    },
    [props, t],
  );

  const getProductBundles = useCallback(
    (productBundleGroupID: string, page = currentPage) => {
      const payload = {
        productBundleGroupID: productBundleGroupID,
        productID: props.productID,
        currentPage: page,
        pageRecordsShow: 10,
      };

      SlatwalApiService.products.getBundles(payload).then((response: any) => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length)
          toast.error(getErrorMessage(response.success().errors));
        getFailureMessageOnSuccess(response, getErrorMessage(response.success().messages));
        if (response.isSuccess() && response.success()?.successfulActions.length > 0) {
          if (response.success()?.data) {
            setProductItems(response.success().data?.at(0));
          }
        }
      });
    },
    [currentPage, props.productID],
  );

  const componentData = useMemo(
    () => new ProductBundleItemsModel({ props, currentPage, productItems, isOpen }),
    [currentPage, isOpen, productItems, props],
  );

  return {
    componentData,
    componentService: {
      addBundleBuild,
      getProductBundles,
      setIsOpen,
      setCurrentPage,
    },
  };
};
