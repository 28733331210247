import { Product, Sku } from "../../../../interface/Product";

export interface ProductDetailHeadingProps {
  sku?: Sku;
  product: Product;
  skuLabel: string;
}

export class ProductDetailHeadingModel {
  skuCode?: string;
  linealMeter?: string;
  skuLabel: string;
  constructor({ props: { sku, product, skuLabel } }: { props: ProductDetailHeadingProps }) {
    if (!!sku?.skuCode?.trim()?.length) this.skuCode = sku?.skuCode?.trim();
    if (!!product?.linealMeter?.trim()?.length) this.linealMeter = product.linealMeter;
    this.skuLabel = skuLabel;
  }
}
