import { List } from "../../../../interface/List";
import { Sku } from "../../../../interface/Product";
import { Quote } from "../../../../interface/Quote";
import { isAuthenticated } from "../../../../utils";
import { DisplayConfig } from "../../product.moduleModels";

export interface ProductFormProps {
  sku: Sku;
  showInputLabel: boolean;
  quantityInputMode: DisplayConfig["quantityInputMode"];
  showInventory: DisplayConfig["showInventory"];
  skuConfiguration?: any;
  wishlists?: any;
  showExistingQuoteAndLists?: any;
}

export class ProductFormModel {
  sku!: Sku;
  showInputLabel: boolean = false;
  quantityInputMode: DisplayConfig["quantityInputMode"] = "dropdown";
  showInventory: DisplayConfig["showInventory"] = true;
  showExistingQuoteAndLists: DisplayConfig["showExistingQuoteAndLists"] = true;
  isLoading: boolean = false;
  quotes: Quote[] = [];
  wishlists!: List;
  itemCount: number = 1;
  quoteModal: boolean = false;
  listModal: boolean = false;
  showAddToListBtn: boolean = true;
  authenticationRequiredForAddToCart: boolean = false;
  skuConfiguration?: any;
  clientCustoms?: any;

  constructor({
    props: { sku, skuConfiguration, showInputLabel, quantityInputMode, showInventory, showExistingQuoteAndLists },
    cart,
    wishlists,
    isLoading,
    itemCount,
    quoteModal,
    listModal,
    cartAndQuotes = [],
  }: {
    props: ProductFormProps;
    cart: { isLoading: boolean };
    isLoading: boolean;
    itemCount: number;
    quoteModal: boolean;
    listModal: boolean;
    cartAndQuotes: Quote[];
    wishlists: List;
  }) {
    this.sku = sku;
    this.showInputLabel = showInputLabel;
    this.quantityInputMode = quantityInputMode;
    this.showInventory = showInventory;
    this.skuConfiguration = skuConfiguration;
    this.showExistingQuoteAndLists = showExistingQuoteAndLists;
    this.isLoading = cart.isLoading || isLoading;
    this.quotes = cartAndQuotes.filter((order) => order.orderTypeCode !== "otSalesOrder");
    this.wishlists = wishlists;
    this.itemCount = itemCount;
    this.quoteModal = quoteModal;
    this.listModal = listModal;
    this.showAddToListBtn = !!isAuthenticated();

    this.authenticationRequiredForAddToCart = !!sku?.settings?.skuRequireLoginToAddToCart ? !isAuthenticated() : false;
  }
}
