import { useComponentData } from "./ProductOutOfStock.componentService";
import { ProductOutOfStockProps } from "./ProductOutOfStock.componentModel";
import { ProductOutOfStockView } from "./ProductOutOfStock.componentView";

const ProductOutOfStock = (props: ProductOutOfStockProps) => {
  const { componentData } = useComponentData(props);

  return <ProductOutOfStockView componentData={componentData} />;
};

export { ProductOutOfStock, type ProductOutOfStockProps };
