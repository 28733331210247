import { useContentContext } from "../../contexts/ContentContext";
import { useElementContext } from "../../contexts/ElementContext";

const BlogPost = () => {
  const pageData = useContentContext();
  const {
    PageModule: { DynamicPage },
    CommonModule: { BlogPostBody, BlogPostHeader },
  } = useElementContext();

  const { blogPostData } = pageData.templateData;

  return (
    <DynamicPage ignoreLayout={true}>
      {({ renderDynamicContent }) => (
        <>
          <BlogPostHeader />
          <div className="container my-5">
            {blogPostData && <BlogPostBody blogPostData={blogPostData} />}
            {renderDynamicContent()}
          </div>
        </>
      )}
    </DynamicPage>
  );
};
export { BlogPost };
