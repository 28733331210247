const SocialSiteLink = ({ uri, iconClass, color }: { uri?: string; iconClass?: string; color?: string }) => {
  return (
    <>
      {uri && (
        <a className="me-2" href={uri} style={{ color }} target="_blank" rel="noopener noreferrer">
          <i className={`bi bi-${iconClass} fs-5`}></i>
        </a>
      )}
    </>
  );
};

export { SocialSiteLink };
