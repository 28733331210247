import { ProductBundleModel } from "./ProductBundle.componentModel";
import { ProductBundleBuildOnAccount } from "../../../../interface/Product";
import { useElementContext } from "../../../../contexts";

const ProductBundleView = ({
  componentData,
  setBundleBuildsOnAccount,
  addToCart,
  removeProductBundleBuild,
  removeProductBundleItem,
}: {
  componentData: ProductBundleModel;
  setBundleBuildsOnAccount: (data: ProductBundleBuildOnAccount | undefined) => void;
  addToCart: () => void;
  removeProductBundleBuild: () => void;
  removeProductBundleItem: (productBundleBuildItemID: string) => void;
}) => {
  const { ProductModule } = useElementContext();
  const { bundlesOnAccount, productBundle, productID } = componentData;

  return (
    <>
      <ProductModule.CoreControl.BundleConfig
        bundlesOnAccount={bundlesOnAccount}
        addToCart={addToCart}
        removeProductBundleBuild={removeProductBundleBuild}
        removeProductBundleItem={removeProductBundleItem}
      />
      {productBundle?.map((productBundleItem) => (
        <ProductModule.CoreComponents.ProductBundleItems
          key={productBundleItem.productBundleGroupID}
          productID={productID}
          bundlesOnAccount={bundlesOnAccount}
          productBundleItem={productBundleItem}
          setBundleBuildsOnAccount={setBundleBuildsOnAccount}
        />
      ))}
    </>
  );
};

export { ProductBundleView };
