import { SortOption, PotentialFilters, SearchProduct, SearchSku } from "../interface/SearchProduct";
// import { parseProductDescription } from "./utils";
export class ProductSearchFactory {
  products: SearchProduct[] | SearchSku[] = [];
  potentialFilters: PotentialFilters = {};
  sortBy: SortOption[];
  resultCount: number;
  pageSize: number;

  constructor(data: {
    products: SearchProduct[] | SearchSku[];
    potentialFilters: PotentialFilters;
    sortBy: SortOption[];
    resultCount: number;
    pageSize: number;
  }) {
    this.products = data.products || [];
    // this.products = (data.products || []).map((product) => ({
    //   ...product,
    //   skuDescription: parseProductDescription((product as SearchSku).skuDescription),
    //   productDescription: parseProductDescription((product as SearchProduct).productDescription),
    // })) as SearchSku[] | SearchProduct[];

    this.potentialFilters = data.potentialFilters || {};
    this.sortBy = data.sortBy;
    this.resultCount = data.resultCount;
    this.pageSize = data.pageSize;
  }
}
