import { createContext, useContext } from "react";
import { ProductTypeListProps } from "../../modules/Product/Components/ProductTypeList/ProductTypeList.component";

export interface IBrandContext {
  rangeList?: ProductTypeListProps;
}

export const BrandContext = createContext<IBrandContext>({});

export const useBrandContext = () => {
  const context = useContext(BrandContext);

  if (!context === undefined) {
    throw new Error("useContentContext was used outside of its Provider");
  }

  return context;
};
