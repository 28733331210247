import { useElementContext } from "../../../contexts";
import { ReactNode } from "react";

export const HeaderContentOne = ({
  theme,
  siteSetting,
  filteredMenus = [],
}: {
  filteredMenus: ReactNode[];
  theme: any;
  siteSetting: any;
}) => {
  const {
    CommonModule: { MiniCart, SearchBar },
  } = useElementContext();
  return (
    <div className="d-flex flex-column align-items-end col-xl-8 order-xl-2">
      <div className="d-none d-lg-flex justify-content-end col-9">
        <SearchBar searchBoxTypeaheadFlag redirectToSearchPage searchBoxPlaceholder={theme.searchPlaceholder} />
        <div className="ms-2 d-none d-lg-block">{siteSetting.sitePurpose !== "Non-Transactional" && <MiniCart />}</div>
      </div>

      <div className="text-center text-md-start mb-3 mb-lg-0 order-xl-3 d-none d-lg-block">{filteredMenus}</div>
    </div>
  );
};
