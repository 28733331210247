import { useTranslation } from "react-i18next";
import { ProductOutOfStockModel } from "./ProductOutOfStock.componentModel";

const ProductOutOfStockView = ({
  componentData: { isProductOutOfStock },
}: {
  componentData: ProductOutOfStockModel;
}) => {
  const { t } = useTranslation();

  if (!isProductOutOfStock) return null;
  return (
    <div className="productCard outOfStock">
      <span className="text-accent"> {t("frontend.checkout.stock")}</span>
    </div>
  );
};

export { ProductOutOfStockView };
