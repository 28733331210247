const FormRadioField = ({
  attributeName,
  attributeCode,
  requiredFlag,
  options,
  setValue,
  value,
  invalid,
  validationMessage,
}) => {
  return (
    <fieldset>
      <div className={`form-label ${requiredFlag ? "required" : ""}`}>{attributeName}</div>
      {options.map((option, idx) => {
        return (
          <div className="form-check" key={idx}>
            <input
              onChange={() => {
                setValue((response) => {
                  return {
                    ...response,
                    [attributeCode]: option.value,
                  };
                });
              }}
              value={attributeCode}
              className={"form-check-input" + (invalid ? " is-invalid" : "")}
              type="radio"
              name={attributeCode + option.name}
              checked={option.value === value}
              id={attributeCode + option.name}
            />
            <label className="form-check-label" htmlFor={attributeCode + option.name}>
              {option.name}
            </label>
          </div>
        );
      })}
      {invalid && <div className="invalid-feedback">{validationMessage ?? ""}</div>}
    </fieldset>
  );
};

export default FormRadioField;
