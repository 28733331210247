import { PropsWithChildren, useRef } from "react";

export interface MiniCartAccordionItemProps {
  title: string;
  className?: string;
  isSingleCart: boolean;
  isActive: boolean;
  id: string;
}

export const MiniCartAccordionItem = ({
  title,
  className = "",
  isSingleCart,
  isActive,
  children,
  id,
}: PropsWithChildren<MiniCartAccordionItemProps>) => {
  const accordionId = `cart-panel-${id}`;
  const activeRef = useRef(isActive || isSingleCart);
  return (
    <div className={`accordion-item ${className}`}>
      <h2 className="accordion-header pt-0">
        <button
          className={`accordion-button py-1 ${activeRef.current ? "" : "collapsed"} ${
            isSingleCart ? "accordion-button--hide-icon" : ""
          }`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${accordionId}`}
          aria-expanded="true"
          aria-controls={accordionId}
          disabled={isSingleCart}
        >
          {title}
        </button>
      </h2>
      <div
        id={accordionId}
        className={`accordion-collapse collapse ${activeRef.current ? "show" : ""}`}
        data-bs-parent="#miniCartAccordion"
        aria-labelledby=""
      >
        <div className="accordion-body">{children}</div>
      </div>
    </div>
  );
};
