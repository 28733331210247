import { useSelector } from "react-redux";
import { getSiteConfig, getCurrencies } from "../selectors/configurationSelectors";

const useFormatCurrency = ({ minimumFractionDigits = 2 }) => {
  const { currencyCode } = useSelector(getSiteConfig);
  const currencies = useSelector(getCurrencies);
  const format = (value) => {
    const isNegative = value < 0;
    value = parseFloat(Math.abs(value)).toLocaleString(undefined, {
      minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits,
    });
    const currency = currencies[currencyCode];
    return value ? `${isNegative ? "-" : ""}${currency?.currencySymbol}${value}${currency?.formatMask}` : "";
  };
  return [format];
};
export { useFormatCurrency };
