import { useUtilities } from "../../hooks/useUtilities";
import { useElementContext } from "../../contexts";

const ContentPane = (props) => {
  if (props.viewMode === "vertical") return <VerticalContentPane {...props} />;
  if (props.viewMode === "horizontal") return <HorizontalContentPane {...props} />;
  return null;
};
const VerticalContentPane = (props) => {
  const {
    CommonModule: { ExternalLink },
  } = useElementContext();
  const { eventHandlerForWSIWYG } = useUtilities();
  const {
    siblingCount,
    imagePath,
    linkUrl,
    linkLabel,
    systemCode,
    viewMode,
    contentBody,
    contentHeading,
    stylingBackgroundColor,
    stylingFontColor,
  } = props;

  return (
    <div className={`${systemCode} ${viewMode} col-md-${12 / siblingCount} p-0 d-flex flex-column`}>
      <div className="image" style={{ backgroundImage: `url(${imagePath})` }}></div>
      <h4 style={{ background: stylingBackgroundColor, color: stylingFontColor }}>{contentHeading}</h4>
      <div className="text">
        <div onClick={eventHandlerForWSIWYG} dangerouslySetInnerHTML={{ __html: contentBody }} />
        {!!linkLabel && !!linkUrl && (
          <>
            <br />
            <ExternalLink className="text-link link-carrot" linkUrl={linkUrl}>
              {linkLabel}
            </ExternalLink>
          </>
        )}
      </div>
    </div>
  );
};
const HorizontalContentPane = (props) => {
  const {
    CommonModule: { ExternalLink },
  } = useElementContext();
  const { eventHandlerForWSIWYG } = useUtilities();
  const {
    imagePath,
    linkUrl,
    linkLabel,
    systemCode,
    viewMode,
    contentBody,
    contentHeading,
    stylingBackgroundColor,
    stylingFontColor,
  } = props;

  return (
    <div className={`d-md-flex p-0 ${systemCode} ${viewMode}`}>
      <div className="col-md-6 position-relative p-0">
        <div className="image" style={{ backgroundImage: `url(${imagePath})` }}></div>
      </div>
      <div className="col-md-6 p-0 d-flex flex-column">
        <div>
          <h4 style={{ background: stylingBackgroundColor, color: stylingFontColor }}>{contentHeading}</h4>
        </div>
        <div className="text">
          <div onClick={eventHandlerForWSIWYG} dangerouslySetInnerHTML={{ __html: contentBody }} />
          {!!linkLabel && !!linkUrl && (
            <ExternalLink className="text-link link-carrot" linkUrl={linkUrl}>
              {linkLabel}
            </ExternalLink>
          )}
        </div>
      </div>
    </div>
  );
};

export { ContentPane, VerticalContentPane, HorizontalContentPane };
