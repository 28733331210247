import ExternalLink from "../ExternalLink";

const ActionButton = ({
  linkLabel = "",
  linkUrl = "",
  style,
  bootStrapLayoutClass,
  btn_style,
  urlTarget,
  ctaAlign,
  customStyleClasses,
  componentLayout,
}) => {
  return (
    <div
      className={`py-4 action-button ${style?.toLowerCase()} ${customStyleClasses || ""} ${bootStrapLayoutClass} ${
        componentLayout === "Half Width"
          ? "col-lg-6 col-12"
          : componentLayout === "Third Width"
          ? "col-lg-4 col-12"
          : componentLayout === "Quarter Width"
          ? "col-lg-3 col-12"
          : "col-12 container"
      }`}
    >
      <div className={`text-center align-${ctaAlign}`}>
        {linkUrl ? (
          <ExternalLink
            linkUrl={linkUrl}
            className={`btn ${btn_style}`}
            target={urlTarget}
            {...(urlTarget === "_blank" ? { rel: "noreferrer noopener" } : {})}
          >
            {linkLabel}
          </ExternalLink>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export { ActionButton };
