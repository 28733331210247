import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useElementContext } from "../../../contexts";

const PickupLocationPicker = ({ fulfillment, onChangeDate, onChangeLocation, pickupLocations }) => {
  const {
    CommonModule: { SwRadioSelect },
  } = useElementContext();
  const { t } = useTranslation();
  const custDetails = useSelector((state) => state.userReducer);
  const { pickupLocation } = fulfillment;
  return (
    <>
      <div>
        <h5>{t("frontend.confirmdetails")}</h5>
        <div className="row">
          <div className="me-2 d-flex col-5 flex-wrap">
            <b>{t("frontend.contact.firstName")}: &nbsp;</b>
            <p>{custDetails?.firstName}</p>
          </div>
          <div className="d-flex col-5 flex-wrap">
            <b>{t("frontend.contact.lastName")}: &nbsp;</b>
            <p>{custDetails?.lastName}</p>
          </div>
        </div>
        <div className="row">
          <div className="me-2 d-flex col-5 flex-wrap">
            <b>{t("frontend.account.company")}: &nbsp;</b>
            <p>{custDetails?.company}</p>
          </div>
          <div className="d-flex col-5 flex-wrap">
            <b>{t("frontend.contact.emailAddress")}: &nbsp;</b>
            <p>{custDetails?.primaryEmailAddress.emailAddress}</p>
          </div>
        </div>
        <div className="row">
          <div className="d-flex flex-wrap">
            <b>{t("frontend.contact.phoneNumber")}: &nbsp;</b>
            <p>{custDetails?.primaryPhoneNumber.phoneNumber}</p>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-12">
          {pickupLocations.length > 0 && (
            <SwRadioSelect
              label={t("frontend.checkout.location_option")}
              options={pickupLocations}
              onChange={onChangeLocation}
              selectedValue={pickupLocation?.locationID}
            />
          )}
        </div>
      </div>
    </>
  );
};

export { PickupLocationPicker };
