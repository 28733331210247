import * as SlatwalSDK from "@ultracommerce/javascript-sdk/dist/client/index";
import { getCurrentSiteCode, LocalStorageService } from "../utils";
import { toBoolean } from "../utils";

const getSdkURL = () => `${window.UC?.integrations?.commerce.moduleApiUrl || ""}/`;

let SlatwalApiService = SlatwalSDK.init();

const setupSlatwalApiService = () => {
  SlatwalApiService = SlatwalSDK.init({
    host: getSdkURL(),
  });

  SlatwalApiService.sdkScope.httpService.axios.interceptors.request.use((config) => {
    let localSiteCode = getCurrentSiteCode();
    config.headers["SWX-requestSiteCode"] = localSiteCode;
    if (LocalStorageService.getItem("token")) {
      config.headers["Auth-Token"] = `Bearer ${LocalStorageService.getItem("token")}`;
    }
    return config;
  });
  SlatwalApiService.sdkScope.httpService.axios.interceptors.response.use(
    (successRes) => {
      if (successRes.data.token) {
        LocalStorageService.setItem("token", successRes.data.token);
        if (toBoolean(process?.env?.REACT_APP_CREATE_TOKEN_COOKIE)) {
          const appConfig = LocalStorageService.getItem("appConfiguration");
          document.cookie = "token=" + successRes.data.token + ";path=/";
          if (appConfig?.settings?.siteCookieDomain?.length > 0) {
            document.cookie = "domain = " + appConfig.settings?.siteCookieDomain;
          }
        }
      }
      return successRes;
    },
    (error) => {},
  );
};

export { getSdkURL, SlatwalApiService, setupSlatwalApiService };
