const Ribbon = (props) => {
  const { systemCode, stylingBackgroundColor, stylingFontColor, children } = props;

  return (
    <div
      className={`ribbon ${systemCode || ""}`}
      style={{
        backgroundColor: stylingBackgroundColor,
        color: stylingFontColor,
      }}
    >
      {children}
    </div>
  );
};
export { Ribbon };
