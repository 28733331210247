import { CartPromoBoxView } from "./CartPromoBox.componentView";
import { useComponentData } from "./CartPromoBox.componentService";
import { CartPromoBoxProps } from "./CartPromoBox.componentModel";

const CartPromoBox = (props: CartPromoBoxProps) => {
  const { componentData, componentService } = useComponentData(props);

  return (
    <CartPromoBoxView
      componentData={componentData}
      onApplyCode={componentService.onApplyCode}
      setPromoCode={componentService.setPromoCode}
    />
  );
};
export { CartPromoBox, type CartPromoBoxProps };
