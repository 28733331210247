export class storefront404model {
  contentBody =
    "<p style='text-align:center'>Sorry, but we couldn't find that page. Click <a href='/'> here </a> to return home </p>";
  contentHeading = "Oh no!, you've hit a 404";
  title = "404";
  is404 = true;
  contentID = 404;

  constructor(pageBodyContent = null) {
    console.error("Redirect to 404 Page");
    if (pageBodyContent) this.contentBody = pageBodyContent;
  }
}

export class storefront500model {
  contentBody =
    "<p style='text-align:center'>Sorry, but something has gone very wrong. Try again in a few minutes. </p>";
  contentHeading = "Oh no!, Something is not quite right here.";
  title = "500";
  is404 = true;
  contentID = 500;

  constructor(pageBodyContent = null) {
    console.error("Redirect to 500 Page");
    if (pageBodyContent) this.contentBody = pageBodyContent;
  }
}
