import { useCallback } from "react";
import { ProductBundleItemsProps } from "./ProductBundleItems.componentModel";
import { useComponentData } from "./ProductBundleItems.componentService";
import { ProductBundleItemsView } from "./ProductBundleItems.componentView";

const ProductBundleItems = (props: ProductBundleItemsProps) => {
  const { componentData, componentService } = useComponentData(props);

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      componentService.setIsOpen(isOpen);
      if (isOpen && !componentData.productItems) {
        componentService.getProductBundles(componentData.productBundleItem.productBundleGroupID);
      }
    },
    [componentData.productBundleItem.productBundleGroupID, componentData.productItems, componentService],
  );

  return (
    <ProductBundleItemsView
      componentData={componentData}
      addBundleBuild={componentService.addBundleBuild}
      setCurrentPage={componentService.setCurrentPage}
      getProductBundles={componentService.getProductBundles}
      setIsOpen={setIsOpen}
    />
  );
};

export { ProductBundleItems, type ProductBundleItemsProps };
