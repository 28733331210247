import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { getCart, removeItem, updateItemQuantity } from "../../actions";

import QuoteModel from "./Quote.componentModel";
import { Cart } from "../../interface/Cart";
import { useLocation } from "react-router-dom";

const useComponentData = () => {
  const dispatch = useDispatch();

  const cart: Cart = useSelector((state: any) => state.cart);
  const location = useLocation();
  const { allItems } = queryString.parse(location.search);
  const [isRemovingItem, setRemovingItem] = useState(false);

  useEffect(() => {
    dispatch(getCart() as any);
  }, [dispatch]);

  const onRemoveItem = useCallback(
    async (itemId: string) => {
      const orderItem = cart.orderItems.find((orderItem) => orderItem.orderItemID === itemId);
      if (!orderItem) return;
      setRemovingItem(true);
      await dispatch(removeItem(orderItem) as any);
      setRemovingItem(false);
    },
    [cart.orderItems, dispatch],
  );

  const onUpdateQty = useCallback(
    (orderItemID: string, itemCount: number) => {
      dispatch(updateItemQuantity(orderItemID, itemCount) as any);
    },
    [dispatch],
  );

  const componentData = useMemo(
    () => new QuoteModel({ cart, isRemovingItem, isAllItems: allItems === "1" }),
    [allItems, cart, isRemovingItem],
  );

  return { componentData, componentService: { onRemoveItem, onUpdateQty } };
};

export { useComponentData };
