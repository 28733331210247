export interface HeartButtonProps {
  skuID: string;
  className?: string;
}

export class HeartButtonModel {
  showHeartButton: boolean = true;
  liked: boolean = false;
  primaryColor: string = "";
  className?: string;

  constructor({
    props,
    items,
    isAuthenticated,
    primaryColor,
  }: {
    props: HeartButtonProps;
    items: string[];
    isAuthenticated: boolean;
    primaryColor: string;
  }) {
    this.showHeartButton = isAuthenticated;
    this.liked = items.includes(props.skuID);
    this.className = props.className;
    this.primaryColor = primaryColor;
  }
}
