import { useTranslation } from "react-i18next";
import { useContentContext } from "../../../contexts/ContentContext";
import { useElementContext } from "../../../contexts";

/*
 * TODO: Fix content menu
 */
const AccountSidebar = () => {
  const pageData = useContentContext();
  const {
    CommonModule: { MenuItem },
  } = useElementContext();
  const { t } = useTranslation();
  const { menu } = pageData.templateData;
  return (
    <>
      <div className="col-sm-12 col-md-4 col-lg-3">
        <div className="accountTitle px-2">
          <h3 className="m-0 no-print">{t("frontend.account.myAccount")}</h3>
          <nav className="accountMenu navbar navbar-expand-md navbar-light no-print">
            <button
              className="navbar-toggler mb-4"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse w-100" id="navbarNav">
              <ul className="navbar-nav flex-column w-100">
                {menu?.innerElements?.map((props, idx) => {
                  return <MenuItem key={idx} {...props} />;
                })}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

const MyAccountLayout = ({ children }) => {
  return (
    <>
      <div className="container pb-4 mb-2 mb-md-3 pt-4">
        <div className="row">
          <AccountSidebar />
          <section className="col-sm-12 col-md-8 col-lg-9">{children}</section>
        </div>
      </div>
    </>
  );
};

const PromptLayout = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="mb-5 bg-white col-md-6 py-3">
          <div className="text-center">
            <h1>{t("frontend.account.myAccount")}</h1>
          </div>
          <hr />
          <div className="card-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

const AccountLayout = MyAccountLayout;
export { AccountLayout, PromptLayout };
