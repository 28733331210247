import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetProductsByEntityModified } from "../../../hooks";
import { createSelector } from "reselect";
import { getWishlistsItems } from "../../../selectors";

const getAllFavorites = createSelector(getWishlistsItems, (whishlistItems = []) => {
  return whishlistItems.map(({ sku_skuID }) => sku_skuID);
});

const useAccountFavorites = () => {
  const items = useSelector(getAllFavorites);
  const isListItemsLoaded = useSelector((state) => state.userReducer.wishList.isListItemsLoaded);
  let [productList, setRequest] = useGetProductsByEntityModified();
  let [skuList, setSkuList] = useState(items);
  const [currentPage, setPage] = useState(1);
  const countToDisplay = 6;

  if (isListItemsLoaded && !productList.isFetching && !productList.isLoaded) {
    setSkuList(items);
    setRequest({
      ...productList,
      entity: "product",
      params: { "f:skus.skuID:in": items.join(), "p:show": 250, includeImages: true },
      makeRequest: true,
      isFetching: true,
      isLoaded: false,
    });
  }

  if (skuList.length > 0 && skuList !== items) {
    setSkuList(items);
    setRequest({
      ...productList,
      entity: "product",
      params: { "f:skus.skuID:in": items.join(), "p:show": 250, includeImages: true },
      makeRequest: true,
      isFetching: true,
      isLoaded: false,
    });
  }
  const start = (currentPage - 1) * countToDisplay;
  const end = start + countToDisplay;
  const products = productList.data.slice(start, end);
  const totalPages = Math.ceil(productList.data.length / countToDisplay);
  const isFetching = productList.isFetching;
  return { isFetching, products, totalPages, setPage };
};
export { useAccountFavorites };
