import { useSelector } from "react-redux";
import { checkInvetory } from "../../../selectors";
import { Sku } from "../../../interface/Product";
import { SearchSku } from "../../../interface/SearchProduct";

export interface ProductQuantityInputProps {
  setQuantity: (n: number) => void;
  quantity: number;
  sku: Sku | SearchSku;
  showLabel?: boolean;
  quantityInputType?: "text" | "dropdown";
  showInventory?: boolean;
  dropdownLimitCount?: number;
}

// render quantity input on the basis of config
// renders input on text, dropdown on dropdown
function ProductQuantityInput({
  setQuantity,
  quantity,
  sku,
  showLabel = true,
  quantityInputType = "text",
  showInventory = false,
  dropdownLimitCount = 8,
}: ProductQuantityInputProps) {
  //changes - getting config as a param
  //changes -  extracting the required data from the config
  const stock = sku.calculatedQATS;
  const checkInvetoryFlag = useSelector(checkInvetory);

  const validateQuantity = (value: string) => {
    const newQuantity = parseInt(value, 10);
    if (checkInvetoryFlag) {
      if (newQuantity <= sku.skuOrderMinimumQuantity || newQuantity > sku.skuOrderMaximumQuantity) return;
    }
    setQuantity(newQuantity);
  };

  const calculateDropdownLimit = () => {
    if (checkInvetoryFlag) return sku.calculatedQATS > 20 ? 20 : sku.calculatedQATS;
    return dropdownLimitCount;
  };

  return (
    //changes - style changes for better look
    <div className="d-flex align-items-end gap-4 mb-3">
      <div className="d-flex flex-column">
        {quantityInputType === "text" && (
          <>
            {showLabel && <label>Quantity</label>}
            <input
              type="number"
              min="1"
              onChange={(event) => {
                validateQuantity(event.target.value);
              }}
              value={quantity}
              className="form-control rounded-pill"
              style={{ width: "5rem" }}
            />
          </>
        )}
        {sku && quantityInputType === "dropdown" && (
          <>
            {showLabel && <label>Quantity</label>}
            <select
              value={quantity}
              onChange={(event) => {
                validateQuantity(event.target.value);
              }}
              className="custom-select "
              style={{ width: "5rem" }}
            >
              {Array.from({ length: calculateDropdownLimit() }, (v, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </>
        )}
      </div>
      {showInventory && stock > 0 && (
        //changes - style changes for better look
        <div className="btn border">
          {stock}
          <span className="text-accent"> in Stock</span>
        </div>
      )}
    </div>
  );
}

export { ProductQuantityInput };
