import { updateOrderNotes } from "../../../actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { getErrorMessage } from "../../../utils";
import { useElementContext } from "../../../contexts";

const TradeOrderNotes = ({ orderNotes, orderID, showSummary }) => {
  const {
    CommonModule: { Modal },
  } = useElementContext();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [orderNote, setOrderNote] = useState(orderNotes ? orderNotes : "");
  const [modalOrderNote, setModalOrderNote] = useState("");

  useEffect(() => {
    setOrderNote(orderNotes ? orderNotes : "");
  }, [orderNotes]);

  const handleSubmit = () => {
    dispatch(
      updateOrderNotes({
        orderNotes: modalOrderNote,
        orderID: orderID,
        returnJSONObjects: "orderInfo",
      }),
    ).then((response) => {
      if (response.isSuccess()) {
        if (Object.keys(response.success()?.errors || {}).length) {
          toast.error(getErrorMessage(response.success().errors));
        } else {
          toast.success("Notes have been updated.");
          setShowModal(false);
          setOrderNote(modalOrderNote);
        }
      }
    });
  };

  return (
    <>
      <div className={showSummary ? "" : "alert alertbox"}>
        <p className="small">
          {orderNote === "" && !showSummary ? "Click on the edit icon to add your notes here" : ""}
          {orderNote !== "" ? orderNote : ""} &nbsp;
        </p>
        {!showSummary && (
          <i
            className="bi bi-pencil linkit"
            onClick={() => {
              setShowModal(true);
              setModalOrderNote(orderNote);
            }}
          ></i>
        )}
      </div>
      {showModal && (
        <Modal show={showModal} setShow={setShowModal} title="Order Notes" size="xMedium">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            className="text-center"
          >
            <div className="col-12 d-flex mb-2">
              <div className="col-4">
                <label className="form-label">Order Notes*</label>
              </div>
              <div className="col-8">
                <textarea
                  name="orderNotes"
                  value={modalOrderNote}
                  onChange={(e) => {
                    setModalOrderNote(e.target.value);
                  }}
                  className="form-control"
                ></textarea>
              </div>
            </div>
            <button className="btn btn-primary my-2" type="submit">
              Save
            </button>
          </form>
        </Modal>
      )}
    </>
  );
};

export default TradeOrderNotes;
