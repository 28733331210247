import { addShippingAddressToOrderFulfillment, addShippingAddressUsingAccountAddress } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import { getErrorMessage } from "../../../utils";
import { useElementContext } from "../../../contexts";
import { getAllAccountAddresses } from "../../../selectors";

const TradeOrderAddress = ({ orderID, orderFulfillment, showSummary, getOrderInfo }) => {
  const {
    CommonModule: { BillingAddress, Modal, SwRadioSelect, Overlay },
  } = useElementContext();
  const { t } = useTranslation();
  const accountAddresses = useSelector(getAllAccountAddresses);
  const [address, setAddress] = useState(
    orderFulfillment?.orderFulfillment_shippingAddress_streetAddress
      ? `${orderFulfillment?.orderFulfillment_shippingAddress_streetAddress}, ${
          orderFulfillment?.orderFulfillment_shippingAddress_street2Address
            ? `${orderFulfillment?.orderFulfillment_shippingAddress_street2Address}, `
            : ""
        } ${orderFulfillment?.orderFulfillment_shippingAddress_city}, ${
          orderFulfillment?.orderFulfillment_shippingAddress_postalCode
        }`
      : `Update your delivery address`,
  );
  const [showModal, setShowModal] = useState(false);
  const [isSaveLoading, setSaveLoading] = useState(false);

  const dispatch = useDispatch();

  const saveAddressOnOrder = (values) => {
    if (isSaveLoading) return;
    setSaveLoading(true);
    dispatch(
      addShippingAddressToOrderFulfillment({
        params: {
          orderID: orderID,
          fulfillmentID: orderFulfillment.orderFulfillment_orderFulfillmentID,
          accountAddressName: values.accountAddressName || "",
          city: values?.city,
          countryCode: values?.countryCode,
          emailAddress: values?.emailAddress,
          locality: values?.locality,
          name: values?.name,
          phoneNumber: values?.phoneNumber,
          postalCode: values?.postalCode,
          saveAddress: values?.saveAddress,
          stateCode: values?.stateCode,
          street2Address: values?.street2Address,
          streetAddress: values?.streetAddress,
        },
      }),
    ).then((response) => {
      setSaveLoading(false);
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length)
        toast.error(getErrorMessage(response.success().errors));
      else if (response.isSuccess()) {
        getOrderInfo({ isFetching: false });
        setAddress(
          `${values?.streetAddress}, ${values?.street2Address ? `${values?.street2Address}, ` : ""}  ${values?.city}, ${
            values?.postalCode
          }`,
        );
        toast.success("Address has been saved successfully for order.");
        setShowModal(false);
      }
    });
  };

  const existingAddress = {
    name: orderFulfillment?.orderFulfillment_shippingAddress_name || "",
    countryCode: orderFulfillment?.orderFulfillment_shippingAddress_countryCode || "",
    stateCode: orderFulfillment?.orderFulfillment_shippingAddress_stateCode || "",
    postalCode: orderFulfillment?.orderFulfillment_shippingAddress_postalCode || "",
    locality: orderFulfillment?.orderFulfillment_shippingAddress_locality || "",
    city: orderFulfillment?.orderFulfillment_shippingAddress_city || "",
    streetAddress: orderFulfillment?.orderFulfillment_shippingAddress_streetAddress || "",
    street2Address: orderFulfillment?.orderFulfillment_shippingAddress_street2Address || "",
    phoneNumber: orderFulfillment?.orderFulfillment_shippingAddress_phoneNumber || "",
    emailAddress: orderFulfillment?.orderFulfillment_shippingAddress_emailAddress || "",
  };

  const selectedAddress = accountAddresses.find(({ address }) => {
    return address.addressID === existingAddress.addressID;
  })?.accountAddressID;

  return (
    <>
      <h3 className="h5 mb-0 me-2 font-weight-bold">
        Deliver to:{" "}
        <div className={showSummary ? "mt-2" : "alert alertbox mt-2"}>
          <p className="small fw-normal"> {address}</p>{" "}
        </div>
      </h3>

      {!showSummary && <i className="bi bi-pencil linkit" onClick={() => setShowModal(true)}></i>}

      {showModal && (
        <Modal show={showModal} setShow={setShowModal} title="Address" size="xMedium">
          <Overlay
            active={isSaveLoading}
            spinner
            text="Saving address..."
            styles={{ wrapper: (base) => ({ ...base, position: "static" }) }}
          >
            <SwRadioSelect
              options={accountAddresses.map(({ accountAddressID, address }) => {
                const addressString = ` ${address.streetAddress}, ${address.city}, ${address.stateCode} ${address.postalCode}`;
                const name = address.name ? `${address.name} - ${addressString} ` : addressString;
                return { name, value: accountAddressID };
              })}
              onChange={(value) => {
                if (isSaveLoading) return;
                setSaveLoading(true);
                dispatch(
                  addShippingAddressUsingAccountAddress({
                    orderID: orderID,
                    accountAddressID: value,
                    fulfillmentID: orderFulfillment.orderFulfillment_orderFulfillmentID,
                  }),
                )
                  .then((response) => {
                    if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length)
                      toast.error(getErrorMessage(response.success().errors));
                    else if (response.isSuccess()) {
                      getOrderInfo({ isFetching: false });
                      const values = accountAddresses.find(
                        ({ accountAddressID }) => accountAddressID === value,
                      ).address;
                      setAddress(
                        `${values?.streetAddress}, ${values?.street2Address ? `${values?.street2Address}, ` : ""}  ${
                          values?.city
                        }, ${values?.postalCode}`,
                      );
                      toast.success("Address has been saved successfully for order.");
                      setShowModal(false);
                    }
                  })
                  .finally(() => {
                    setSaveLoading(false);
                  });
              }}
              customLabel={t("frontend.checkout.receive_option")}
              selectedValue={selectedAddress}
            />
            <BillingAddress
              saveAddressOnAccount={false}
              onSave={(values) => {
                saveAddressOnOrder(values);
              }}
              onCancel={(values) => {
                setShowModal(false);
              }}
              existingAddress={selectedAddress ? {} : existingAddress}
            />
          </Overlay>
        </Modal>
      )}
    </>
  );
};

export default TradeOrderAddress;
