import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { useListingFilter } from "../../../../hooks";
import { FacetFilterModel, FacetFilterProps } from "./FacetFilter.componentModel";

export const useComponentData = (props: FacetFilterProps) => {
  const { filter, updateAttribute, facetKey = "" } = props;
  const { searchTerm, setSearchTerm, searchResults, appliedFilters } = useListingFilter(
    filter.options,
    filter.selectType,
    facetKey,
  );
  const filterData = useSelector((state: any) => state.configuration.filtering.filterDataShowCounts);
  const [updateCount, setUpdateCount] = useState<number>(filterData);

  const componentData = useMemo(
    () => new FacetFilterModel({ props, searchTerm, searchResults, updateCount, appliedFilters }),
    [props, searchTerm, searchResults, updateCount, appliedFilters],
  );

  const onShowMoreClick = () => {
    setUpdateCount(componentData.searchResults.length);
  };

  const onShowLessClick =
    updateCount > filterData
      ? () => {
          setUpdateCount(filterData);
        }
      : undefined;

  return {
    componentData,
    componentService: {
      updateAttribute,
      onShowMoreClick,
      setSearchTerm,
      onShowLessClick,
    },
  };
};
