export interface ProductTypeData {
  urlTitle: string;
  systemCode: string;
  imageFile: string;
  parentProductType_productTypeID: string;
  productTypeDescription: string;
  productTypeID: string;
  productTypeIDPath: string;
  productTypeName: string;
  productTypeNamePath: string;
  childProductTypes: ProductTypeData[];
}

export interface ProductTypeListProps {
  data: any;
  showOnlyDirectChild?: boolean;
  customStyleClasses?: string;
  isFetching?: boolean;
  rangeMetaData: {
    [key: string]: { rangeName: string; rangeLogo: string; sortOrder?: number };
  };
}

class ProductTypeListModel {
  childProductTypes: any[] = [];
  showOnlyDirectChild: boolean = false;
  isFetching: boolean = false;
  customStyleClasses?: string;
  rangeMetaData: ProductTypeListProps["rangeMetaData"] = {};
  constructor({ props }: { props: ProductTypeListProps }) {
    this.showOnlyDirectChild = !!props.showOnlyDirectChild;
    this.isFetching = !!props.isFetching;
    const { childProductTypes = [] } = props.data || {};
    this.childProductTypes = childProductTypes;
    this.customStyleClasses = props.customStyleClasses;
    this.rangeMetaData = props.rangeMetaData || {};
  }
}
export { ProductTypeListModel };
