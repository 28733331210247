import { useContentContext } from "../../contexts/ContentContext";

import { useElementContext } from "../../contexts";

const LocationPage = () => {
  const {
    templateData: { location },
  } = useContentContext();
  const {
    LocationModule,
    PageModule: { DynamicPage },
    CommonModule: { PageContentRow },
  } = useElementContext();

  return (
    <DynamicPage>
      {({ renderDynamicContent }: any) => (
        <>
          <PageContentRow>{location && <LocationModule.CoreControl.LocationCard location={location} />}</PageContentRow>
          {renderDynamicContent()}
        </>
      )}
    </DynamicPage>
  );
};

export default LocationPage;
