import { ReactElement } from "react";
import { useElementContext } from "../../contexts";
import { MenuItemProps } from "../Header/MenuItem/MenuItem";

export interface ContentStackMenuProps {
  contentStack: ReactElement[];
}

const ContentStackMenu = ({ contentStack }: ContentStackMenuProps) => {
  const {
    CommonModule: { MenuItem },
  } = useElementContext();

  const columnVal = Math.min(4, Math.floor(12 / (contentStack?.length || 1)));
  return (
    <>
      {contentStack?.map((contentStac, i) => {
        return (
          <ul className={`col-md-${columnVal} ${columnVal < 4 ? "flex-md-grow-1" : ""} col-4`} key={i}>
            {contentStac?.props?.el?.innerElements?.length > 0 && (
              <>
                <div className="linksTitle px-0">{contentStac.props.el.contentElementName}</div>
                <>
                  <ul>
                    {contentStac.props.el.innerElements.map((d: MenuItemProps, i: number) => {
                      return <MenuItem {...d} key={i} cssClass={`px-0 py-0 ${d.cssClass}`} />;
                    })}
                  </ul>
                </>
              </>
            )}
          </ul>
        );
      })}
    </>
  );
};

export { ContentStackMenu };
