import { SetStateAction } from "react";
import { FormQuestion, Layout, DynamicFormProps, Payload, InvalidTracker } from "./interface";

export class formModel {
  submitDisabled: boolean = false;
  layoutInput: formModel_formQuestion[][] = [];
  reference: string = "";
  description: string = "";
  heading: string = "";
  formAction: string = "8a8284c983f707020183fb05ec0109b9";
  stylingCustomClasses: string = "";
  bootStrapLayoutClass: string = "";
  successMessage: string = "";
  actionButtonText: string = "Submit";
  requireReCAPTCHA: boolean = true;
  customStyleClasses?: string;

  isFormSubmitted: boolean = false;
  isFormSubmitting: boolean = false;
  handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => Promise.resolve();
  setRecaptchaVerified: (isVerified: boolean) => void = () => {};

  constructor({
    props,
    payload,
    isFormSubmitted,
    isFormSubmitting,
    invalidTracker,
    recaptchaVerified,
    recaptchaSitekey,
    setValueFactory,
    handleFormSubmit,
    setRecaptchaVerified,
  }: {
    props: DynamicFormProps;
    payload: Payload;
    isFormSubmitted: boolean;
    isFormSubmitting: boolean;
    invalidTracker: InvalidTracker;
    recaptchaVerified: boolean;
    recaptchaSitekey?: string;
    setValueFactory: (attributeCode: string) => (func: SetStateAction<{}>) => void;
    handleFormSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
    setRecaptchaVerified: (isVerified: boolean) => void;
  }) {
    const {
      formLayout = "[]",
      formQuestions = [],
      formDescription = "",
      formHeading = "",
      formReference = "",
      formRequireReCAPTCHA = false,
      formAction = "8a8284c983f707020183fb05ec0109b9",
      stylingCustomClasses = "",
      bootStrapLayoutClass = "",
      actionButtonText = "Submit",
      successMessage = "Thank you for contacting us, we will get back to you as soon as possible.",
      customStyleClasses,
    } = props;

    this.customStyleClasses = customStyleClasses;
    this.reference = formReference;
    this.description = formDescription;
    this.heading = formHeading;
    this.formAction = formAction;
    this.stylingCustomClasses = stylingCustomClasses;
    this.bootStrapLayoutClass = bootStrapLayoutClass;
    this.requireReCAPTCHA = formRequireReCAPTCHA === "1" && !!recaptchaSitekey;
    this.submitDisabled =
      this.requireReCAPTCHA === false ? this.requireReCAPTCHA : this.requireReCAPTCHA && !recaptchaVerified;
    this.actionButtonText = actionButtonText;
    this.successMessage = successMessage;

    this.isFormSubmitted = isFormSubmitted;
    this.isFormSubmitting = isFormSubmitting;
    this.handleFormSubmit = handleFormSubmit;
    this.setRecaptchaVerified = setRecaptchaVerified;

    this.setLayoutInput(formLayout, formQuestions, payload, invalidTracker, setValueFactory);
  }

  setLayoutInput = (
    formLayout: string,
    formQuestions: FormQuestion[],
    payload: Payload,
    invalidTracker: InvalidTracker,
    setValueFactory: (attributeCode: string) => (func: SetStateAction<{}>) => void,
  ) => {
    let layout: Layout[] = JSON.parse(formLayout);
    if (!Array.isArray(layout)) layout = [];

    const questions = formQuestions
      .sort((a: any, b: any) => (a.sortOrder > b.sortOrder ? 1 : -1))
      .map(
        (question) =>
          new formModel_formQuestion(
            question,
            payload[question.attributeCode],
            invalidTracker[question.attributeCode],
            setValueFactory(question.attributeCode),
          ),
      );

    const questionWithLayout = layout.reduce<formModel_formQuestion[][]>((acc, { elements, orientation }: Layout) => {
      if (orientation === "Horizontal")
        acc.push(
          (elements.split(",") || [])
            .map((field: string) => questions.find((q) => q.attributes.attributeCode === field)!)
            .filter((q) => !!q),
        );
      else {
        (elements.split(",") || []).forEach((field: string) => {
          const q = questions.find((q) => q.attributes.attributeCode === field);
          if (q) acc.push([q]);
        });
      }
      return acc;
    }, []);
    const questionWithoutLayout = [
      ...questions
        .filter(
          (question) =>
            !layout?.find((group: any) => group.elements?.split(",").includes(question.attributes.attributeCode)),
        )
        .map((q) => [q]),
    ];

    this.layoutInput = [...questionWithLayout, ...questionWithoutLayout];
  };
}

export class formModel_formQuestion {
  attributes: FormQuestion;
  value: any = undefined;
  invalid: boolean;
  validationMessage: string | undefined;
  setValue: (func: SetStateAction<{}>) => void;

  constructor(attributes: FormQuestion, value: any, invalid: boolean, setValue: (func: SetStateAction<{}>) => void) {
    this.attributes = attributes;
    this.value = value;
    this.invalid = invalid;

    this.setValue = setValue;
  }
}
