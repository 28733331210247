import { InvalidTracker, FormQuestion, Payload } from "../interface";

const EMAIL_VALIDATION_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateFields = ({ formQuestions, payload }: { formQuestions: FormQuestion[]; payload: Payload }) => {
  const invalidFields: InvalidTracker = {};
  // validate form
  const requiredFieldsFilledOut = formQuestions
    .filter((q) => q.requiredFlag)
    .reduce((acc, curr) => {
      // the value is not undefined, and is not an empty string
      const isValid = payload[curr.attributeCode] !== undefined && payload[curr.attributeCode] !== "";
      if (!isValid) invalidFields[curr.attributeCode] = true;
      return acc && isValid;
    }, true);

  const regexMatches = formQuestions
    .filter((q) => q.validationRegex)
    .reduce((acc, curr) => {
      // the current answer, if undefined is treated as an empty string, and compared to the validation regex
      const isValid = !!(payload[curr.attributeCode] ?? "").match(curr.validationRegex);
      if (!isValid) invalidFields[curr.attributeCode] = true;
      return acc && isValid;
    }, true);

  const emailsValid = formQuestions
    .filter((q) => q.inputType === "email")
    .reduce((acc, curr) => {
      // the current answer, if undefined is treated as an empty string, and compared to the validation regex
      const isValid = !!(payload[curr.attributeCode] ?? "").match(EMAIL_VALIDATION_REGEX);
      if (!isValid) invalidFields[curr.attributeCode] = true;
      return acc && isValid;
    }, true);

  return {
    invalidFields,
    isFormValid: requiredFieldsFilledOut && regexMatches && emailsValid,
  };
};

export const formatPayload = (payload: Payload) => {
  const newPayload = { ...payload };
  for (const [key, value] of Object.entries(payload)) {
    if (key.includes("-filename")) {
      var newKey = key.replace("-filename", "");
      newPayload[newKey] = value;
    }
  }
  return newPayload;
};
