import { useElementContext } from "../../../contexts";

export interface LocationHeaderProps {
  title: string;
  thumbnail?: string;
}

export const LocationHeader = ({ title, thumbnail }: LocationHeaderProps) => {
  const {
    CommonModule: { SimpleImage },
  } = useElementContext();
  return (
    <div className="d-flex p-4 bg-light">
      <SimpleImage style={{ maxWidth: 150, maxHeight: 100, objectFit: "contain" }} src={thumbnail} />
      <div className="d-flex align-items-center px-4 pr-0">
        <h2 className="p-0 m-0">{title}</h2>
      </div>
    </div>
  );
};
