import { useDispatch, useSelector } from "react-redux";
import {
  getEligibleFulfillmentMethods,
  getPickupLocations,
  changeOrderFulfillment,
  addShippingAddressUsingAccountAddress,
  addNewAddressAndAttachAsShipping,
  addShippingAddress,
  addShippingMethod,
} from "../../../actions";
import { useEffect } from "react";
import {
  getAllOrderItems,
  getAllOrderFulfillments,
  isAllowedToSwitchFulfillmentMethod,
  getAllEligibleFulfillmentMethods,
  fulfillmentMethodSelector,
} from "../../../selectors";
import { Navigate } from "react-router-dom";
import { usePickupLocation } from "../../../hooks";
import { pickupLocationOptions } from "../../../selectors/orderSelectors";
import { useElementContext } from "../../../contexts";

const ShippingSlide = ({ currentStep }) => {
  const {
    CommonModule: { FulfillmentList, SlideNavigation, Overlay },
  } = useElementContext();
  const dispatch = useDispatch();
  const { orderRequirementsList } = useSelector((state) => state.cart);
  const allOrderFulfillments = useSelector(getAllOrderFulfillments);
  const { isFetching } = useSelector((state) => state.cart);
  let orderFulfillments = useSelector(getAllOrderFulfillments);
  let orderItems = useSelector(getAllOrderItems);
  const canSwitchFulfillmentMethod = useSelector(isAllowedToSwitchFulfillmentMethod);
  let eligibleFulfillmentMethods = useSelector(getAllEligibleFulfillmentMethods);
  let selectedFulfillmentMethod = useSelector(fulfillmentMethodSelector);
  const { onChangeDate, onChangeLocation } = usePickupLocation();
  useEffect(() => {
    dispatch(getEligibleFulfillmentMethods());
    dispatch(getPickupLocations());
  }, [dispatch]);
  const pickupLocations = useSelector(pickupLocationOptions);
  if (
    allOrderFulfillments?.length === 1 &&
    allOrderFulfillments?.at(0)?.fulfillmentMethod?.fulfillmentMethodType === "auto"
  ) {
    return <Navigate to={`../${currentStep.next}`} />;
  }
  return (
    <>
      <Overlay
        active={isFetching}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(0, 0, 0, 0)",
          }),
          spinner: (base) => ({
            ...base,
            width: "100px",
            "& svg circle": {
              stroke: "rgba(211, 211, 211)",
            },
          }),
        }}
        spinner
      >
        <FulfillmentList
          orderFulfillments={orderFulfillments}
          orderItems={orderItems}
          canSwitchFulfillmentMethod={canSwitchFulfillmentMethod}
          selectedFulfillmentMethod={selectedFulfillmentMethod}
          eligibleFulfillmentMethods={eligibleFulfillmentMethods}
          pickupLocations={pickupLocations}
          onChangeOrderFullfillment={(fulfillmentMethodID, orderItemIDList) => {
            dispatch(changeOrderFulfillment({ fulfillmentMethodID, orderItemIDList }));
          }}
          onShipmentSelect={(value, orderFulfillmentID) => {
            return dispatch(
              addShippingAddressUsingAccountAddress({
                accountAddressID: value,
                fulfillmentID: orderFulfillmentID,
              }),
            );
          }}
          onShipmentSave={(values, orderFulfillmentID) => {
            if (values.saveAddress) {
              return dispatch(addNewAddressAndAttachAsShipping({ ...values }));
            } else {
              return dispatch(
                addShippingAddress({ ...values, fulfillmentID: orderFulfillmentID, returnJSONObjects: "cart" }),
              );
            }
          }}
          onSelectShippingMethod={(value, orderFulfillmentID) => {
            dispatch(
              addShippingMethod({
                shippingMethodID: value,
                fulfillmentID: orderFulfillmentID,
              }),
            );
          }}
          onChangeDate={onChangeDate}
          onChangeLocation={onChangeLocation}
        />
      </Overlay>
      <SlideNavigation currentStep={currentStep} nextActive={!orderRequirementsList.includes("fulfillment")} />
    </>
  );
};

export { ShippingSlide };
