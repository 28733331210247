import { useComponentData } from "./HeartButton.componentService";
import { HeartButtonView } from "./HeartButton.componentView";
import { HeartButtonProps } from "./HeartButton.componentModel";
import { useCallback } from "react";

const HeartButton = (props: HeartButtonProps) => {
  const { componentData, componentService } = useComponentData(props);

  const onHeartButtonClicked = useCallback(() => {
    if (componentData.liked) {
      componentService.removeFromWishList();
    } else {
      componentService.addToWishList();
    }
  }, [componentData.liked, componentService]);

  return <HeartButtonView componentData={componentData} onHeartButtonClicked={onHeartButtonClicked} />;
};
export { HeartButton, type HeartButtonProps };
