import Masonry from "react-masonry-css";

const ResourceHeader = ({ contentElementName }) =>
  contentElementName?.trim()?.length > 0 ? (
    <header className="section-title">
      <h2>{contentElementName}</h2>
    </header>
  ) : null;

const ResourceCardLayoutCSSBlock = ({ children, contentElementName }) => {
  return (
    <div className="container content-container p-2 px-5 resourceCards resourceCards-block" style={{}}>
      <ResourceHeader contentElementName={contentElementName} />
      <div className="d-flex flex-wrap">{children}</div>
    </div>
  );
};

const ResourceCardLayoutGrid = ({ children, contentElementName, stylingCustomClasses = "" }) => {
  return (
    <div className="container p-2 px-5 resourceCards resourceCards-grid">
      <ResourceHeader contentElementName={contentElementName} />
      <div className="d-flex flex-wrap">{children}</div>
    </div>
  );
};

const ResourceCardLayoutCardGroup = ({ children, ...props }) => {
  const { categoryList = [], contentElementName } = props;
  if (categoryList.length > 0) {
    return (
      <div>
        <ResourceHeader contentElementName={contentElementName} />
        {categoryList.map((category) => {
          const childList = [];
          children.map((child) => {
            if (category === child.props?.el?.cardCategory) {
              return childList.push(child);
            }
            return null;
          });
          return (
            <div className="container p-5 resourceCards resourceCards-grid">
              <div className="row d-flex flex-wrap gy-5">
                <h3 className="mt-0 fw-bold">{category}</h3>
                {childList}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div
      className="grid d-flex resourceCards"
      style={{ gridTemplateColumns: " repeat(auto-fill, minmax(200px, 1fr))" }}
    >
      <ResourceHeader contentElementName={contentElementName} />
      {children}
    </div>
  );
};

const ResourceCardLayoutCardMasontry = ({
  children,
  contentElementName,
  breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  },
}) => {
  return (
    <div className="container-fluid px-3">
      <ResourceHeader contentElementName={contentElementName} />
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="sw-masonry-grid mt-3"
        columnClassName="sw-masonry-grid_column"
      >
        {children
          ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
          ?.map((card) => {
            return (
              <div key={card.key} className="brick">
                {card}
              </div>
            );
          })}
      </Masonry>
    </div>
  );
};

const ResourceLinkListing = (props) => {
  let { children = [], contentElementName, maxColumn, style } = props;
  let iters = 1;
  if (!maxColumn || maxColumn === 0) {
    maxColumn = 4;
  }
  if (children.length && maxColumn && maxColumn !== 0) {
    iters = Math.ceil(children.length / maxColumn);
  }
  if (!children) {
    return null;
  }
  return (
    <div className="bodyContent col-12 p-5">
      <ResourceHeader contentElementName={contentElementName} />
      <ResourceLinkListingResourceLinkColumns iters={iters} children={children} maxColumn={maxColumn} style={style} />
    </div>
  );
};

const ResourceLinkListingResourceLinkColumns = ({ iters, children, maxColumn, style }) => {
  let valDict = [];
  let prevCount = -1;
  for (var iterNo = 0; iterNo < iters; iterNo++) {
    valDict.push(iterNo);
  }
  return (
    <div className="wrapper-container px-0">
      {valDict.map((nos, i) => {
        return (
          <div key={i} className="row justify-content-start pb-4 px-4">
            {children?.map((el, idx) => {
              if (idx > prevCount && idx < (nos + 1) * maxColumn) {
                prevCount = idx;
                return (
                  <div
                    key={idx}
                    className={`cetColumnsItem pr-4-lg `}
                    style={style === "info" ? { width: "25%" } : { width: `${100 / maxColumn}%` }}
                  >
                    {el}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        );
      })}
    </div>
  );
};

export {
  ResourceLinkListing,
  ResourceCardLayoutCSSBlock,
  ResourceCardLayoutGrid,
  ResourceCardLayoutCardGroup,
  ResourceCardLayoutCardMasontry,
};
