import { useLocation, useNavigate } from "react-router";
import { SkuSelectorModel, SkuSelectorProps } from "./SkuSelector.componentModel";
import { useMemo } from "react";

export const useComponentModel = (props: SkuSelectorProps) => {
  const loc = useLocation();
  const navigate = useNavigate();

  const componentData = useMemo(() => new SkuSelectorModel({ props }), [props]);

  const onSelectorChange = (skuID: string) => {
    navigate(
      {
        pathname: loc.pathname,
        search: `skuid=${skuID}`,
      },
      { replace: true },
    );
  };

  return { componentData, componentService: { onSelectorChange } };
};
