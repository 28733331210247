import { useComponentData } from "./Cart.componentService";
import View from "./Cart.componentView";

const CartController = () => {
  const { componentData, componentService } = useComponentData();
  return (
    <View
      componentData={componentData}
      onClearCartData={componentService.onClearCartData}
      onRemoveItem={componentService.onRemoveItem}
      onUpdateQty={componentService.onUpdateQty}
      onApplyCode={componentService.onApplyCode}
    />
  );
};

export default CartController;
