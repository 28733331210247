import { ProductAttributesProps } from "./ProductAttributes.componentModel";
import { ProductAttributesView } from "./ProductAttributes.componentView";
import { useCopmonentData } from "./ProductAttributes.componentService";

const ProductAttributes = (props: ProductAttributesProps) => {
  const { componentData, componentService } = useCopmonentData(props);

  return <ProductAttributesView componentData={componentData} downloadFile={componentService.downloadFile} />;
};

export { ProductAttributes, type ProductAttributesProps };
