import { MiniCartItemProps } from "../MiniCartItem";
import { Cart } from "../../../../interface/Cart";

export interface MiniCartProps {
  color?: string;
}

export class MiniCartModel {
  cartList: MiniCartItemProps["cartItem"][] = [];
  miniCartOpen: boolean = false;
  color?: string;
  cart: Cart;
  quoteModal: boolean = false;
  miniCartStyle?: "Dropdown" | "Sidepanel(left)" | "Sidepanel(right)" = "Dropdown";
  miniCartShowQuote: boolean = true;
  miniCartSize: string;

  constructor({
    cartList,
    miniCartOpen,
    color = "",
    cart,
    quoteModal,
    miniCartStyle = "Dropdown",
    miniCartShowQuote,
    miniCartSize,
  }: MiniCartModel) {
    this.cartList = cartList;
    this.miniCartOpen = miniCartOpen;
    this.color = color;
    this.cart = cart;
    this.quoteModal = quoteModal;
    this.miniCartStyle = miniCartStyle;
    this.miniCartShowQuote = miniCartShowQuote;
    this.miniCartSize = miniCartSize;
  }
}
