import { useElementContext } from "../../../contexts";

export const HeaderContentTwo = ({ theme, siteSetting }: { theme: any; siteSetting: any }) => {
  const {
    CommonModule: { DisplayField, SocialLinks },
  } = useElementContext();
  return (
    <div className="d-none d-lg-flex flex-column align-items-start small order-xl-2">
      <div className="d-flex">
        <DisplayField fieldValue={siteSetting.companyPhone} iconClass={"telephone-fill"} />
        <SocialLinks color={theme.headerTextColor?.trim()} prefix={""} />
      </div>
      <DisplayField fieldValue={siteSetting.companyEmailAddress} iconClass={"at"} />
      <DisplayField fieldValue={siteSetting.companyAddress} iconClass={"pin-map-fill"} />
    </div>
  );
};
