import { ReactNode } from "react";
import { useUtilities } from "../../hooks/useUtilities";
import { useContentContext } from "../../contexts/ContentContext";
import { Event } from "./interface";
import { useElementContext } from "../../contexts";

const EventPage = () => {
  const pageData = useContentContext();
  let { eventHandlerForWSIWYG } = useUtilities();
  const { event }: { event: Event } = pageData.templateData;
  const {
    PageModule: { DynamicPage },
    CommonModule: { PageContentRow, Truncate, ExternalLink, EventDateTime },
  } = useElementContext();

  return (
    <DynamicPage>
      {({ renderDynamicContent }: { renderDynamicContent: () => ReactNode }) => (
        <>
          <PageContentRow>
            {event?.featuredImage && (
              <img
                style={{ aspectRatio: "3/1" }}
                className="eventPage-img w-auto"
                src={event.featuredImage}
                alt={event.title}
              />
            )}
            <h2 className="display-6">{event?.title}</h2>
            {event?.labelText && (
              <label
                className={`p-1 px-2 mx-3 rounded w-auto text-break ${event.labelStyles || "bg-primary text-light"}`}
              >
                {event.labelText}
              </label>
            )}
            <EventDateTime
              cssClass="p-2 px-3"
              startDateTime={event?.startDateTime}
              endDateTime={event?.endDateTime}
              format="DD MMM YYYY h:mm A"
            />
            <Truncate cssClass="mb-2" lineClamp="6">
              <div
                onClick={eventHandlerForWSIWYG}
                dangerouslySetInnerHTML={{
                  __html: event?.description,
                }}
              />
            </Truncate>
            <div className="d-flex gap-2 pb-4">
              {event?.actionButtons.map((button, index) => (
                <ExternalLink
                  className={`btn ${button.btn_style || "btn-primary"}`}
                  key={index}
                  linkUrl={button.cta_url}
                  target={button.cta_target}
                >
                  {button.cta_title}
                </ExternalLink>
              ))}
            </div>
          </PageContentRow>
          {renderDynamicContent()}
        </>
      )}
    </DynamicPage>
  );
};

export default EventPage;
