import { getSdkURL } from "../../services";
import { getCurrentSiteCode } from "../../utils";

const OauthFacebookLogin = ({ buttonText }) => {
  //get site code
  const siteCode = getCurrentSiteCode();
  //set redirect url
  const redirectURL =
    getSdkURL() + "?slatAction=main.OAuthLoginStorefrontHandler&integrationName=facebooklogin&siteCode=" + siteCode;

  return (
    <div id="FacebookLogin" className="btn btn-primary btn-lg m-2" onClick={() => window.location.replace(redirectURL)}>
      <i className="bi bi-facebook m-2" />
      <span className="buttonText ml-2">{buttonText}</span>
    </div>
  );
};

export { OauthFacebookLogin };
