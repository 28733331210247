import image from "../assets/posts.webp";
import POSTFLOORIMAGE from "../assets/POST-FLOOR.webp";
import DESIGNPINELOGO from "../assets/design-pine-logo.webp";
import POSTROOFFLOORIMAGE from "../assets/postsRoofFloor.png";
import { useEffect } from "react";
import $ from "jquery";

const Posts = () => {
  useEffect(() => {
    $("#PostSubmit").on("click", function () {
      var spanType = $("#list1");

      if (spanType.val() === "Floor%20Only") {
        floorOnSubmit();
      } else if (spanType.val() === "Floor%20and%20Roof") {
        bothOnSubmit();
      } else {
        postsOnSubmit();
      }
    });

    function floorOnSubmit() {
      var x = document.getElementById("myDIV");
      var list1 = $("#list1");
      var list5 = $("#list5");
      var list6 = $("#list6");

      var errorMessage = document.getElementById("errorMessage4");

      if (list1.val() === "") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Span Type";
        $("#list1").focus();
        return false;
      } else if (list5.val() === "NULL") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Supported Floor Load";
        $("#list5").focus();
        return false;
      } else if (list6.val() === "NULL") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Floor Live Load";
        $("#list6").focus();
        return false;
      } else {
        errorMessage.style.display = "none";
        x.style.display = "block";
        var spantype = list1.val();
        var windzone = "NULL";
        var roofload = "NULL";
        var roofloadsupport = "NULL";
        var floorloadsupport = list5.val();
        var floorliveload = list6.val();
        var desiredspan = document.getElementById("desiredSpan").value;

        requestPost(spantype, windzone, roofload, roofloadsupport, floorloadsupport, floorliveload, desiredspan);
        document.getElementById("showAppReport").innerHTML =
          '<div className="col-md-6"> <h2><b>Post</b> </h2> <p Floor live load 2.0 kPa (Should be as per the live load selected)</p><p> Floor dead load 0.4kPa (40kg/m2)</p> <p><b>Notes</b> </p> <p> 1. Post height is measured from connection at base to floor bearer connection point.<br> 2. Tributary roof area equals tributary floor area<br>3. 88x88 posts are not recommended for supporting floors</p></div><div className="col-md-6"><div className="imgtype"><img src="https://cdn8.bigcommerce.com/s-fa2oh/product_images/uploaded_images/post-floor.jpg" width="100%"/> </div></div>';

        return true;
      }
    }
    function bothOnSubmit() {
      var x = document.getElementById("myDIV");
      var list1 = $("#list1");
      var list2 = $("#list2");
      var list3 = $("#list3");
      var list4 = $("#list4");
      // eslint-disable-next-line
      var list5 = $("#list5");
      var list6 = $("#list6");

      var errorMessage = document.getElementById("errorMessage4");

      if (list1.val() === "") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Span Type";
        $("#list1").focus();
        return false;
      } else if (list2.val() === "NULL") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Supported Wind Zone";
        $("#list2").focus();
        return false;
      } else if (list3.val() === "NULL") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Roof Load";
        $("#list3").focus();
        return false;
      } else if (list4.val() === "NULL") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Supported Roof Load";
        $("#list4").focus();
        return false;
      } else if (list6.val() === "NULL") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Floor Live Load";
        $("#list6").focus();
        return false;
      } else {
        errorMessage.style.display = "none";
        x.style.display = "block";
        var spantype = list1.val();
        var windzone = list2.val();
        var roofload = list3.val();
        var roofloadsupport = list4.val();
        var floorloadsupport = "NULL";
        var floorliveload = list6.val();
        var desiredspan = document.getElementById("desiredSpan").value;

        requestPost(spantype, windzone, roofload, roofloadsupport, floorloadsupport, floorliveload, desiredspan);
        document.getElementById("showAppReport").innerHTML =
          '<div className="col-md-6"> <h2><b>Post</b> </h2> <p> Floor live load 2.0 kPa (Should be as per the live load selected)</p><p> Floor dead load 0.4kPa (40kg/m2)</p> <p><b>Notes</b> </p> <p> 1. Post height is measured from connection at base to floor bearer connection point.<br> 2. Tributary roof area equals tributary floor area<br>3. 88x88 posts are not recommended for supporting floors</p></div><div className="col-md-6"><div className="imgtype"><img src="https://cdn11.bigcommerce.com/s-od0de/images/stencil/original/image-manager/postsrooffloor.png?v=290722" width="100%"/> </div></div>';

        return true;
      }
    }
    function postsOnSubmit() {
      var x = document.getElementById("myDIV");
      var list1 = $("#list1");
      var list2 = $("#list2");
      var list3 = $("#list3");
      var list4 = $("#list4");
      // eslint-disable-next-line
      var list5 = $("#list5");
      // eslint-disable-next-line
      var list6 = $("#list6");

      var errorMessage = document.getElementById("errorMessage4");

      if (list1.val() === "") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Span Type";
        $("#list1").focus();
        return false;
      } else if (list2.val() === "NULL") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Supported Wind Zone";
        $("#list2").focus();
        return false;
      } else if (list3.val() === "NULL") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Roof Load";
        $("#list3").focus();
        return false;
      } else if (list4.val() === "NULL") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Supported Roof Load";
        $("#list4").focus();
        return false;
      } else {
        errorMessage.style.display = "none";
        x.style.display = "block";
        var spantype = list1.val();
        var windzone = list2.val();
        var roofload = list3.val();
        var roofloadsupport = list4.val();
        var floorloadsupport = "NULL";
        var floorliveload = "NULL";
        var desiredspan = document.getElementById("desiredSpan").value;

        requestPost(spantype, windzone, roofload, roofloadsupport, floorloadsupport, floorliveload, desiredspan);
        document.getElementById("showAppReport").innerHTML =
          '<div className="col-md-6"> <h2><b>Post</b> </h2> <p><b>Notes</b> </p> <p> 1.	Post height is measured from connection at base to floor bearer connection point.</p></div><div className="col-md-6"><div className="imgtype"><img src="https://cdn11.bigcommerce.com/s-od0de/images/stencil/original/image-manager/posts.png" width="100%"/> </div></div>';

        return true;
      }
    }

    $("#list1").on("change", function () {
      var list1 = $("#list1");

      if (list1.val() === "Floor%20Only") {
        document.getElementById("both").style.display = "none";
        document.getElementById("roof").style.display = "none";
        document.getElementById("floor").style.display = "block";
        document.getElementById("liveload").style.display = "block";

        //hide result
        document.getElementById("myDIV").style.display = "none";
        //change Post Application Image
        document.getElementById("roofOnly").style.display = "none";
        document.getElementById("roofAndFloorOnly").style.display = "none";
        document.getElementById("FloorOnly").style.display = "block";
        $("#list5").prop("selectedIndex", 0);
        $("#list6").prop("selectedIndex", 0);
      } else if (list1.val() === "Floor%20and%20Roof") {
        document.getElementById("both").style.display = "block";
        document.getElementById("floor").style.display = "none";
        document.getElementById("roof").style.display = "block";
        document.getElementById("liveload").style.display = "block";

        //change Post Application Image

        document.getElementById("roofOnly").style.display = "none";
        document.getElementById("roofAndFloorOnly").style.display = "block";
        document.getElementById("FloorOnly").style.display = "none";
        //hide result
        document.getElementById("myDIV").style.display = "none";
        $("#list2").prop("selectedIndex", 0);
        $("#list3").prop("selectedIndex", 0);
        $("#list4").prop("selectedIndex", 0);
        $("#list6").prop("selectedIndex", 0);
      } else {
        document.getElementById("both").style.display = "block";
        document.getElementById("roof").style.display = "block";
        document.getElementById("floor").style.display = "none";
        document.getElementById("liveload").style.display = "none";

        //change Post Application Image
        document.getElementById("roofOnly").style.display = "block";
        document.getElementById("roofAndFloorOnly").style.display = "none";
        document.getElementById("FloorOnly").style.display = "none";

        //hide result
        document.getElementById("myDIV").style.display = "none";
        $("#list2").prop("selectedIndex", 0);
        $("#list3").prop("selectedIndex", 0);
        $("#list4").prop("selectedIndex", 0);
      }
    });

    function requestPost(opt1, opt2, opt3, opt4, opt5, opt6, opt7) {
      var spantype = opt1;
      var windzone = opt2;
      var roofload = opt3;
      var roofLoadSupport = opt4;
      var floorLoadSupport = opt5;
      var floorLiveLoad = opt6;
      var desiredspan = opt7;
      // eslint-disable-next-line
      var setLink =
        "https://spantablesviewer.azurewebsites.net/dppost?brandname=DesignPine" +
        "&spantype=" +
        spantype +
        "&windzone=" +
        windzone +
        "&roofload=" +
        roofload +
        "&roofloadsupport=" +
        roofLoadSupport +
        "&floorloadsupport=" +
        floorLoadSupport +
        "&floorliveload=" +
        floorLiveLoad +
        "&desiredspan=" +
        desiredspan;

      $.ajax({
        url: setLink,
        type: "GET",
        dataType: "text",
        success: function (response) {
          $("#Loading").hide();
          if (response.length === 0) {
            document.getElementById("yellowAlert").style.display = "block";
            document.getElementById("reportButton").style.display = "none";
            document.getElementById("tableShell").style.display = "none";

            document.getElementById("showResult").innerHTML = "No result Found";
          } else {
            $("#list-rafter").empty();
            var clearHTML =
              '<tr><td data-label="Size"><span id="PostSize"></span></td> <td data-label="Grade"><span id="strength"></span></td><td data-label="Height (m)"><span id="span"></span></td><td data-label="Select"><label className="toggle-check"><input id="reportCheckbox" type="checkbox" data-id="" data-name="" data-application="" data-summary="" data-strength="" data-span="" data-spantype="" data-windzone="" data-roofload="" data-supportedroofload="" /></label></td>  </tr>';
            var jsonData = JSON.parse(response);
            // checkGetData(jsonData);
            var dJSON = { products: jsonData },
              directive = {
                tr: {
                  "products<-products": {
                    "#PostSize": "products.PostSize",
                    "#reportCheckbox@data-id": "products.DpPostID",
                    "#reportCheckbox@data-name": "products.brandName",
                    "#reportCheckbox@data-application": "products.Application",
                    "#reportCheckbox@data-summary": "products.PostSize",
                    "#strength": "products.Grade",
                    "#reportCheckbox@data-strength": "products.Grade",
                    "#span": "products.height",
                    "#reportCheckbox@data-span": "products.height",
                    "#reportCheckbox@data-spantype": "products.SpanType",
                    "#reportCheckbox@data-roofload": "products.RoofLoad",
                    "#reportCheckbox@data-windzone": "products.WindZone",
                    "#reportCheckbox@data-supportedroofload": "products.RoofLoadSupport",
                    "#reportCheckbox@data-floorliveload": "products.FloorLiveLoad",
                    "#reportCheckbox@data-supportedfloorload": "products.FloorLoadSupport",
                  },
                },
              };
            $("#list-rafter").append(clearHTML);
            if (typeof dJSON.products !== "undefined" && dJSON.products.length > 0) {
              document.getElementById("yellowAlert").style.display = "none";
              document.getElementById("reportButton").style.display = "block";
              document.getElementById("tableShell").style.display = "inline-table";

              $("#list-rafter").render(dJSON, directive); //render the result
            } else {
              document.getElementById("yellowAlert").style.display = "block";
              document.getElementById("reportButton").style.display = "none";
              document.getElementById("tableShell").style.display = "none";

              document.getElementById("showResult").innerHTML = "Product is currently not available";
            }
          }
          relevantEventListeners();
        },
        error: function (response) {
          document.getElementById("yellowAlert").style.display = "block";
          document.getElementById("reportButton").style.display = "none";
          document.getElementById("tableShell").style.display = "none";
          document.getElementById("showResult").innerHTML = "Product is currently not available";
        },
      });

      $("#searchContainer").ajaxStart(function () {
        $("#Loading").show();
      });
    }
    function relevantEventListeners() {
      $("#reportCheckbox").myCart({
        currencySymbol: "$",
        classCartIcon: "my-cart-icon1",
        classCartBadge: "my-cart-badge1",
        classProductQuantity: "my-product-quantity",
        classProductRemove: "my-product-remove",
        classCheckoutCart: "my-cart-checkout",
        affixCartIcon: false,
        showCheckoutModal: true,
        numberOfDecimals: 2,
        cartItems: [],
      });
    }

    $("#viewReportlist").on("click", function () {
      var val = [];
      $("#list-rafter input[type=checkbox]:checked").each(function (i) {
        this.setAttribute("checked", ""); // For IE
        this.checked = true;
        val[i] = $(this).val();
      });
      if (val.length === 0) {
        $("#yellowAlert").show();
        document.getElementById("showResult").innerHTML = "Please include a report to view report";
      } else {
        $("#yellowAlert").hide();
        $("#hideonReport").hide();
        $("#myDIV").hide();
        $("#ViewReport").show();

        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        var newdate = "Date created: " + day + "/" + month + "/" + year;

        document.getElementById("getDate").innerHTML = newdate;
      }
    });
    // report button JS
    $("#printReport").on("click touchstart", function () {
      $("#ViewReport").printThis({
        pageTitle: "jQuery printThis Demo",
        loadCSS: "",
      });
    });
    $("#continueEdit").on("click touchstart", function () {
      $("#ViewReport").hide();
      $("#hideonReport").show();
      $("#myDIV").show();
    });
    (function ($) {
      var OptionManager = (function () {
        var objToReturn = {};

        var _options = null;
        var DEFAULT_OPTIONS = {
          currencySymbol: "$",
          classCartIcon: "my-cart-icon",
          classCartBadge: "my-cart-badge",
          classProductQuantity: "my-product-quantity",
          classProductRemove: "my-product-remove",
          classCheckoutCart: "my-cart-checkout",
          affixCartIcon: true,
          showCheckoutModal: true,
          numberOfDecimals: 2,
          cartItems: null,
          clickOnAddToCart: function ($addTocart) {},
          afterAddOnCart: function (products) {},
          clickOnCartIcon: function ($cartIcon, products) {},
          checkoutCart: function (products) {
            return false;
          },
        };

        var loadOptions = function (customOptions) {
          _options = $.extend({}, DEFAULT_OPTIONS);
          if (typeof customOptions === "object") {
            $.extend(_options, customOptions);
          }
        };
        var getOptions = function () {
          return _options;
        };

        objToReturn.loadOptions = loadOptions;
        objToReturn.getOptions = getOptions;
        return objToReturn;
      })();
      // eslint-disable-next-line
      var MathHelper = (function () {
        var objToReturn = {};
        var getRoundedNumber = function (number) {
          if (isNaN(number)) {
            throw new Error("Parameter is not a Number");
          }
          number = number * 1;
          var options = OptionManager.getOptions();
          return number.toFixed(options.numberOfDecimals);
        };
        objToReturn.getRoundedNumber = getRoundedNumber;
        return objToReturn;
      })();

      var ProductManager = (function () {
        var objToReturn = {};

        /*
        PRIVATE
        */
        localStorage.products = localStorage.products ? localStorage.products : "";
        var getIndexOfProduct = function (id) {
          var productIndex = -1;
          var products = getAllProducts();
          $.each(products, function (index, value) {
            if (value.id === id) {
              productIndex = index;
              return;
            }
          });
          return productIndex;
        };
        var setAllProducts = function (products) {
          localStorage.products = JSON.stringify(products);
        };
        var addProduct = function (
          id,
          name,
          summary,
          application,
          strength,
          span,
          spantype,
          windzone,
          roofload,
          supportedroofload,
          floorliveload,
          supportedfloorload,
        ) {
          var products = getAllProducts();
          products.push({
            id: id,
            name: name,
            summary: summary,
            application: application,
            strength: strength,
            span: span,
            spantype: spantype,
            windzone: windzone,
            roofload: roofload,
            supportedroofload: supportedroofload,
            floorliveload: floorliveload,
            supportedfloorload: supportedfloorload,
          });
          setAllProducts(products);
        };

        /*
        PUBLIC
        */
        var getAllProducts = function () {
          try {
            var products = JSON.parse(localStorage.products);
            return products;
          } catch (e) {
            return [];
          }
        };
        var updatePoduct = function (id, quantity) {
          var productIndex = getIndexOfProduct(id);
          if (productIndex < 0) {
            return false;
          }
          // eslint-disable-next-line
          var products = getAllProducts();

          return true;
        };
        var setProduct = function (
          id,
          name,
          summary,
          application,
          strength,
          span,
          spantype,
          windzone,
          roofload,
          supportedroofload,
          floorliveload,
          supportedfloorload,
        ) {
          if (typeof id === "undefined") {
            console.error("id required");
            return false;
          }
          if (typeof name === "undefined") {
            console.error("name required");
            return false;
          }

          summary = typeof summary === "undefined" ? "" : summary;
          application = typeof application === "undefined" ? "" : application;
          strength = typeof strength === "undefined" ? "" : strength;
          span = typeof span === "undefined" ? "" : span;
          spantype = typeof spantype === "undefined" ? "" : spantype;
          windzone = typeof windzone === "undefined" ? "" : windzone;
          roofload = typeof roofload === "undefined" ? "" : roofload;
          supportedroofload = typeof supportedroofload === "undefined" ? "" : supportedroofload;
          floorliveload = typeof floorliveload === "undefined" ? "" : floorliveload;
          supportedfloorload = typeof supportedfloorload === "undefined" ? "" : supportedfloorload;

          if (!updatePoduct(id)) {
            addProduct(
              id,
              name,
              summary,
              application,
              strength,
              span,
              spantype,
              windzone,
              roofload,
              supportedroofload,
              floorliveload,
              supportedfloorload,
            );
          }
        };
        var clearProduct = function () {
          setAllProducts([]);
        };
        var removeProduct = function (id) {
          var products = getAllProducts();
          products = $.grep(products, function (value, index) {
            return value.id !== id;
          });
          setAllProducts(products);
        };

        objToReturn.getAllProducts = getAllProducts;
        objToReturn.updatePoduct = updatePoduct;
        objToReturn.setProduct = setProduct;
        objToReturn.clearProduct = clearProduct;
        objToReturn.removeProduct = removeProduct;
        return objToReturn;
      })();

      var loadMyCartEvent = function (targetSelector) {
        var options = OptionManager.getOptions();
        var $cartIcon = $("." + options.classCartIcon);
        // eslint-disable-next-line
        var $cartBadge = $("." + options.classCartBadge);
        // eslint-disable-next-line
        var classProductQuantity = options.classProductQuantity;
        var classProductRemove = options.classProductRemove;
        // eslint-disable-next-line
        var classCheckoutCart = options.classCheckoutCart;
        // eslint-disable-next-line
        var idCartModal = "my-cart-modal";
        var idCartTable = "my-cart-table";
        // eslint-disable-next-line
        var idGrandTotal = "my-cart-grand-total";
        // eslint-disable-next-line
        var idEmptyCartMessage = "my-cart-empty-message";
        // eslint-disable-next-line
        var idDiscountPrice = "my-cart-discount-price";
        // eslint-disable-next-line
        var classProductTotal = "my-product-total";
        var classAffixMyCartIcon = "my-cart-icon-affix";

        if (options.cartItems && options.cartItems.constructor === Array) {
          ProductManager.clearProduct();
          $.each(options.cartItems, function () {
            ProductManager.setProduct(
              this.id,
              this.name,
              this.summary,
              this.application,
              this.strength,
              this.span,
              this.spantype,
              this.windzone,
              this.roofload,
              this.supportedroofload,
              this.floorliveload,
              this.supportedfloorload,
            );
          });
        }

        var drawTable = function () {
          var $cartTable = $("#" + idCartTable);
          $cartTable.empty();

          var products = ProductManager.getAllProducts();
          $.each(products, function () {
            // var total = this.quantity * this.price;
            $cartTable.append(
              '<tr title="' +
                this.summary +
                '" data-id="' +
                this.id +
                '">' +
                '<td data-label="Product">' +
                this.name +
                "</td>" +
                '<td data-label="Application">' +
                this.application +
                "</td>" +
                '<td data-label="Beam Size">' +
                this.summary +
                "</td>" +
                '<td data-label="Strength">' +
                this.strength +
                "</td>" +
                '<td data-label="Span Type">' +
                this.spantype +
                "</td>" +
                '<td width="20%" data-label="Parameter">' +
                this.floorliveload +
                " " +
                this.windzone +
                " " +
                this.roofload +
                " " +
                this.supportedfloorload +
                this.supportedroofload +
                "</td>" +
                '<td data-label="Result (mm)">' +
                this.span +
                "</td>" +
                '<td data-label=" " title="Remove from Cart" className="text-center" style="width: 30px;"><a href="javascript:void(0);" className="btn btn-xs btn-danger removeProd ' +
                classProductRemove +
                '">X</a></td>' +
                "</tr>",
            );
          });
        };
        var showModal = function () {
          drawTable();
        };

        /*
        EVENT
        */
        if (options.affixCartIcon) {
          var cartIconBottom = $cartIcon.offset().top * 1 + $cartIcon.css("height").match(/\d+/) * 1;
          // eslint-disable-next-line
          var cartIconPosition = $cartIcon.css("position");
          $(window).scroll(function () {
            $(window).scrollTop() >= cartIconBottom
              ? $cartIcon.addClass(classAffixMyCartIcon)
              : $cartIcon.removeClass(classAffixMyCartIcon);
          });
        }

        $cartIcon.click(function () {
          options.showCheckoutModal ? showModal() : options.clickOnCartIcon($cartIcon, ProductManager.getAllProducts());
        });

        $(document).on("click", "." + classProductRemove, function () {
          var $tr = $(this).closest("tr");
          var id = $tr.data("id");
          $tr.hide(500, function () {
            ProductManager.removeProduct(id);

            $("#list-rafter input[type=checkbox]:checked").each(function (i) {
              var $target = $(this);

              var checkboxDataID = $target.data("id");

              if (checkboxDataID === id) {
                this.removeAttribute("checked"); // For other browsers
                this.checked = false;
              } else {
                this.setAttribute("checked", ""); // For IE
                this.checked = true;
              }
            });
            drawTable();
          });
        });

        $("#list-rafter input[type=checkbox]").on("click", targetSelector, function () {
          if (this.checked) {
            //Do stuff
            var $target = $(this);
            options.clickOnAddToCart($target);
            var id = $target.data("id");
            var name = $target.data("name");
            var summary = $target.data("summary");
            var application = $target.data("application");
            var strength = $target.data("strength");
            var span = $target.data("span");
            var spantype = $target.data("spantype");
            var windzone = $target.data("windzone");
            var roofload = $target.data("roofload");
            var supportedroofload = $target.data("supportedroofload");
            var floorliveload = $target.data("floorliveload");
            var supportedfloorload = $target.data("supportedfloorload");
            ProductManager.setProduct(
              id,
              name,
              summary,
              application,
              strength,
              span,
              spantype,
              windzone,
              roofload,
              supportedroofload,
              floorliveload,
              supportedfloorload,
            );
            options.afterAddOnCart(ProductManager.getAllProducts());
            this.setAttribute("checked", ""); // For IE
            // this.removeAttribute("checked"); // For other browsers
            this.checked = true;
          } else {
            ProductManager.clearProduct();
            this.removeAttribute("checked"); // For other browsers

            // $('#reportCheckbox').attr('checked', false); // Unchecks it
            this.checked = false;
          }
        });
        $("#resetReport").on("click touchstart", function () {
          $("#my-cart-table").empty();
          $("#list-rafter input[type=checkbox]:checked").each(function (i) {
            this.removeAttribute("checked"); // For other browsers
            this.checked = false;
          });
          // $('#list-rafter input[type=checkbox]').attr('checked', false); // Unchecks it
          // $('.viewReportlist').hide();
          $("#reportCheckbox").myCart({
            cartItems: [],
          });
        });
      };

      $.fn.myCart = function (userOptions) {
        OptionManager.loadOptions(userOptions);
        loadMyCartEvent(this.selector);
        return this;
      };
    })($);
  }, []);
  return (
    <div className="col-md-9 col-sm-9">
      <div className="row">
        <div id="hideonReport" style={{ display: "flex", background: "white" }}>
          {/* <!-- Rafter span selection  --> */}
          <div className="col-md-6">
            <h2>Posts</h2>
            <div className="dropdown clearfix position-static" id="spanfilter">
              <p id="errorMessage4"></p>
              <label htmlFor="SpanType">Post Application</label>
              <select required="" id="list1">
                <option value="" hidden="">
                  {" "}
                  -- Select Option --{" "}
                </option>
                <option value="Roof%20Only">Roof Only</option>
                <option value="Floor%20and%20Roof">Roof and Floor</option>
                <option value="Floor%20Only">Floor Only</option>
              </select>

              <div id="both">
                <label htmlFor="WindZone">Wind Zone</label>
                <select required="" id="list2">
                  <option value="NULL" hidden="">
                    {" "}
                    -- Select Option --{" "}
                  </option>
                  <option value="C1">C1</option>
                  <option value="C2">C2</option>
                  <option value="C3">C3</option>
                  <option value="C4">C4</option>
                  <option value="N1">N1</option>
                  <option value="N2">N2</option>
                  <option value="N3">N3</option>
                  <option value="N4">N4</option>
                  <option value="N5">N5</option>
                  <option value="N6">N6</option>
                </select>

                <label htmlFor="Roof Load">Roof Load</label>
                <select required="" id="list3">
                  <option value="NULL" hidden="">
                    {" "}
                    -- Select Option --{" "}
                  </option>
                  <option value="6kg/m2">6kg/m2 Transparent Sheet 0.5mm, Battens</option>
                  <option value="10kg/m2">10kg/m2 Steel Sheet 0.5mm, Battens</option>
                  <option value="20kg/m2">20kg/m2 Steel Sheet 0.5mm, Battens, 10mm Plaster Ceiling</option>
                  <option value="30kg/m2">30kg/m2 Steel Sheet 0.75mm, Battens, 13mm Plaster Ceiling</option>
                  <option value="40kg/m2">40kg/m2 Steel Sheet 0.75mm, Battens, graded purlins</option>
                  <option value="60kg/m2">60kg/m2 Terracotta or concrete tiles</option>
                  <option value="75kg/m2">75kg/m2 Roof Tiles, Battens, plaster ceiling</option>
                  <option value="90kg/m2">90kg/m2 Roof Tiles, Battens, 19mm hardwood ceiling</option>
                </select>
              </div>

              <div id="roof">
                <label htmlFor="Supported Roof Load">Supported Roof Load (m)</label>
                <select required="" id="list4">
                  <option value="NULL" hidden="">
                    {" "}
                    -- Select Option --{" "}
                  </option>
                  <option value="2">2</option>
                  <option value="4">4</option>
                  <option value="6">6</option>
                  <option value="8">8</option>
                  <option value="10">10</option>
                  <option value="12">12</option>
                  <option value="14">14</option>
                  <option value="16">16</option>
                  <option value="18">18</option>
                  <option value="20">20</option>
                  <option value="22">22</option>
                </select>
              </div>

              <div id="floor">
                <label htmlFor="Supported Floor Load">Supported Floor Load (m)</label>
                <select required="" id="list5">
                  <option value="NULL" hidden="">
                    {" "}
                    -- Select Option --{" "}
                  </option>
                  <option value="2">2</option>
                  <option value="4">4</option>
                  <option value="6">6</option>
                  <option value="8">8</option>
                  <option value="10">10</option>
                  <option value="12">12</option>
                  <option value="14">14</option>
                  <option value="16">16</option>
                  <option value="18">18</option>
                  <option value="20">20</option>
                  <option value="22">22</option>
                </select>
              </div>
              <div id="liveload">
                <label htmlFor="Floor Live Load kPa">Floor Live Load kPa</label>
                <select required="" id="list6">
                  <option value="NULL" hidden="">
                    {" "}
                    -- Select Option --{" "}
                  </option>
                  <option value="1.5">1.5kPa</option>
                  <option value="2">2.0kPa</option>
                  <option value="3">3.0kPa</option>
                  <option value="4">4.0kPa</option>
                </select>
              </div>
              <label htmlFor="Desired Span (M)">Desired Span (M)</label>

              <input type="text" className="form-control inputSpan" id="desiredSpan" placeholder="Desired Span (M)" />
              <button className="dropdown-toggle search1" type="submit" id="PostSubmit">
                Search
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="imgtype">
              <div id="roofOnly">
                <img alt="" src={image} width="100%" />
              </div>
              <div id="roofAndFloorOnly">
                <img alt="" src={POSTROOFFLOORIMAGE} width="100%" />
              </div>
              <div id="FloorOnly">
                <img alt="" src={POSTFLOORIMAGE} width="100%" />
              </div>
            </div>
          </div>
          {/* <!-- list of span table result --> */}

          {/* <!-- list of span table result --> */}
        </div>
        <div className="col-md-12" id="myDIV">
          <div id="searchContainer">
            <div id="Loading">
              <i className="fa fa-spinner fa-pulse fa-5x fa-fw"></i>
              <span className="sr-only">Loading...</span>
            </div>
          </div>

          <table id="tableShell">
            <thead>
              <tr>
                <th scope="col">Size</th>
                <th scope="col">Grade</th>
                <th scope="col">Span (M)</th>
                <th scope="col">Select</th>
              </tr>
            </thead>
            <tbody id="list-rafter">
              <tr>
                <td data-label="Size">
                  <span id="PostSize"></span>
                </td>
                <td data-label="Grade">
                  <span id="strength"></span>
                </td>
                <td data-label="Height (m)">
                  <span id="span"></span>
                </td>
                <td data-label="Select">
                  <label className="toggle-check">
                    <input
                      id="reportCheckbox"
                      type="checkbox"
                      data-id=""
                      data-name=""
                      data-application=""
                      data-summary=""
                      data-strength=""
                      data-span=""
                      data-spantype=""
                      data-windzone=""
                      data-roofload=""
                      data-supportedroofload=""
                      data-floorliveload=""
                      data-supportedfloorload=""
                    />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="col-md-6 m-auto">
            <div className="viewReportlist mt-4" id="reportButton">
              <span className="my-cart-icon1" id="viewReportlist">
                <p>
                  View Report <span className="badge badge-notify my-cart-badge1"></span>
                </p>
              </span>
            </div>
            <div className="yellowAlert" id="yellowAlert">
              <h4>
                <b id="showResult"></b>{" "}
              </h4>
            </div>
          </div>
        </div>

        <div id="ViewReport">
          <div className="col-md-12 reportBorder">
            <div className="spanTablesTitle" style={{ backgroundColor: "#222" }}>
              <h1>
                <b>Span Tables</b>{" "}
              </h1>
            </div>
            <div className="LogoActions">
              <div className="col-md-4">
                <div className="reportLogo">
                  <img className="header-logo-image" src={DESIGNPINELOGO} alt="Design Pine" />
                </div>
              </div>
              <div className="col-md-8">
                <div className="alightItem">
                  <button id="printReport" className="new-button me-3" type="button" name="button">
                    Print Report
                  </button>
                  <button id="continueEdit" className="new-button me-3" type="button" name="button">
                    Continue Editing Report
                  </button>
                  <button id="resetReport" className="new-button me-3" type="button" name="button">
                    Reset Report
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="outputTitle">
                <p>
                  <b className="designPineBlue">DesignPine</b> Span Table List
                </p>
                <p id="getDate"></p>
                <hr />
                <h4>
                  <b>Results</b>{" "}
                </h4>
                <hr />
              </div>

              <div id="output">
                <div className="reportTable">
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Product</th>
                        <th scope="col">Application</th>
                        <th scope="col">Size</th>
                        <th scope="col">Strength</th>
                        <th scope="col">Span Type</th>
                        <th scope="col">Parameter</th>
                        <th scope="col">Result (mm)</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody id="my-cart-table"></tbody>
                  </table>
                </div>
              </div>

              <div id="showAppReport"></div>

              <hr />
              <h2>
                <b>Certification</b>{" "}
              </h2>
              <p>
                The above span output is certified by Dave King Eng - Tasman Consulting, reviewed by: Robin Frengley
                Consulting Engineer
              </p>
              <p>
                <b>Disclaimer</b>{" "}
              </p>
              <p>
                ITI takes no responsibility for the interpretation of the above application or the information input
                into the generation of this report. The Above span are applicable for the products described above only.
              </p>
              <p>www.designpine.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Posts;
