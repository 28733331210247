import { Link } from "react-router-dom";
import { useState } from "react";
import play from "../../assets/images/play.png";
import { toBoolean } from "../../utils";
import { useElementContext } from "../../contexts";
/**
 * videoDescription also
 */
const Video = ({
  videoAllow,
  videoAllowfullscreen,
  videoFrameborder,
  videoHeight,
  videoSrc,
  videoTitle,
  videoWidth,
  playOptions,
  thumbnail,
  customStyleClasses,
}) => {
  const {
    CommonModule: { Modal },
  } = useElementContext();
  const [showModal, setShowModal] = useState(false);
  const [thumbnailvisible, setThumbnailVisible] = useState(true);
  function removeHttp(url) {
    return url ? url.replace(/^https?:\/\//, "") : "";
  }
  const cutVideo = removeHttp(videoSrc);
  return (
    <>
      {playOptions === "In Page" && (
        <div
          className={`container position-relative my-5 video-container d-flex ${customStyleClasses}`}
          style={{ minHeight: "500px", width: videoWidth, marginBottom: "3rem" }}
        >
          {thumbnail && (
            <>
              <img
                src={thumbnail}
                alt=""
                onClick={() => setThumbnailVisible(false)}
                className={`${thumbnailvisible ? "display-block" : "d-none"} position-absolute`}
                style={{
                  left: 0,
                  bottom: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 98,
                  cursor: "pointer",
                  maxWidth: "none",
                }}
              />
              <img
                src={play}
                alt=""
                onClick={() => setThumbnailVisible(false)}
                className={`${thumbnailvisible ? "display-block" : "d-none"} position-absolute`}
                style={{
                  left: "45%",
                  alignSelf: "center",
                  width: "10%",
                  height: "15%",
                  zIndex: 99,
                  cursor: "pointer",
                  maxWidth: "none",
                }}
              />
            </>
          )}
          <iframe
            className="position-absolute inpage-video"
            style={{ left: 0, bottom: 0, width: "100%" }}
            height={videoHeight}
            src={videoSrc}
            title={videoTitle}
            frameBorder={videoFrameborder}
            allow={videoAllow}
            allowFullScreen={toBoolean(videoAllowfullscreen)}
          />
        </div>
      )}
      {playOptions === "In Modal" && (
        <>
          <div
            onClick={() => setShowModal(true)}
            className={`position-relative py-5 container video-container d-flex ${customStyleClasses}`}
            style={{ minHeight: "500px", width: videoWidth, marginBottom: "3rem" }}
          >
            {!thumbnail && (
              <div
                className="display-block position-absolute"
                style={{ left: 0, bottom: 0, width: "100%", height: "100%", zIndex: 99, cursor: "pointer" }}
              ></div>
            )}
            {thumbnail && (
              <>
                <img
                  src={thumbnail}
                  alt=""
                  className={`${thumbnailvisible ? "display-block" : "d-none"} position-absolute`}
                  style={{
                    left: 0,
                    bottom: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 98,
                    cursor: "pointer",
                    maxWidth: "none",
                  }}
                />
                <img
                  src={play}
                  alt=""
                  className={`${thumbnailvisible ? "display-block" : "d-none"} position-absolute`}
                  style={{
                    left: "45%",
                    alignSelf: "center",
                    width: "10%",
                    height: "15%",
                    zIndex: 99,
                    cursor: "pointer",
                    maxWidth: "none",
                  }}
                />
              </>
            )}
            <iframe
              className="position-absolute cursor-pointer inpage-video"
              style={{ left: 0, bottom: 0, width: "100%" }}
              height={videoHeight ? videoHeight : 500}
              src={videoSrc}
              title={videoTitle}
              frameBorder={videoFrameborder}
              allow={videoAllow}
              allowFullScreen={toBoolean(videoAllowfullscreen)}
            />
          </div>
          <Modal title={videoTitle} size="xLarge" show={showModal} setShow={setShowModal} backgroundstatic={true}>
            <iframe
              style={{ minHeight: "500px", width: "100%" }}
              height={videoHeight ? videoHeight : 500}
              src={videoSrc}
              title={videoTitle}
              frameBorder={videoFrameborder}
              allow={videoAllow}
              allowFullScreen={toBoolean(videoAllowfullscreen)}
            />
          </Modal>
        </>
      )}

      {playOptions === "Link to Source" && (
        <Link
          to={`//${cutVideo}`}
          className={`position-relative py-5 container video-container d-flex mb-4 ${customStyleClasses}`}
          style={{ minHeight: "500px" }}
        >
          <div
            className="display-block position-absolute"
            style={{ left: 0, bottom: 0, width: "100%", height: "100%", zIndex: 99, cursor: "pointer" }}
          ></div>
          {thumbnail && (
            <>
              <img
                src={thumbnail}
                alt=""
                className={`${thumbnailvisible ? "display-block" : "d-none"} position-absolute`}
                style={{
                  left: 0,
                  bottom: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 98,
                  cursor: "pointer",
                  maxWidth: "none",
                }}
              />
              <img
                src={play}
                alt=""
                className={`${thumbnailvisible ? "display-block" : "d-none"} position-absolute`}
                style={{
                  left: "45%",
                  alignSelf: "center",
                  width: "10%",
                  height: "15%",
                  zIndex: 99,
                  cursor: "pointer",
                  maxWidth: "none",
                }}
              />
            </>
          )}
          <iframe
            width={videoWidth}
            className="position-absolute cursor-pointer inpage-video"
            style={{ left: 0, bottom: 0 }}
            height={videoHeight ? videoHeight : 500}
            src={videoSrc}
            title={videoTitle}
            frameBorder={videoFrameborder}
            allow={videoAllow}
            allowFullScreen={toBoolean(videoAllowfullscreen)}
          />
        </Link>
      )}
      {/* <div className="position-relative py-5 container">
        <img src='https://itiaustralia-admin.ultracommerce-dev.co//custom/assets/files/imagepath/ITI-NSW1.jpg' className='display-block position-absolute' style={{width:"100%", height: "100%", maxWidth: '100%'}} alt="img"></img>
        <iframe width={videoWidth} height={videoHeight ? videoHeight : 500} src={videoSrc} title={videoTitle} frameBorder={videoFrameborder} allow={false} allowFullScreen={toBoolean(videoAllowfullscreen)} />
      </div> */}
    </>
  );
};
export { Video };
