import { jsonParse } from ".";

interface LocalStorage {
  i18nextLng: string;
  token: string;
  appConfiguration: { currentSite: string; sites: []; settings?: { siteCookieDomain?: string } };
}
const LocalStorageService = {
  setItem: <Key extends keyof LocalStorage, Value extends LocalStorage[Key]>(key: Key, value: Value) => {
    switch (key) {
      case "appConfiguration":
        return localStorage.setItem(key, JSON.stringify(value));
      default:
        return localStorage.setItem(key, value as string);
    }
  },
  getItem: <Key extends keyof LocalStorage, Value extends LocalStorage[Key]>(key: Key): Value | null => {
    const value = localStorage.getItem(key);
    switch (key) {
      case "appConfiguration":
        return jsonParse(value || "");
      default:
        return value as Value;
    }
  },
};
export { LocalStorageService };
