import { SkuSelectorModel } from "./SkuSelector.componentModel";

const SkuSelectorView = ({
  viewData: { skus, sku },
  onSelectorChange,
}: {
  viewData: SkuSelectorModel;
  onSelectorChange: (id: string) => void;
}) => {
  if (skus.length >= 2) {
    return (
      <div className="d-flex flex-row">
        <div className="form-group pe-4 mb-4">
          <select
            className="custom-select rounded-pill"
            required
            value={sku?.skuID}
            onChange={(e) => {
              onSelectorChange(e.target.value);
            }}
          >
            {skus?.map((skuOption) => {
              return (
                <option key={skuOption.skuID} value={skuOption.skuID}>
                  {skuOption.calculatedSkuDefinition}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export { SkuSelectorView };
