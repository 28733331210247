import image from "../assets/rafter.webp";
import DESIGNPINELOGO from "../assets/design-pine-logo.webp";
import { useEffect } from "react";
import $ from "jquery";

const Rafters = () => {
  useEffect(() => {
    $("#rafterSubmit").on("click", function () {
      getSelectedOption();
    });
    function getSelectedOption() {
      var list = $("#list1");
      var list2 = $("#list2");
      var list3 = $("#list3");
      var list4 = $("#list4");

      var x = document.getElementById("myDIV");
      var errorMessage = document.getElementById("errorMessage");

      if (list.val() === "") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Span Type";
        $("#list1").focus();
        return false;
      } else if (list2.val() === "") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select Wind Zone";
        $("#list2").focus();
        return false;
      } else if (list3.val() === "") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select roof load";
        $("#list3").focus();
        return false;
      } else if (list4.val() === "") {
        errorMessage.style.display = "block";
        errorMessage.innerHTML = "Please Select rafter spacing";
        $("#list4").focus();
        return false;
      } else {
        errorMessage.style.display = "none";
        x.style.display = "block";
        var spantype = list.val();
        var windzone = list2.val();
        var roofload = list3.val();
        var roofspacing = list4.val();
        var desiredspan = document.getElementById("desiredSpan").value;
        displayInfo(spantype, windzone, roofload, roofspacing, desiredspan);

        return true;
      }
    }

    function displayInfo(opt1, opt2, opt3, opt4, opt5) {
      var spantype = opt1;
      var windzone = opt2;
      var roofload = opt3;
      var roofspacing = opt4;
      var desiredspan = opt5;
      // eslint-disable-next-line
      var setLink =
        "https://spantablesviewer.azurewebsites.net/rafter?brandname=DesignPine" +
        "&spantype=" +
        spantype +
        "&windzone=" +
        windzone +
        "&roofload=" +
        roofload +
        "&roofspacing=" +
        roofspacing +
        "&desiredspan=" +
        desiredspan;

      $.ajax({
        url: setLink,
        type: "GET",
        dataType: "text",
        success: function (response) {
          $("#Loading").hide();
          if (response.length === 0) {
            document.getElementById("yellowAlert").style.display = "block";
            document.getElementById("reportButton").style.display = "none";
            document.getElementById("tableShell").style.display = "none";

            document.getElementById("showResult").innerHTML = "No result Found";
          } else {
            $("#list-rafter").empty();
            var clearHTML =
              ' <tr> <td data-label="Size"><span id="BeamSize"></span></td> <td data-label="Strength"><span id="strength" data-strength=""></span></td>          <td data-label="Span"><span id="span" data-span=""></span></td>          <td data-label="">            <label className="toggle-check">              <input id="reportCheckbox" type="checkbox" data-id="" data-name=""              data-application="" data-summary="" data-strength="" data-span="" data-spantype="" data-windzone="" data-roofload="" data-rafterspacing="" /> </label> </td> </tr>';
            var jsonData = JSON.parse(response);
            // checkGetData(jsonData);
            var dJSON = { products: jsonData },
              directive = {
                tr: {
                  "products<-products": {
                    "#BeamSize": "products.BeamSize",
                    "#reportCheckbox@data-id": "products.RafterID",
                    "#reportCheckbox@data-name": "products.brandName",
                    "#reportCheckbox@data-application": "products.Application",
                    "#reportCheckbox@data-summary": "products.BeamSize",
                    "#strength": "products.Strength",
                    "#reportCheckbox@data-strength": "products.Strength",
                    "#span": "products.Span",
                    "#reportCheckbox@data-span": "products.Span",
                    "#reportCheckbox@data-spantype": "products.SpanType",
                    "#reportCheckbox@data-windzone": "products.WindZone",
                    "#reportCheckbox@data-roofload": "products.RoofLoad",
                    "#reportCheckbox@data-rafterspacing": "products.RafterSpacing",
                  },
                },
              };
            $("#list-rafter").append(clearHTML);

            if (typeof dJSON.products !== "undefined" && dJSON.products.length > 0) {
              document.getElementById("yellowAlert").style.display = "none";
              document.getElementById("reportButton").style.display = "block";
              document.getElementById("tableShell").style.display = "inline-table";

              $("#list-rafter").render(dJSON, directive); //render the result
            } else {
              document.getElementById("yellowAlert").style.display = "block";
              document.getElementById("reportButton").style.display = "none";
              document.getElementById("tableShell").style.display = "none";

              document.getElementById("showResult").innerHTML = "Product is currently not available";
            }
          }

          relevantEventListeners();
        },
        error: function (response) {
          document.getElementById("yellowAlert").style.display = "block";
          document.getElementById("reportButton").style.display = "none";
          document.getElementById("tableShell").style.display = "none";
          document.getElementById("showResult").innerHTML = "Product is currently not available";
        },
      });

      $("#searchContainer").ajaxStart(function () {
        $("#Loading").show();
      });
    }

    function relevantEventListeners() {
      $("#reportCheckbox").myCart({
        currencySymbol: "$",
        classCartIcon: "my-cart-icon1",
        classCartBadge: "my-cart-badge1",
        classProductQuantity: "my-product-quantity",
        classProductRemove: "my-product-remove",
        classCheckoutCart: "my-cart-checkout",
        affixCartIcon: false,
        showCheckoutModal: true,
        numberOfDecimals: 2,
        cartItems: [],
      });
    }

    $("#viewReportlist").on("click", function () {
      var val = [];
      $("#list-rafter input[type=checkbox]:checked").each(function (i) {
        this.setAttribute("checked", ""); // For IE
        this.checked = true;
        val[i] = $(this).val();
      });
      if (val.length === 0) {
        $("#yellowAlert").show();
        document.getElementById("showResult").innerHTML = "Please include a report to view report";
      } else {
        $("#yellowAlert").hide();
        $("#hideonReport").hide();
        $("#myDIV").hide();
        $("#ViewReport").show();

        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        var newdate = "Date created: " + day + "/" + month + "/" + year;

        document.getElementById("getDate").innerHTML = newdate;
      }
    });
    // report button JS
    $("#printReport").on("click touchstart", function () {
      $("#ViewReport").printThis({
        pageTitle: "jQuery printThis Demo",
        loadCSS: "",
      });
    });
    $("#continueEdit").on("click touchstart", function () {
      $("#ViewReport").hide();
      $("#hideonReport").show();
      $("#myDIV").show();
    });
  }, []);
  return (
    <div className="col-md-9 col-sm-9">
      <div className="row">
        <div id="hideonReport" style={{ display: "flex", background: "white" }}>
          <div className="col-md-6">
            <h2>Rafter</h2>
            <div className="dropdown clearfix position-static" id="spanfilter">
              <p id="errorMessage"></p>
              <label htmlFor="SpanType">Span Type</label>
              <select required id="list1">
                <option value="" hidden>
                  {" "}
                  -- Select Option --{" "}
                </option>
                <option value="Single">Single</option>
                <option value="Continuous">Continuous</option>
              </select>

              <label htmlFor="WindZone">WindZone</label>
              <select required id="list2">
                <option value="" hidden>
                  {" "}
                  -- Select Option --{" "}
                </option>
                <option value="C1">C1</option>
                <option value="C2">C2</option>
                <option value="C3">C3</option>
                <option value="C4">C4</option>
                <option value="N1/N2">N1</option>
                <option value="N1/N2">N2</option>
                <option value="N3">N3</option>
                <option value="N4">N4</option>
                <option value="N5">N5</option>
                <option value="N6">N6</option>
              </select>

              <label htmlFor="Roof Load">Roof Load</label>
              <select required id="list3">
                <option value="" hidden>
                  {" "}
                  -- Select Option --{" "}
                </option>
                <option value="6kg/m2">6kg/m2 Transparent Sheet 0.5mm, Battens</option>
                <option value="10kg/m2">10kg/m2 Steel Sheet 0.5mm, Battens</option>
                <option value="20kg/m2">20kg/m2 Steel Sheet 0.5mm, Battens, 10mm Plaster Ceiling</option>
                <option value="30kg/m2">30kg/m2 Steel Sheet 0.75mm, Battens, 13mm Plaster Ceiling</option>
                <option value="40kg/m2">40kg/m2 Steel Sheet 0.75mm, Battens, graded purlins</option>
                <option value="60kg/m2">60kg/m2 Terracotta or concrete tiles</option>
                <option value="75kg/m2">75kg/m2 Roof Tiles, Battens, plaster ceiling</option>
                <option value="90kg/m2">90kg/m2 Roof Tiles, Battens, 19mm hardwood ceiling</option>
              </select>

              <label htmlFor="Rafter Spacing">Rafter Spacing (mm)</label>
              <select required id="list4">
                <option value="" hidden>
                  {" "}
                  -- Select Option --{" "}
                </option>
                <option value="600">600</option>
                <option value="760">760</option>
                <option value="900">900</option>
                <option value="1000">1000</option>
                <option value="1200">1200</option>
              </select>
              <label htmlFor="Desired Span (mm)">Desired Span (mm)</label>

              <input type="text" className="form-control inputSpan" id="desiredSpan" placeholder="Desired Span (mm)" />
              <button className="dropdown-toggle search1" type="submit" id="rafterSubmit">
                Search
              </button>
            </div>
          </div>

          <div className="col-md-6">
            <div className="imgtype">
              <img alt="" src={image} width="100%" />
            </div>
          </div>
        </div>
        <div className="col-md-12" id="myDIV">
          <div id="searchContainer">
            <div id="Loading">
              <i className="fa fa-spinner fa-pulse fa-5x fa-fw"></i>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          <table id="tableShell">
            <thead>
              <tr>
                <th scope="col">Size</th>
                <th scope="col">Grade</th>
                <th scope="col">Span (mm)</th>
                <th scope="col">Select</th>
              </tr>
            </thead>
            <tbody id="list-rafter">
              <tr>
                <td data-label="Size">
                  <span id="BeamSize"></span>
                </td>
                <td data-label="Grade">
                  <span id="strength" data-strength=""></span>
                </td>
                <td data-label="Span (mm)">
                  <span id="span" data-span=""></span>
                </td>
                <td data-label="Select">
                  <label className="toggle-check">
                    <input
                      id="reportCheckbox"
                      type="checkbox"
                      data-id=""
                      data-name=""
                      data-application=""
                      data-summary=""
                      data-strength=""
                      data-span=""
                      data-spantype=""
                      data-windzone=""
                      data-roofload=""
                      data-rafterspacing=""
                    />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="col-md-6 m-auto">
            <div className="viewReportlist mt-4" id="reportButton">
              <span className="my-cart-icon1" id="viewReportlist">
                <p>
                  View Report <span className="badge badge-notify my-cart-badge1"></span>
                </p>
              </span>
            </div>
            <div className="yellowAlert" id="yellowAlert">
              <h4>
                <b id="showResult"></b>{" "}
              </h4>
            </div>
          </div>
        </div>

        <div id="ViewReport">
          <div className="col-md-12 reportBorder">
            <div className="spanTablesTitle" style={{ backgroundColor: "#222" }}>
              <h1>
                <b>Span Tables</b>{" "}
              </h1>
            </div>
            <div className="LogoActions">
              <div className="col-md-4">
                <div className="reportLogo">
                  <img className="header-logo-image" src={DESIGNPINELOGO} alt="Design Pine" />
                </div>
              </div>
              <div className="col-md-8">
                <div className="alightItem">
                  <button id="printReport" className="new-button me-3" type="button" name="button">
                    Print Report
                  </button>
                  <button id="continueEdit" className="new-button me-3" type="button" name="button">
                    Continue Editing Report
                  </button>
                  <button id="resetReport" className="new-button me-3" type="button" name="button">
                    Reset Report
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="outputTitle">
                <p>
                  <b className="designPineBlue">DesignPine</b> Span Table List
                </p>
                <p id="getDate"></p>
                <hr />
                <h4>
                  <b>Results</b>{" "}
                </h4>
                <hr />
              </div>

              <div id="output">
                <div className="reportTable">
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Product</th>
                        <th scope="col">Application</th>
                        <th scope="col">Size</th>
                        <th scope="col">Strength</th>
                        <th scope="col">Span Type</th>
                        <th scope="col">Parameter</th>
                        <th scope="col">Result (mm)</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody id="my-cart-table"></tbody>
                  </table>
                </div>
              </div>
              <div id="showAppReport"></div>
              <hr />
              <h2>
                <b>Certification</b>{" "}
              </h2>
              <p>
                The above span output is certified by Dave King Eng - Tasman Consulting, reviewed by: Robin Frengley
                Consulting Engineer
              </p>
              <p>
                <b>Disclaimer</b>{" "}
              </p>
              <p>
                ITI takes no responsibility for the interpretation of the above application or the information input
                into the generation of this report. The Above span are applicable for the products described above only.
              </p>
              <p>www.designpine.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rafters;
