import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { getUser, getWishLists } from "../../../actions";
import queryString from "query-string";

import { Payload } from "../interface";
import { useCallback } from "react";
import { getErrorMessage } from "../../../utils";
import { SlatwalApiService } from "../../../services";

const useAuthFormService = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const createAccountSubmitAction = useCallback(
    async (newPayload: Payload) => {
      console.log("start Signup");

      return SlatwalApiService.account
        .create(newPayload)
        .then((response: any) => {
          if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) {
            toast.error(getErrorMessage(response.success().errors));
            return Promise.reject(getErrorMessage(response.success().errors));
          }
          if (response.isSuccess()) {
            if (!response.success().failureActions.length) {
              toast.success("Success");
              dispatch(getUser() as any).then(() => {
                dispatch(getWishLists() as any);
              });
            }
          } else {
            toast.error("Error");
            return Promise.reject("Error");
          }
        })
        .then(() => {
          if (search.includes("redirect")) {
            const params = queryString.parse(search);
            navigate({
              pathname: params.redirect as string,
            });
          } else {
            navigate({
              pathname: "/my-account/overview",
            });
          }
        });
    },
    [dispatch, navigate, search],
  );

  return { createAccountSubmitAction };
};

export default useAuthFormService;
