import { ProductPriceView } from "./ProductPrice.componentView";
import { ProductPriceProps } from "./ProductPrice.componentModel";
import { useComponentData } from "./ProductPrice.componentService";

const ProductPrice = (props: ProductPriceProps) => {
  const { componentData } = useComponentData(props);

  return <ProductPriceView componentData={componentData} />;
};

export { ProductPrice, type ProductPriceProps };
