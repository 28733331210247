const FormFileField = ({
  attributeName,
  attributeCode,
  requiredFlag,
  setValue,
  value = "",
  inputType,
  row = 5,
  invalid,
  validationMessage,
}) => {
  return (
    <>
      <label htmlFor={attributeCode} className={`form-label ${requiredFlag ? "required" : ""}`}>
        {attributeName}
      </label>
      <input
        className={"form-control" + (invalid ? " is-invalid" : "")}
        id={attributeCode}
        type={inputType}
        value={value}
        onChange={(e) => {
          var filename = e.target.value.match(/[^\\/]*$/)?.at(0);
          setValue((response) => {
            return {
              ...response,
              [attributeCode]: e.target.value,
              [`${attributeCode}-filename`]: filename,
              [`${attributeCode}-file`]: e.target.files[0],
              [`${attributeCode}-path`]: e.target.value,
            };
          });
        }}
      />
      {invalid && <div className="invalid-feedback">{validationMessage ?? ""}</div>}
    </>
  );
};

export default FormFileField;
