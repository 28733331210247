import { useTranslation } from "react-i18next";

export interface ProductAdditionalInformationPorps {
  additionalInformation: string;
}

const ProductAdditionalInformation = ({ additionalInformation }: ProductAdditionalInformationPorps) => {
  const { t } = useTranslation();
  return (
    <div className="product-info my-5">
      <h2 className="h4">{t("frontend.product.additionalInformation")}</h2>
      <div
        className="pt-1"
        dangerouslySetInnerHTML={{
          __html: additionalInformation,
        }}
      />
    </div>
  );
};
export { ProductAdditionalInformation };
