import { useFormatCurrency } from "../../hooks";
import { addMultipleItemsToCart } from "../../actions";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useListing } from "../../hooks";
import { GRID, LISTING } from "../../modules/ProductListing/productListing.moduleModels";
import { useElementContext } from "../../contexts";

const BulkOrder = (props) => {
  const {
    ProductListingModule,
    CommonModule: {
      BulkOrderListingListView,
      BulkOrderListingGridView,
      ProductRow,
      SkuRow,
      ListingPagination,
      BulkOrderProductCard,
      BulkOrderSkuCard,
    },
  } = useElementContext();
  const defaultListingConfig = useSelector((state) => state.configuration.defaultListingConfig);
  const config = props.listingConfig || defaultListingConfig;
  const { records, isFetching, potentialFilters, total, totalPages, updateAttribute, setPage, params } = useListing(
    {},
    config,
  );

  const productsListingFlag = !!config.params.productsListingFlag;
  const viewMode = config.viewMode || LISTING;
  const [bulkOrderList, setBulkOrderList] = useState({});

  const hide = [];

  const addSkuToList = (product, quantity) => {
    setBulkOrderList((currentlist) => {
      if (quantity < 1) {
        delete currentlist[product.skuID];
        return { ...currentlist };
      }
      return {
        ...currentlist,
        [product.skuID]: {
          quantity,
          images: [product.imageFile],
          skuDefinition: product.skuDefinition,
          skuCode: product.skuCode,
          salePrice: product.salePrice,
          productName: product.skuName,
        },
      };
    });
  };

  return (
    <div className={`${props.customStyleClasses || ""}`}>
      <div className="container-fluid product-listing mb-5">
        <div className="row mt-5">
          <ProductListingModule.CoreControl.ListingSidebar
            config={config}
            isFetching={isFetching}
            hide={hide}
            filtering={potentialFilters}
            recordsCount={total}
            updateAttribute={updateAttribute}
          />
          <div className="col">
            {viewMode === LISTING && (
              <BulkOrderListingListView
                config={config}
                bulkOrderList={bulkOrderList}
                pageRecords={records}
                isFetching={isFetching}
                onButtonClick={addSkuToList}
                Card={productsListingFlag ? ProductRow : SkuRow}
              />
            )}
            {viewMode === GRID && (
              <BulkOrderListingGridView
                isFetching={isFetching}
                pageRecords={records}
                bulkOrderList={bulkOrderList}
                onButtonClick={addSkuToList}
                Card={productsListingFlag ? BulkOrderProductCard : BulkOrderSkuCard}
              />
            )}
            <ListingPagination
              recordsCount={total}
              currentPage={params["currentPage"]}
              totalPages={totalPages}
              setPage={setPage}
            />
          </div>

          <SideCart items={bulkOrderList} updateList={setBulkOrderList} />
        </div>
      </div>
    </div>
  );
};

const SideCart = ({ items, updateList }) => {
  const {
    CommonModule: { ProductImage },
  } = useElementContext();
  const [formatCurrency] = useFormatCurrency({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let filtered = Object.entries(items);
  const filtereditems = Object.fromEntries(filtered);

  const addtoCart = (lineItems) => {
    let skuIDs = [];
    let quantities = [];
    Object.keys(lineItems).forEach((skuID) => {
      skuIDs.push(skuID);
      quantities.push(lineItems[skuID].quantity);
    });

    dispatch(addMultipleItemsToCart(skuIDs.join(), quantities.join())).then((response) => {
      updateList({});
    });
  };

  return (
    <div className=" listingCart col-lg-3">
      <div className="filter-block p-4">
        <h4 className="border-bottom pb-2 mb-3">List</h4>

        <div className={``}>
          <div className="">
            <div
              style={{
                maxHeight: "50vh",
                overflowY: "auto",
                paddingRight: "10px",
              }}
            >
              {Object.keys(filtereditems)?.map((skuID) => {
                const { salePrice, skuCode, images, productName, quantity } = filtereditems[skuID];
                return (
                  <div className="py-3  border-bottom border-light" key={skuID}>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-2">
                          {images && images?.length > 0 && (
                            <ProductImage
                              className="img-fluid mw-50px productImage"
                              skuID={skuID}
                              imageFile={images?.at(0)}
                              alt={productName}
                              type="product"
                            />
                          )}
                        </div>
                        <div className="col-8">
                          <p>
                            <span>{productName}</span> <br />
                            <span>{skuCode}</span>
                          </p>
                        </div>
                        <div className="col-2 ">
                          <span className="text-muted small fw-bolder">
                            {quantity} x <span className="text-black">{formatCurrency(salePrice)}</span>
                          </span>
                          <figure className="m-0">
                            <i
                              onClick={() => {
                                updateList((currentlist) => {
                                  delete currentlist[skuID];
                                  return { ...currentlist };
                                });
                              }}
                              className="bi bi-trash3"
                              role="button"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {Object.keys(filtereditems).length > 0 && (
                <button
                  className="btn btn-dark d-block "
                  onClick={(e) => {
                    e.preventDefault();
                    addtoCart(filtereditems);
                  }}
                >
                  {t("frontend.bulkorder.add_all_to_cart")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export { BulkOrder, SideCart };
