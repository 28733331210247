import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getBrandRoute, getProductRoute } from "../../selectors";
import { useElementContext } from "../../contexts";

const BulkOrderProductCard = ({ productData, onButtonClick, quantity }) => {
  const {
    ProductCardModule,
    ProductModule,
    CommonModule: { Button },
  } = useElementContext();
  const { t } = useTranslation();
  const isCartFetching = useSelector((state) => state.cart.isFetching);
  const brand = useSelector(getBrandRoute);
  const product = useSelector(getProductRoute);
  const productLink =
    `/${product}/${productData.urlTitle}` +
    (productData.defaultSku_skuID.length ? `?skuid=${productData.defaultSku_skuID}` : "");
  const defaultSku = productData.skus.find((sku) => sku.skuID === productData.defaultSku_skuID) || productData.skus[0];

  return (
    <div className="card p-3 pt-2 h-100">
      {productData.productClearance === true && <span className="badge">{t("frontend.core.special")}</span>}
      <ProductModule.CoreComponents.HeartButton skuID={defaultSku.skuID} />
      <Link to={productLink}>
        <ProductCardModule.CoreControl.ProductCardImage product={defaultSku} />
      </Link>
      <div className="card-body">
        <Link to={`/${brand}/${productData.brand_urlTitle}`} className="text-capitalize mb-3" style={{ fontSize: 12 }}>
          {productData.brand_brandName}
        </Link>
        <h2>
          <Link to={productLink} className="product-name d-inline-block w-100">
            {productData.productName}
          </Link>
        </h2>
        {!productData.defaultSku_skuCode && productData.productCode && (
          <div className="product-brand">{productData.productCode}</div>
        )}
        {productData.defaultSku_skuCode && <div className="product-brand">{productData.defaultSku_skuCode}</div>}
        <div className="row">
          <div className="col-8">
            <ProductModule.CoreComponents.ProductPrice
              salePrice={productData.salePrice}
              listPrice={productData.listPrice}
              className="d-flex"
            />
          </div>
          <div className="col-4">
            <input
              type="number"
              className="form-control"
              min={0}
              value={quantity}
              disabled={isCartFetching}
              onChange={(e) => {
                onButtonClick(defaultSku, parseInt(e.target.value, 10));
              }}
            />
          </div>
        </div>
      </div>
      <div className="text-center card-footer border-0 bg-transparent pb-3 pt-0">
        <Button
          className="btn btn-primary btn-block my-2"
          label={t("frontend.bulkorder.add_to_list")}
          onClick={(e) => {
            e.preventDefault();
            onButtonClick(defaultSku, quantity + 1);
          }}
        />
      </div>
    </div>
  );
};

const BulkOrderSkuCard = ({ productData, onButtonClick, quantity }) => {
  const {
    ProductModule,
    ProductCardModule,
    CommonModule: { Button },
  } = useElementContext();
  const { t } = useTranslation();
  const isCartFetching = useSelector((state) => state.cart.isFetching);
  const brand = useSelector(getBrandRoute);
  const product = useSelector(getProductRoute);
  const productLink =
    `/${product}/${productData.product_urlTitle}` + (productData.skuID.length ? `?skuid=${productData.skuID}` : "");

  return (
    <div className="card p-3 pt-2 h-100">
      {productData.productClearance === true && <span className="badge">{t("frontend.core.special")}</span>}
      <ProductModule.CoreComponents.HeartButton skuID={productData.skuID} />
      <Link to={productLink}>
        <ProductCardModule.CommonModule.ProductCardImage product={productData} />
      </Link>
      <div className="card-body">
        <Link
          to={`/${brand}/${productData.product_brand_urlTitle}`}
          className="text-capitalize mb-3"
          style={{ fontSize: 12 }}
        >
          {productData.product_brand_brandName}
        </Link>
        <h2>
          <Link to={productLink} className="product-name d-inline-block w-100">
            {productData.product_productName}
          </Link>
        </h2>
        {!productData.skuCode && productData.product_productCode && (
          <div className="product-brand">{productData.product_productCode}</div>
        )}
        {productData.skuCode && <div className="product-brand">{productData.skuCode}</div>}
        <div className="row">
          <div className="col-8">
            <ProductModule.CoreComponents.ProductPrice
              salePrice={productData.salePrice}
              listPrice={productData.listPrice}
              className="d-flex"
            />
          </div>
          <div className="col-4">
            <input
              type="number"
              className="form-control"
              min={0}
              value={quantity}
              disabled={isCartFetching}
              onChange={(e) => {
                onButtonClick(product, parseInt(e.target.value, 10));
              }}
            />
          </div>
        </div>
      </div>
      <div className="text-center card-footer border-0 bg-transparent pb-3 pt-0">
        <Button
          className="btn btn-primary btn-block my-3"
          label={t("frontend.bulkorder.add_to_list")}
          onClick={(e) => {
            e.preventDefault();
            onButtonClick(productData, quantity + 1);
          }}
        />
      </div>
    </div>
  );
};

export { BulkOrderProductCard, BulkOrderSkuCard };
