import { RelatedProductsSliderProps } from "./RelatedProductsSlider.componentModel";
import { useComponentData } from "./RelatedProductsSlider.componentService";
import { RelatedProductsSliderView } from "./RelatedProductsSlider.componentView";

const RelatedProductsSlider = (props: RelatedProductsSliderProps) => {
  const { componentData } = useComponentData(props);

  return <RelatedProductsSliderView componentData={componentData} />;
};
export { RelatedProductsSlider, type RelatedProductsSliderProps };
