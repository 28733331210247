import { useComponentData } from "./Quote.componentService";
import { QuoteView } from "./Quote.componentView";

const Quote = () => {
  const { componentData, componentService } = useComponentData();

  return (
    <QuoteView
      componentData={componentData}
      onRemoveItem={componentService.onRemoveItem}
      onUpdateQty={componentService.onUpdateQty}
    />
  );
};

export default Quote;
