import { useElementContext } from "../../contexts";

const Resources = (props) => {
  const { layoutViewMode = "css-grid", ...rest } = props;
  const {
    CommonModule: {
      ResourceLinkListing,
      ResourceCardLayoutCSSBlock,
      ResourceCardLayoutGrid,
      ResourceCardLayoutCardGroup,
      ResourceCardLayoutCardMasontry,
    },
  } = useElementContext();

  switch (layoutViewMode) {
    case "card-grid":
      return <ResourceCardLayoutGrid {...rest} />;
    case "card-group":
      return <ResourceCardLayoutCardGroup {...rest} />;
    case "card-masonry":
      return <ResourceCardLayoutCardMasontry {...rest} />;
    case "link-listing":
      return <ResourceLinkListing {...rest} />;
    default:
      return <ResourceCardLayoutCSSBlock {...rest} />;
  }
};

export { Resources };
