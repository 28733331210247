import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useElementContext } from "../../../contexts";

export interface CartCheckOutProps {
  disableCheckoutBtn?: boolean;
  onApplyCode: (promoCode: string, setPromoCode: (code: string) => void) => void;
}

const CartCheckOut = ({ disableCheckoutBtn = false, onApplyCode }: CartCheckOutProps) => {
  const { t } = useTranslation();
  const {
    CartModule,
    CommonModule: { CartOrderSummary },
  } = useElementContext();

  return (
    <div className="row">
      <div className="col-sm-12">
        <CartOrderSummary />
      </div>
      <CartModule.CoreComponents.CartPromoBox onApplyCode={onApplyCode} />
      <div className="ps-2 pe-2">
        <Link
          className={`col-md-12 btn btn-primary w-100 mt-2 ${disableCheckoutBtn ? "disabled" : ""}`}
          to={"/checkout"}
        >
          {t("frontend.order.to_checkout")}
        </Link>
      </div>
    </div>
  );
};

export { CartCheckOut };
