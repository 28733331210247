import { useNavigate } from "react-router-dom";

export interface CartCheckoutOptionsProps {
  quoteOrderItemsLength: number;
  cartOrderItemsLength: number;
}

const CartCheckoutOptions = ({ cartOrderItemsLength, quoteOrderItemsLength }: CartCheckoutOptionsProps) => {
  const navigate = useNavigate();

  return (
    <>
      <h3>
        <b>Checkout Options</b>
      </h3>
      <p>
        You have some items in your cart which must go to the seller for a quote. We can create this quote for you and
        send it off to the seller as part of the checlkout process.
      </p>
      <p>You can also choose to send all items to the seller for a quote.</p>

      <p className="fst-italic">
        {cartOrderItemsLength > 1 ? `${cartOrderItemsLength} Items` : `${cartOrderItemsLength} Item`} can be ordered
        immediately
        <br />
        {quoteOrderItemsLength > 1 ? `${quoteOrderItemsLength} Items` : `${quoteOrderItemsLength} Item`} Items need to
        be sen to the seller for a quote
      </p>

      <div className="col-12">
        <button
          className="btn btn-success rounded checkout-btn"
          onClick={() => {
            navigate({
              pathname: "/quotes-in-cart",
              search: `allItems=0`,
            });
          }}
        >
          Checkout {cartOrderItemsLength > 1 ? `${cartOrderItemsLength} Items` : `${cartOrderItemsLength} Item`} now and
          create a quote with {quoteOrderItemsLength} items
        </button>
      </div>
      <div className="col-12 my-2">
        <button
          className="btn btn-success rounded w-100 checkout-btn"
          onClick={() => {
            navigate({
              pathname: "/quotes-in-cart",
              search: `allItems=1`,
            });
          }}
        >
          Send all{" "}
          {cartOrderItemsLength + quoteOrderItemsLength > 1
            ? `${cartOrderItemsLength + quoteOrderItemsLength} Items`
            : `${cartOrderItemsLength + quoteOrderItemsLength} Item`}{" "}
          items to a quote
        </button>
      </div>
    </>
  );
};

export { CartCheckoutOptions };
