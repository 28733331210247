import Slider from "react-slick";
import { useElementContext } from "../../contexts";

const CollectionSlide = ({
  urlPath,
  imagePath,
  urlTitle = "",
  contentHeading,
  slug,
  clickable,
  customStyleClasses,
  ...rest
}) => {
  const {
    CommonModule: { ExternalLink, SimpleImage },
  } = useElementContext();
  return (
    <div className="brand-slide repeater">
      <div className="card-body">
        <ExternalLink
          linkUrl={urlPath}
          style={{ height: "150px" }}
          className={`p-2 justify-content-center d-flex align-items-center shadow-sm ${
            clickable && urlPath ? "cursor-pointer" : "pe-none"
          }`}
        >
          {imagePath && (
            <SimpleImage className="w-100 mh-100" src={!!imagePath ? imagePath : ""} alt={contentHeading} />
          )}
          {!imagePath && <h3>{contentHeading}</h3>}
        </ExternalLink>
      </div>
    </div>
  );
};

const CollectionSlider = (props) => {
  const { imageList = [], contentHeading, clickable, cta, customStyleClasses } = props;
  const {
    CommonModule: { CallToActionBtn },
  } = useElementContext();

  const settings = {
    dots: false,
    infinite: imageList.length > 3,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    clickableSlides: clickable,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 578,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  if (!imageList.length) return null;
  return (
    <div className={`container ${customStyleClasses}`}>
      <div className="py-4 brand-slider-sec">
        <div className="row">
          <div className="col-12">
            <header className="section-title">
              <h2>{contentHeading}</h2>
            </header>
            <div className="card border-0 bg-transparent">
              <Slider {...settings}>
                {imageList.map((slide, i) => {
                  slide.clickable = settings.clickableSlides;
                  return <CollectionSlide key={i} {...slide} />;
                })}
              </Slider>
            </div>
            {cta && (
              <div className="pt-4 d-flex justify-content-center">
                <CallToActionBtn {...cta} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { CollectionSlider };
