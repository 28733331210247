import { CartItemListProps } from "../../modules/Cart/Controls/CartItemList";
import { Cart } from "../../interface/Cart";
import { CartCheckOutProps } from "../../modules/Cart/Controls/CartCheckOut";
import { CartCheckoutOptionsProps } from "../../modules/Cart/Controls/CartCheckoutOptions";

class CartModel {
  orderID: string = "";
  isRemovingItem: boolean = false;
  isEmptyCart: boolean = false;
  showClearCart: boolean = false;

  components: {
    cartOrderItemList?: Omit<CartItemListProps, "onRemoveItem" | "onUpdateQty">;
    quoteOrderItemsList?: Omit<CartItemListProps, "onRemoveItem" | "onUpdateQty">;
    cartCheckOut?: Omit<CartCheckOutProps, "onApplyCode">;
    cartCheckoutOptions?: CartCheckoutOptionsProps;
  } = {};

  constructor({
    cart,
    isRemovingItem,
    disableInteraction,
  }: {
    cart: Cart;
    isRemovingItem: boolean;
    disableInteraction: boolean;
  }) {
    const { orderItems = [], orderID } = cart;

    this.orderID = orderID;
    this.isEmptyCart = !orderItems.length;

    this.isRemovingItem = isRemovingItem;
    const cartAndQuoteItems = orderItems.filter((item) => item.parentOrderItemID === "");
    const cartOrderItems = cartAndQuoteItems.filter((item) => !item.quoteOnly);
    const quoteOrderItems = cartAndQuoteItems.filter((item) => item.quoteOnly);

    this.components.cartOrderItemList =
      cartOrderItems.length > 0
        ? {
            title: `Shopping Cart (${cartOrderItems.length})`,
            cartItems: cartOrderItems,
            orderItems: orderItems,

            isRemovingItem: isRemovingItem,
          }
        : undefined;

    this.components.quoteOrderItemsList =
      quoteOrderItems.length > 0
        ? {
            title: `Items which needs to be quoted (${quoteOrderItems.length})`,
            cartItems: quoteOrderItems,
            orderItems: orderItems,

            isRemovingItem: isRemovingItem,
          }
        : undefined;

    if (quoteOrderItems.length > 0) {
      this.components.cartCheckoutOptions = {
        cartOrderItemsLength: cartOrderItems.length,
        quoteOrderItemsLength: quoteOrderItems.length,
      };
    } else {
      this.components.cartCheckOut = !this.isEmptyCart ? { disableCheckoutBtn: disableInteraction } : undefined;
    }

    this.showClearCart = !this.isEmptyCart;
  }
}

export default CartModel;
