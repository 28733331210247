import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductFormModel, ProductFormProps } from "./ProductForm.componentModel";
import { addToCartOrQuote, setAllCartOrQuotes } from "../../../../actions/cartActions";
import { Quote } from "../../../../interface/Quote";
import { Sku } from "../../../../interface/Product";

export const useComponentModel = (props: ProductFormProps) => {
  const dispatch = useDispatch();
  const cart = useSelector((state: any) => state.cart);
  const cartAndQuotes = useSelector((state: any) => state?.allCartQuote?.cartAndQuote);
  const wishlists = useSelector((state: any) => state?.userReducer?.wishList);
  const [isLoading, setLoading] = useState(false);
  const [itemCount, setItemCount] = useState(1);
  const [quoteModal, setQuoteModal] = useState(false);
  const [listModal, setListModal] = useState(false);

  const componentData = useMemo(
    () =>
      new ProductFormModel({
        props,
        cart,
        wishlists,
        isLoading,
        itemCount,
        quoteModal,
        listModal,
        cartAndQuotes,
      }),
    [cart, isLoading, itemCount, listModal, props, quoteModal, cartAndQuotes, wishlists],
  );

  const getAllCartOrQuotes = useCallback(() => {
    dispatch<any>(setAllCartOrQuotes());
  }, [dispatch]);

  const addToQuote = useCallback(
    (sku: Sku, qty: number, quote?: Quote, skuConfiguration?: any) => {
      setLoading(true);
      dispatch<any>(addToCartOrQuote(sku, qty, quote?.orderID, quote?.quoteName, skuConfiguration)).then(() => {
        setItemCount(1);
        setLoading(false);
      });
    },
    [dispatch],
  );

  return {
    componentData,
    componentService: { getAllCartOrQuotes, addToQuote, setQuoteModal, setListModal, setItemCount, wishlists },
  };
};
