import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getCountriesAndAddressOptions } from "../../../actions";
import { useCreateAccount } from "../../../hooks";
import { useEffect, useMemo } from "react";
import { getSocialLogins } from "../../../selectors";
import { useElementContext } from "../../../contexts";

//additional button added for oAuthLogins
const OAuthSigninButtons = () => {
  const {
    CommonModule: { OauthGoogleLogin, OauthFacebookLogin, Oauth0Login },
  } = useElementContext();
  const { t } = useTranslation();
  const socialLogins = useSelector(getSocialLogins);
  return (
    <div className="text-right pt-4">
      {socialLogins?.map((integration) => {
        if (integration.key === "googlelogin")
          return <OauthGoogleLogin key={integration.key} buttonText={t("frontend.oauth.googlesignup")} />;
        if (integration.key === "auth0")
          return <Oauth0Login key={integration.key} buttonText={t("frontend.oauth.auth0signin")} />;
        if (integration.key === "facebooklogin")
          return <OauthFacebookLogin key={integration.key} buttonText={t("frontend.oauth.facebooksignup")} />;
        return null;
      })}
      <Oauth0Login key="auth0" buttonText="Login With Auth0" />
    </div>
  );
};

const CreateAccount = () => {
  const {
    CommonModule: { PromptLayout, SWForm, SWInput },
  } = useElementContext();
  const { formik } = useCreateAccount();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.content.countries);
  useEffect(() => {
    if (Object.keys(countries)?.length === 0) {
      dispatch(getCountriesAndAddressOptions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const names = useMemo(() => {
    const defaultUserCountry = formik.initialValues.userCountry;
    const result = Object.keys(countries).reduce((acc, cur) => {
      const name = countries[cur].name;
      if (name === defaultUserCountry) {
        return [name, ...acc];
      }
      return [...acc, name];
    }, []);
    if (result[0] !== defaultUserCountry) {
      result.unshift(defaultUserCountry);
    }
    return result;
  }, [countries, formik.initialValues.userCountry]);

  return (
    <PromptLayout>
      <h2>{t("frontend.account.createAccount")}</h2>

      <SWForm
        formik={formik}
        primaryButtontext="Create Account & Continue"
        title=""
        AdditionalFormButtons={OAuthSigninButtons}
      >
        <div className="row">
          <div className="col-md-6">
            <SWInput formik={formik} token="firstName" label="First Name*" wrapperClasses="" />
          </div>
          <div className="col-md-6">
            <SWInput formik={formik} token="lastName" label="Last Name*" wrapperClasses="" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SWInput formik={formik} token="phoneNumber" label="Phone Number*" type="phone" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SWInput formik={formik} token="emailAddress" label="Email Address*" type="email" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SWInput formik={formik} token="emailAddressConfirm" label="Confirm Email Address*" type="email" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SWInput formik={formik} token="company" label="Company Name" type="text" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SWInput formik={formik} token="australianBusinessNumber" label="ABN" type="text" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SWInput formik={formik} token="builderLicenseNumber" label="Builders License Number" type="text" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SWInput formik={formik} token="addressLineOne" label="Address Line 1*" type="text" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SWInput formik={formik} token="addressLineTwo" label="Address Line 2*" type="text" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <SWInput formik={formik} token="city" label="Suburb/City*" type="text" />
          </div>
          <div className="col-md-6">
            <SWInput formik={formik} token="userState" label="State*" type="text" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div>
              <div className="col form-group">
                <label className="control-label" htmlFor="userCountry">
                  Country*
                </label>
                <select
                  className="form-select"
                  name="userCountry"
                  id="userCountry"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values["userCountry"]}
                >
                  {names.map((name) => {
                    return (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    );
                  })}
                </select>

                {formik.touched["userCountry"] && formik.errors["userCountry"] && (
                  <span className="form-error-msg">{formik.errors["userCountry"]}</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <SWInput formik={formik} token="userPostCode" label="Post Code*" type="text" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SWInput formik={formik} token="password" label="Password*" type="password" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <SWInput formik={formik} token="passwordConfirm" label="Confirm Password*" type="password" />
          </div>
        </div>
        <p>
          {t("frontend.account.old_account")}
          <Link className="ms-1 link" to="/my-account/login">
            {t("frontend.account.here")}
          </Link>
          .
        </p>
      </SWForm>
    </PromptLayout>
  );
};

export { CreateAccount };
