import { ReactNode } from "react";

export class HeaderModel {
  ribbons: ReactNode[] = [];
  menus: ReactNode[] = [];
  megaMenus: ReactNode[] = [];
  filteredMenus: ReactNode[] = [];
  theme: any;
  siteSetting: any;
  constructor({ ribbons, menus, megaMenus, filteredMenus, theme, siteSetting }: HeaderModel) {
    this.ribbons = ribbons;
    this.megaMenus = megaMenus;
    this.menus = menus;
    this.filteredMenus = filteredMenus;
    this.theme = theme;
    this.siteSetting = siteSetting;
  }
}
