import { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";

export interface TruncateProps {
  cssClass?: string;
  lineClamp?: string;
  maxHeight?: string;
  expandLabel?: string;
  collapseLabel?: string;
  showExpandBtn?: boolean;
}

const Truncate = ({
  cssClass = "",
  lineClamp = "initial",
  maxHeight = "auto",
  expandLabel = "Read more",
  collapseLabel = "Read less",
  showExpandBtn = true,
  children,
}: PropsWithChildren<TruncateProps>) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setOverflow] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!contentRef.current) return;
    const checkoutOverflow = () => {
      if (!contentRef.current) return;
      const { scrollHeight, clientHeight } = contentRef.current!;
      setOverflow(scrollHeight > clientHeight);
    };

    if (!isExpanded) {
      checkoutOverflow();
      const observer = new ResizeObserver(checkoutOverflow);
      observer.observe(contentRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [isExpanded]);

  const toggleExpand = useCallback(() => {
    setExpanded((prevState) => !prevState);
  }, []);

  return (
    <div className={`d-flex flex-column ${cssClass}`}>
      <div
        className="truncate_content"
        ref={contentRef}
        style={isExpanded ? undefined : { WebkitLineClamp: lineClamp, maxHeight }}
      >
        {children}
      </div>
      {isOverflow && showExpandBtn && (
        <p className="truncate_expandBtn link-button" onClick={toggleExpand}>
          {isExpanded ? collapseLabel : expandLabel}
        </p>
      )}
    </div>
  );
};

export default Truncate;
