import { ProductBundleBuildOnAccount, ProductBundleItem } from "../../../../interface/Product";

export interface ProductBundleItemsProps {
  bundlesOnAccount: { [key: string]: any };
  productBundleItem: ProductBundleItem;
  productID: string;
  setBundleBuildsOnAccount: (bundle?: ProductBundleBuildOnAccount) => void;
}

export class ProductBundleItemsModel {
  currentPage: number = 1;
  totalPages: number = 1;
  isOpen: boolean = false;
  productItems: any;
  bundlesOnAccount!: ProductBundleItemsProps["bundlesOnAccount"];
  productBundleItem!: ProductBundleItemsProps["productBundleItem"];

  constructor({
    props,
    currentPage,
    isOpen,
    productItems,
  }: {
    props: ProductBundleItemsProps;
    currentPage: number;
    isOpen: boolean;
    productItems: any;
  }) {
    this.totalPages = Math.ceil(productItems?.totalSkuCount / 10);
    this.currentPage = currentPage;
    this.isOpen = isOpen;
    this.productItems = productItems;
    this.bundlesOnAccount = props.bundlesOnAccount;
    this.productBundleItem = props.productBundleItem;
  }
}
