import { useSelector } from "react-redux";
import { Navigate, Link } from "react-router-dom";

import { getAllOrderFulfillments, orderPayment } from "../../../selectors";
import { useTranslation } from "react-i18next";
import { useCheckoutUtilities, CHECK_PAYMENT_CODE } from "../../../hooks";
import { useElementContext } from "../../../contexts";

const ReviewSlide = ({ currentStep }) => {
  const {
    CartModule,
    CommonModule: {
      SlideNavigation,
      GiftCardDetails,
      PickupLocationDetails,
      ExternalPaymentDetails,
      CashPaymentDetails,
      CCDetails,
      BillingAddressDetails,
      TermPaymentDetails,
      CheckPaymentDetails,
      ShippingAddressDetails,
    },
  } = useElementContext();
  const cart = useSelector((state) => state.cart);
  const orderFulfillments = useSelector(getAllOrderFulfillments);
  const payment = useSelector(orderPayment);
  const { t } = useTranslation();
  const {
    SHIPPING_CODE,
    PICKUP_CODE,
    CREDIT_CARD_CODE,
    GIFT_CARD_CODE,
    TERM_PAYMENT_CODE,
    CASH_PAYMENT_CODE,
    EXTERNAL_PAYMENT_CODE,
  } = useCheckoutUtilities();

  if (cart.isPlaced) {
    return <Navigate to={"order-confirmation"} />;
  }

  return (
    <>
      <div className="row bg-lightgray rounded mx-0 py-3">
        {orderFulfillments.map((orderFulfillment) => {
          return (
            <div className="col-md-4 col-sm-6" key={orderFulfillment.orderFulfillmentID}>
              {orderFulfillment.fulfillmentMethod.fulfillmentMethodType === SHIPPING_CODE && (
                <ShippingAddressDetails orderFulfillment={orderFulfillment} />
              )}
              {orderFulfillment.fulfillmentMethod.fulfillmentMethodType === PICKUP_CODE && (
                <PickupLocationDetails pickupLocation={orderFulfillment.pickupLocation} />
              )}
            </div>
          );
        })}

        {payment?.billingAddress?.addressID?.length > 0 &&
          payment.paymentMethod.paymentMethodType !== CHECK_PAYMENT_CODE && (
            <div className="col-md-4 col-sm-6">
              <BillingAddressDetails orderPayment={payment} />
            </div>
          )}

        <div className="col-md-4 col-sm-6">
          {payment.paymentMethod.paymentMethodType === CREDIT_CARD_CODE && <CCDetails creditCardPayment={payment} />}
          {payment.paymentMethod.paymentMethodType === GIFT_CARD_CODE && <GiftCardDetails />}
          {payment.paymentMethod.paymentMethodType === TERM_PAYMENT_CODE && (
            <TermPaymentDetails termPayment={payment} />
          )}
          {payment.paymentMethod.paymentMethodType === CASH_PAYMENT_CODE && (
            <CashPaymentDetails cashPayment={payment} />
          )}
          {payment.paymentMethod.paymentMethodType === CHECK_PAYMENT_CODE && <CheckPaymentDetails payment={payment} />}
          {payment.paymentMethod.paymentMethodType === EXTERNAL_PAYMENT_CODE && (
            <ExternalPaymentDetails payment={payment} />
          )}
          <Link to="/checkout/payment" className="text-link link">
            {t("frontend.core.edit")}
          </Link>
        </div>
      </div>

      <h2 className="h3 pt-1 pb-3 my-3 border-bottom">{t("frontend.checkout.review.order")}</h2>
      {cart.orderItems &&
        cart.orderItems
          .filter((item) => item.parentOrderItemID === "")
          .map((orderItem) => {
            return (
              <CartModule.CoreComponents.CartLineItem
                key={orderItem.orderItemID}
                orderItem={orderItem}
                isDisabled={true}
                childBundleItems={cart.orderItems?.filter((item) => item?.parentOrderItemID === orderItem.orderItemID)}
              />
            ); // this cannot be index or it wont force a rerender
          })}

      <SlideNavigation currentStep={currentStep} />
    </>
  );
};

export { ReviewSlide };
